import { IconDotsNine } from '@lbox/shared/components'

import { Button, IconOnlyButton, Tooltip } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import type { DesktopGuestButtonsProps } from '../types'

const DesktopGuestButtons = (props: DesktopGuestButtonsProps) => {
  const { type, onClickLogin, onClickRegister } = props

  if (type === 'type2') {
    return (
      <div className={cn('grid grid-flow-col items-center gap-x-[8px]')}>
        <Tooltip description="서비스" color="white">
          <IconOnlyButton
            buttonType="ghost"
            icon={IconDotsNine}
            iconProps={{ weight: 'bold' }}
            onClick={props.onClickMenuButton}
          />
        </Tooltip>
        <Button label="로그인" type="button" buttonType="ghost" width="fit" onClick={onClickLogin} />
        <Button label="회원가입" type="button" buttonType="filled" width="fit" onClick={onClickRegister} />
      </div>
    )
  }

  // NOTE: type1, type3
  return (
    <div className={cn('grid grid-flow-col gap-x-[8px]')}>
      <Button label="로그인" type="button" buttonType="ghost" width="fit" onClick={onClickLogin} />
      <Button label="회원가입" type="button" buttonType="filled" width="fit" onClick={onClickRegister} />
    </div>
  )
}

export default DesktopGuestButtons
