import { useMutation } from '@tanstack/react-query'

import { postVerifyLawyer } from '../$apis/postVerifyLawyer'
import { API_ENDPOINT } from '../../../constants/api/endpoint'

export function usePostVerifyLawyer() {
  return useMutation({
    mutationKey: [API_ENDPOINT.lboxUser.verifyLawyer],
    mutationFn: postVerifyLawyer,
  })
}
