import type { CommandAll } from '../../$types/command'

export const FAST_SEARCH_COMMAND_GUIDE: Partial<
  Record<CommandAll, { leftText: string; rightTagText: string; rightExampleText: string }>
> = {
  사건명: {
    leftText: '사건명에서 검색',
    rightTagText: '@사건명 검색어 또는 "문장"',
    rightExampleText: '예) @사건명 건물명도',
  },
  주문: {
    leftText: '주문에서 검색',
    rightTagText: '@주문 검색어 또는 "문장"',
    rightExampleText: '예) @주문 기각',
  },
  법조문: {
    leftText: '법조문에서 검색',
    rightTagText: '@법조문 법령명조문번호',
    rightExampleText: '예) @법조문 민법406',
  },
  // 법무법인: {
  //   leftText: '법무법인 검색',
  //   rightTagText: '@법무법인 검색어',
  //   rightExampleText: '예) @법무법인 율촌',
  // },
  당사자명: {
    leftText: '당사자명 검색',
    rightTagText: '@당사자명 검색어 또는 "문장"',
    rightExampleText: '예) @당사자명 공정거래위원회',
  },
  사건부호: {
    leftText: '사건부호 검색',
    rightTagText: '@사건부호 검색어',
    rightExampleText: '예) @사건부호 카합',
  },
  판결요지: {
    leftText: '판시사항/판결요지에서 검색',
    rightTagText: '@판결요지 검색어 또는 "문장"',
    rightExampleText: '@판결요지 토지수용',
  },
  청구취지: {
    leftText: '청구취지에서 검색',
    rightTagText: '@청구취지 검색어 또는 "문장"',
    rightExampleText: '@청구취지 지급하라',
  },
  신청취지: {
    leftText: '신청취지에서 검색',
    rightTagText: '@신청취지 검색어 또는 "문장"',
    rightExampleText: '@신청취지 인도하라',
  },
  완전일치: {
    leftText: '검색어 완전 일치',
    rightTagText: '"검색어" 또는 "문장"',
    rightExampleText: '"항소를 기각한다"',
  },
  제외어: {
    leftText: '제외 검색어 적용',
    rightTagText: '검색어 -단어',
    rightExampleText: '소유권 -양도',
  },
}
