import React from 'react'

import cn from 'classnames'

import type {
  LargeSearchBarDividerLineProps,
  SearchBarDividerLineProps,
  SmallAndMediumSearchBarDividerLineProps,
} from '../$types/searchBar'

const SearchBarDividerLine = ({ size, isSearchDataPopoverOpen, hasSearchBarData }: SearchBarDividerLineProps) => {
  if (size === 'l') {
    return <SearchBarDividerLine.Large isSearchDataPopoverOpen={isSearchDataPopoverOpen} />
  }

  return (
    <SearchBarDividerLine.SmallAndMedium
      isSearchDataPopoverOpen={isSearchDataPopoverOpen}
      hasSearchBarData={hasSearchBarData}
    />
  )
}

SearchBarDividerLine.SmallAndMedium = function SmallAndMediumSearchBarDividerLine({
  isSearchDataPopoverOpen,
  hasSearchBarData,
}: SmallAndMediumSearchBarDividerLineProps) {
  if (!isSearchDataPopoverOpen || !hasSearchBarData) {
    return <></>
  }

  return <hr className={cn('absolute bottom-0', 'h-[1px] w-[calc(100%-28px)] bg-zinc-200')} />
}

SearchBarDividerLine.Large = function LargeSearchBarDividerLine({
  isSearchDataPopoverOpen,
}: LargeSearchBarDividerLineProps) {
  if (!isSearchDataPopoverOpen) {
    return <></>
  }

  return <hr className={cn('absolute bottom-0', 'h-[1px] w-[calc(100%-60px)] bg-zinc-200')} />
}

export default SearchBarDividerLine
