import { useState } from 'react'

import { IconMagnifyingGlass } from '@lbox/shared/components'
import { useOnClickOutside } from '@lbox/shared/hooks'

import { twMergeLDS } from '@lbox-kr/libra/utils'
import { Button } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { PLACEHOLDER_TEXT } from './$constants/placeholder'
import useRegisterSearchBarFocus from './$hooks/useRegisterSearchBarFocus'
import { useSearchBar } from './$hooks/useSearchBar'
import type { SearchBarProps } from './$types/searchBar'
import { getConditionalSearchBarStyle } from './$utils/getConditionalSearchBarStyle'
import SearchBarDividerLine from './search-bar-divider-line/SearchBarDividerLine'
import { SearchCommandGuidePopover } from './search-command-guide-popover/SearchCommandGuidePopover'
import SearchBarDataList from './search-data-list/search-bar-data-list/SearchBarDataList'

/**
 * HeaderSearchBarTablet
 * - [x] 하이라이트
 * - [x] x축 스크롤 따라서 하이라이트
 * - [x] 태그 클릭해서 텍스트 추가 후 커서 이동 스크롤 없을 때
 * - [x] 태그 클릭해서 텍스트 추가 후 커서 이동 스크롤 있을 때
 * - [x] 한글 입력 관련 이슈 - 스크롤 있을 때, 없을 때
 * - [x] 검색해서 데이터 연동
 * - [x] 포커스 시 열고 닫기
 * - [x] 로딩 시 키워드 렌더링 및 하이라이트, 포커스 아웃
 * - [x] absolute 디자인 가리는 문제 해결 -> fixed
 * - [x] 입력 시 팝오버 활성화
 * - [x] 태그 클릭 시 텍스트 추가 - 완전일치, 제외어
 * - [x] 입력 중 빠검명 클릭 시 입력 중이던 텍스트 대체
 * - [x] 팝오버 최근 검색어 등 클릭 시 해당 키워드로 검색 기능 동작
 * - [x] 빠검명 가이드 표시 로직 업데이트
 * - [x] 빠검명은 아니지만 사건번호 하이라이트
 * - [x] 판례 이외의 탭에서는 완전일치, 제외어만 표시
 * - [ ] 팝오버 내 하이라이트 디자인 업데이트
 * - [ ] 구 코드 정리 및 폴더 구조 업데이트
 * - [ ] 모바일
 * - [ ] 방향키, 마우스로 커서 이동 시 상태 업데이트 - 스크롤 고급화?
 * - [ ] 똑같은 태그 2개 이상 만들어질 경우 스타일 겹침
 *
 * @param size: 검색창 크기
 * @param autoFocus: 자동 포커스 유무
 * @param enableOpenSearchDataPopover: 검색 결과창 popover 열리는 상태 허용 유무
 * @param useRouter: 커스텀 NextRouter 타입을 반환하는 함수
 * - 검색 화면의 모바일 -> false
 * - 그 외 -> true
 * @param onFireUserEvent: 외부로부터 주입 받는 user event function
 */
const SearchBar = ({
  size,
  autoFocus,
  enableOpenSearchDataPopover = true,
  router,
  onFireUserEvent,
  rootClassName,
  ...inputFieldProps
}: SearchBarProps) => {
  const {
    isSearchDataPopoverOpen,
    isSearchCommandGuidePopoverOpen,
    highlighted,
    searchKeyword,
    cursorOffset,
    searchBarRef,
    searchDataPopoverRef,
    ghostTextRef,
    highlightedRef,
    searchKeywordRef,
    searchCommandGuideButtonRef,
    handleInputSearchKeyword,
    handleClickTag,
    handleSearchJudgeDataList,
    handleSearchDataList,
    handleClickSearchBar,
    handleClickSearchButton,
    closeSearchDataPopover,
    closeSearchCommandGuidePopover,
    handleClickSearchCommandGuideTriggerButton,
  } = useSearchBar({ enableOpenSearchDataPopover, onFireUserEvent, router })

  const [hasSearchBarData, setHasSearchBarData] = useState(false)

  const searchBarWrapperRef = useOnClickOutside<HTMLDivElement>(() => {
    if (!isSearchCommandGuidePopoverOpen) {
      closeSearchDataPopover()
    }
  })

  useRegisterSearchBarFocus({ searchKeywordRef })

  return (
    <>
      <div
        ref={searchBarWrapperRef}
        className={twMergeLDS(cn('relative', { 'w-full': size === 'm' || size === 's' }), rootClassName)}
        onClick={handleClickSearchBar}
      >
        <form
          ref={searchBarRef}
          className={cn(
            'relative',
            'overflow-hidden',
            getConditionalSearchBarStyle({ size, isSearchDataPopoverOpen, hasSearchBarData })
          )}
          onSubmit={handleClickSearchButton}
        >
          <div
            ref={ghostTextRef}
            className={cn('invisible absolute overflow-x-hidden whitespace-pre outline-none', {
              'left-[48px] w-[calc(100%-70px)] py-[7px] text-lds2-body2-regular': size === 's',
              'left-[14px] w-[calc(100%-60px)] py-[7px] text-lds2-body2-regular': size === 'm',
              'left-[30px] w-[calc(100%-206px)] py-[10px] text-lds2-heading4-medium': size === 'l',
            })}
          />

          <div
            id="innerSearchBoxGhost"
            ref={highlightedRef}
            className={cn(
              'overflow-x-hidden whitespace-pre text-transparent outline-none',
              /**
               * @description
               * angular 디자인 무너지는 부분 때문에 추가
               */
              'text-start',
              {
                'absolute left-[48px] w-[calc(100%-70px)] py-[7px] text-lds2-body2-regular': size === 's',
                'absolute left-[14px] w-[calc(100%-60px)] py-[7px] text-lds2-body2-regular': size === 'm',
                'absolute left-[30px] w-[calc(100%-206px)] py-[10px] text-lds2-heading4-medium': size === 'l',
              }
            )}
            data-testid="search-bar-input-ghost"
            contentEditable
            suppressContentEditableWarning
            dangerouslySetInnerHTML={{ __html: highlighted }}
          />

          <input
            id="innerSearchBox"
            type="search"
            autoComplete="off"
            ref={searchKeywordRef}
            className={cn(
              'resize-none overflow-hidden',
              'whitespace-pre',
              'bg-transparent outline-none',
              'p-0',
              'placeholder:text-zinc-400',
              /**
               * @description
               * angular 디자인 무너지는 부분 때문에 추가
               */
              'border-none',
              {
                'absolute left-[48px] h-[40px] w-[calc(100%-70px)] py-[8px] text-lds2-body2-regular': size === 's',
                'absolute left-[14px] h-[45px] w-[calc(100%-60px)] py-[10px] text-lds2-body2-regular': size === 'm',
                'absolute left-[30px] h-[45px] w-[calc(100%-206px)] py-[8px] text-lds2-heading4-medium': size === 'l',
              }
            )}
            autoFocus={autoFocus}
            placeholder={PLACEHOLDER_TEXT}
            value={searchKeyword}
            data-testid="search-bar-input"
            onInput={handleInputSearchKeyword}
            {...inputFieldProps}
          />

          <button
            type={size !== 's' ? 'submit' : 'button'}
            data-testid="search-bar-submit-button"
            className={cn(
              'select-none text-zinc-700',
              /**
               * @description
               * angular 디자인 무너지는 부분 때문에 추가
               */
              'm-0 w-fit',
              {
                'absolute left-[14px] top-[7px]': size === 's',
                'absolute right-[14px] top-[11px]': size === 'm',
                'absolute right-[30px] top-[25px]': size === 'l',
              }
            )}
          >
            <IconMagnifyingGlass size={size === 'm' || size === 's' ? 24 : 32} />
          </button>

          <SearchBarDividerLine
            size={size}
            isSearchDataPopoverOpen={isSearchDataPopoverOpen}
            hasSearchBarData={hasSearchBarData}
          />
        </form>

        {/* 검색 가이드 UI trigger 버튼 */}
        {size === 'l' && (
          <div className={cn('absolute right-[78px] top-[25px]')}>
            <Button
              ref={searchCommandGuideButtonRef}
              label="검색 가이드"
              size="small"
              buttonType="outlined"
              onClick={handleClickSearchCommandGuideTriggerButton}
            />
          </div>
        )}

        {/* 검색 데이터 팝오버 */}
        {/* NOTE: size가 s인 경우에는 SearchBottomSheet을 사용 */}
        {isSearchDataPopoverOpen && size !== 's' && (
          <SearchBarDataList
            ref={searchDataPopoverRef}
            size={size}
            query={searchKeyword}
            hasSearchBarData={hasSearchBarData}
            cursorOffset={cursorOffset}
            setHasSearchBarData={setHasSearchBarData}
            onSearch={handleSearchDataList}
            onClickTag={handleClickTag}
            onSearchJudge={handleSearchJudgeDataList}
            onFireUserEvent={onFireUserEvent}
          />
        )}
      </div>
      {/* 연산자 가이드 팝오버 */}
      <SearchCommandGuidePopover
        isOpen={isSearchCommandGuidePopoverOpen}
        renderTarget={searchCommandGuideButtonRef.current}
        closePopover={closeSearchCommandGuidePopover}
      />
    </>
  )
}

export default SearchBar
