import { Spacing } from '@lbox-kr/libra/v2'
import cn from 'classnames'

export const CaseUploadNotice = () => {
  return (
    <div className={cn('text-zinc-700')}>
      <h3 className={cn('text-lds2-body3-medium')}>[판결문 등록 안내]</h3>
      <Spacing size={12} />
      <ul className={cn('text-lds2-body3-regular', 'list-disc pl-[16px] marker:text-[10px]')}>
        <li>변호사님(본인)의 성함이 소송대리인/변호인란에 표기된 판결문만 등록 가능합니다.</li>
        <li>판결문 정본 또는 대법원 전자소송에서 다운로드한 판결문만 등록 가능합니다.</li>
        <li>
          의뢰인 또는 제3자가 엘박스를 통해 판결문의 열람 제한 요청 시, 비밀 유지 의무 준수를 위해 해당 판결문이 수정
          또는 비공개/삭제 될 수 있습니다.
        </li>
      </ul>
    </div>
  )
}
