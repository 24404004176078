import { USER_ALARM_API_ENDPOINT } from './endpoints'
import type { PostUserAlarmListParams, UserAlarm } from './types'
import type { PageableData } from '../../types/common'
import { lboxHttpClient } from '../../utils/api'

/**
 * 쿠키 정보를 이용해 현재 유저의 알림 개수 조회
 */
export async function getUserAlarmCount() {
  const { data } = await lboxHttpClient.get(USER_ALARM_API_ENDPOINT.count)
  return data
}

export async function postUserAlarmList({ types, page, size }: PostUserAlarmListParams) {
  const { data } = await lboxHttpClient.post<PageableData<UserAlarm[]>>(
    `${USER_ALARM_API_ENDPOINT.list}?page=${page}&size=${size}`,
    types
  )
  return data
}

export async function postUserAlarmRead() {
  const { data } = await lboxHttpClient.post(USER_ALARM_API_ENDPOINT.read)
  return data
}
