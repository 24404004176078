import { useMutation } from '@tanstack/react-query'

import { fetchAwsUploadSignature } from '../$apis/fetchAwsUploadSignature'
import { API_ENDPOINT } from '../../../constants/api/endpoint'
import { METHOD } from '../../../constants/api/method'

export const useFetchAwsUploadSignature = () => {
  return useMutation({
    mutationKey: [METHOD.post, API_ENDPOINT.upload.myCase],
    mutationFn: fetchAwsUploadSignature,
  })
}
