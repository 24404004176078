import { useEffect } from 'react'

import { IconMagnifyingGlass } from '@lbox/shared/components'

import { BOTTOM_SHEET_SHOW_UP_TRANSITION_DURATION_MS } from '@lbox-kr/libra/constants'
import { Spacing } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { PLACEHOLDER_TEXT } from '../$constants/placeholder'
import { useSearchBar } from '../$hooks/useSearchBar'
import type { SearchBarMobileProps } from '../$types/searchBar'
import SearchBottomSheetDataList from '../search-data-list/search-bottom-sheet-data-list/SearchBottomSheetDataList'

export default function SearchBarMobile({ placeholder, router, onFireUserEvent }: SearchBarMobileProps) {
  const {
    highlighted,
    searchKeyword,
    cursorOffset,
    searchBarRef,
    searchDataPopoverRef,
    ghostTextRef,
    highlightedRef,
    searchKeywordRef,
    handleInputSearchKeyword,
    handleClickTag,
    handleSearchJudgeDataList,
    handleClickSearchButton,
    handleSearchDataList,
  } = useSearchBar({ router, onFireUserEvent })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!searchKeywordRef.current) {
        return
      }

      searchKeywordRef.current.focus()
      searchKeywordRef.current.selectionStart = searchKeyword.length
    }, BOTTOM_SHEET_SHOW_UP_TRANSITION_DURATION_MS + 100)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <div>
        <form
          ref={searchBarRef}
          className={cn(
            'relative overflow-hidden',
            'flex w-full items-center justify-center',
            'h-[55px]',
            'rounded-[4px]',
            'bg-zinc-50',
            'pl-[14px] pr-[6px]',
            'text-lds2-body1-medium'
          )}
          onSubmit={handleClickSearchButton}
        >
          <div
            ref={ghostTextRef}
            className={cn(
              'invisible absolute left-[14px]',
              'h-[55px] w-[calc(100%-90px)]',
              'overflow-x-hidden whitespace-pre',
              'pb-[12px] pt-[14px]',
              'text-transparent outline-none'
            )}
          />

          <div
            id="innerSearchBoxGhost"
            ref={highlightedRef}
            className={cn(
              /**
               * @description
               * angular 디자인 무너지는 부분 때문에 추가
               */
              'text-start',
              'absolute left-[14px]',
              'h-[55px] w-[calc(100%-90px)]',
              'overflow-x-hidden whitespace-pre',
              'pb-[12px] pt-[14px]',
              'text-transparent outline-none'
            )}
            suppressContentEditableWarning
            contentEditable
            data-testid="search-bar-input-ghost"
            dangerouslySetInnerHTML={{ __html: highlighted }}
          />

          <input
            id="innerSearchBox"
            type="search"
            autoComplete="off"
            ref={searchKeywordRef}
            className={cn(
              'absolute left-[14px]',
              'h-[55px] w-[calc(100%-90px)]',
              'resize-none overflow-x-hidden bg-transparent',
              'pb-[12px] pt-[14px]',
              'whitespace-pre text-lds2-body1-medium',
              'outline-none',
              /**
               * @description
               * angular 디자인 무너지는 부분 때문에 추가
               */
              'border-none'
            )}
            placeholder={placeholder ?? PLACEHOLDER_TEXT}
            value={searchKeyword}
            data-testid="search-bar-input"
            onInput={handleInputSearchKeyword}
          />

          <div className={cn('ml-auto', 'grid grid-flow-col items-center')}>
            <button
              type="submit"
              data-testid="search-bar-submit-button"
              className={cn(
                'p-[6px]',
                /**
                 * @description
                 * angular 디자인 무너지는 부분 때문에 추가
                 */
                'm-0 w-fit'
              )}
            >
              <IconMagnifyingGlass size={24} />
            </button>
          </div>
        </form>
      </div>

      <Spacing size={36} />

      <SearchBottomSheetDataList
        ref={searchDataPopoverRef}
        query={searchKeyword}
        cursorOffset={cursorOffset}
        onSearch={handleSearchDataList}
        onSearchJudge={handleSearchJudgeDataList}
        onClickTag={handleClickTag}
        onFireUserEvent={onFireUserEvent}
      />
    </>
  )
}
