import useMe from '@lbox-kr/components/src/hooks/useMe'
import { useMutation, useQuery } from '@tanstack/react-query'

import { PROMOTIONS_API_ENDPOINTS } from './endpoints'
import {
  getPromotionsInterestServices,
  getPromotionsMarketingConsent,
  getPromotionsPeriods,
  postPromotionsInterestServices,
  putPromotionsMarketingConsent,
} from './fetchers'

export const PROMOTIONS_QUERY_KEYS = {
  base: [PROMOTIONS_API_ENDPOINTS.base],

  marketingConsent: [PROMOTIONS_API_ENDPOINTS.base, PROMOTIONS_API_ENDPOINTS.marketingConsent],
  interestServices: [PROMOTIONS_API_ENDPOINTS.base, PROMOTIONS_API_ENDPOINTS.interestServices],
  periods: (promotionName: string) => [PROMOTIONS_API_ENDPOINTS.base, PROMOTIONS_API_ENDPOINTS.periods, promotionName],
} as const

export const useGetPromotionsMarketingConsent = () => {
  const { isLoggedIn } = useMe()

  return useQuery({
    queryKey: PROMOTIONS_QUERY_KEYS.marketingConsent,
    queryFn: getPromotionsMarketingConsent,

    enabled: isLoggedIn,
  })
}

export const usePutPromotionsMarketingConsent = () => {
  return useMutation({ mutationFn: putPromotionsMarketingConsent })
}

export const useGetPromotionsInterestServices = () => {
  const { isLoggedIn } = useMe()

  return useQuery({
    queryKey: PROMOTIONS_QUERY_KEYS.interestServices,
    queryFn: getPromotionsInterestServices,

    retry: 1,
    enabled: isLoggedIn,
  })
}

export const usePostPromotionsInterestServices = () => {
  return useMutation({ mutationFn: postPromotionsInterestServices })
}

export const useGetPromotionsPeriods = ({ promotionName }: { promotionName: string }) => {
  return useQuery({
    queryKey: PROMOTIONS_QUERY_KEYS.periods(promotionName),
    queryFn: () => getPromotionsPeriods(promotionName),
  })
}
