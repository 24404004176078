import Link from 'next/link'

import { IconArrowSquareOut, IconInfo } from '@lbox/shared/components'
import { LBOX_PAGE_PATH, LFIND_PAGE_PATH, V2_PREFIX, externalUrl } from '@lbox/shared/constants'

import { Badge, Tooltip } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import MenuCategory from './menu-category/MenuCategory'
import MenuItem from './menu-item/MenuItem'
import { LFIND_HOST } from '../../../../../constants/paths'
import useMe from '../../../../../hooks/useMe'
import { useGetUserHasPlan } from '../../../../../hooks/user/useGetUserHasPlan'
import { useLawyerProfileOnboarding } from '../../../../../hooks/user/useLawyerProfileOnboarding'
import { useLogout } from '../../../../../hooks/user/useLogout'
import type { OnFireClientTrackingEvent, OnFireUserEvent } from '../../../../../types/event'
import { getDeviceId } from '../../../../../utils/fingerprint'
import { isRestrictedUser } from '../../../../../utils/user/checkIsRestrictedUser'
import ChallengeStatusButton from '../../../../ai-kkujun-challenge/ChallengeStatusButton'

interface MenuContentProps {
  onFireUserEvent: OnFireUserEvent
  onFireClientTrackingEvent?: OnFireClientTrackingEvent
}

const MenuContent = ({ onFireUserEvent, onFireClientTrackingEvent }: MenuContentProps) => {
  const { isJobLawyer, name, isLoggedIn, isGuest, lboxUser } = useMe()
  const { data: { userPlanDisplayName } = {} } = useGetUserHasPlan()
  const { logout } = useLogout({
    deviceId: getDeviceId(),
  })
  const { hasProfile } = useLawyerProfileOnboarding()

  const handleClickLogout = () => {
    logout()
    window.location.reload()
  }

  return (
    <>
      {/* 비로그인 */}
      {isGuest && (
        <div className="w-[240px] pb-[28px] pt-[26px] tablet:pb-0">
          <MenuItem
            targetPathV2={LBOX_PAGE_PATH.login.index()}
            type="로그인"
            label="로그인"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetPathV2={LBOX_PAGE_PATH.register.index()}
            hasThickBottomBorder
            type="회원가입"
            label="회원가입"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />

          <MenuCategory>기타</MenuCategory>
          <MenuItem
            targetURL={externalUrl.이용가이드}
            type="이용 가이드"
            label="이용 가이드"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetURL={externalUrl.고객센터}
            type="고객센터"
            label="고객센터"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetPathV2={LBOX_PAGE_PATH.pricing.index}
            type="요금제"
            label="요금제"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
        </div>
      )}

      {isLoggedIn && (
        <div className="w-[240px] pb-[60px] tablet:max-h-[calc(100vh-90px)] tablet:pb-0">
          <div className="block border-b-[8px] border-zinc-50 px-[16px] pb-[16px] pt-[24px] text-body1 tablet:hidden">
            <p>{name} 님</p>
            <p className={cn('text-lds2-caption1-regular text-zinc-900')}>{userPlanDisplayName}</p>
          </div>

          {isJobLawyer && !isRestrictedUser() && (
            <>
              <MenuCategory>
                {!hasProfile && (
                  <Tooltip
                    description="‘수행사건 기반 의뢰인 만나기’ 서비스 엘파인드에서 직접 수행하신 사건을 통해 선임 의지가 높은 의뢰인을 만나보세요."
                    color="white"
                    tooltipWrapperClassName={cn('min-w-0 max-w-[237px] whitespace-normal')}
                    initialDirection="left-center"
                    isPortalTooltip
                  >
                    <span className={cn('flex items-center gap-x-[2px]')}>
                      엘파인드 <IconInfo size={14} className={cn('fill-current')} />
                    </span>
                  </Tooltip>
                )}
                {hasProfile && (
                  <Tooltip description="엘파인드 새탭에서 열기" color="white" isPortalTooltip>
                    <Link
                      href={`https://${LFIND_HOST}${LFIND_PAGE_PATH.home}`}
                      target="_blank"
                      className={cn('flex items-center gap-x-[2px]')}
                    >
                      엘파인드 <IconArrowSquareOut size={14} className={cn('fill-current')} />
                    </Link>
                  </Tooltip>
                )}
              </MenuCategory>
              {hasProfile && (
                <>
                  <MenuItem
                    onClick={() => window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.lawyerProfile.index}`)}
                    type="내 프로필 관리"
                    label="내 프로필 관리"
                    labelComponent={<Badge.Number value="N" className={cn('ml-[8px]')} />}
                    onFireUserEvent={onFireUserEvent}
                    onFireClientTrackingEvent={onFireClientTrackingEvent}
                  />
                  <MenuItem
                    onClick={() =>
                      window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.lawyerProfile.caseManagement}`)
                    }
                    type="내 수행사건 관리"
                    label="내 수행사건 관리"
                    labelComponent={<Badge.Number value="N" className={cn('ml-[8px]')} />}
                    onFireUserEvent={onFireUserEvent}
                    onFireClientTrackingEvent={onFireClientTrackingEvent}
                  />
                </>
              )}
              {!hasProfile && (
                <MenuItem
                  onClick={() =>
                    window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.lawyerProfile.simpleOnboarding}`)
                  }
                  type="변호사 프로필"
                  label="변호사 프로필"
                  labelComponent={<Badge.Number value="N" className={cn('ml-[8px]')} />}
                  onFireUserEvent={onFireUserEvent}
                  onFireClientTrackingEvent={onFireClientTrackingEvent}
                />
              )}
              <MenuItem
                onClick={() => window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.lawyerProfile.myCase}`)}
                hasThickBottomBorder
                type="내 판례등록"
                label="내 수행사건 등록"
                onFireUserEvent={onFireUserEvent}
                onFireClientTrackingEvent={onFireClientTrackingEvent}
              />
            </>
          )}

          <div className="p-4 pb-0 empty:hidden">
            <ChallengeStatusButton />
          </div>

          <MenuCategory>계정</MenuCategory>
          <MenuItem
            targetPathV1="/user/myPage/info"
            type="계정/요금제"
            label="계정/요금제"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            type="알림 설정"
            label="알림 설정"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
            onClick={() => window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.user.myPage.alarm}`)}
          />
          <MenuItem
            targetPathV1="/user/logs/payment"
            type="결제 내역"
            label="결제 내역"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetPathV1="/user/logs/point"
            type="포인트 내역"
            label="포인트 내역"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          >
            <span>{(lboxUser?.point ?? 0).toLocaleString('ko-KR')}p</span>
          </MenuItem>
          <MenuItem
            targetPathV1="/user/invite"
            type="친구 초대"
            label="친구 초대"
            hasThickBottomBorder
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          >
            <span className="text-lbox-blue">70,000p 받기</span>
          </MenuItem>

          <MenuCategory>서비스 이용 내역</MenuCategory>
          <MenuItem
            targetPathV1="/user/requestCases"
            type="등록요청한 판례"
            label="등록요청한 판결문"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetPathV1="/user/subscribe"
            hasThickBottomBorder
            type="검색조건 알림"
            label="검색조건 알림"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />

          <MenuCategory>기타</MenuCategory>
          <MenuItem
            targetURL={externalUrl.이용가이드}
            type="이용 가이드"
            label="이용 가이드"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetURL={externalUrl.고객센터}
            type="고객센터"
            label="고객센터"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetPathV2={LBOX_PAGE_PATH.pricing.index}
            type="요금제"
            label="요금제"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
          <MenuItem
            targetPathV1="/event"
            hasThickBottomBorder
            type="이벤트"
            label="이벤트"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />

          <MenuItem
            onClick={handleClickLogout}
            type="로그아웃"
            label="로그아웃"
            onFireUserEvent={onFireUserEvent}
            onFireClientTrackingEvent={onFireClientTrackingEvent}
          />
        </div>
      )}
    </>
  )
}

export default MenuContent
