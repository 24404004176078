import type { CommandAll, CommandWithAt } from '../$types/command'
import { getCaseNoRegex } from '../../../utils/regex'

export interface FastSearchCommandState {
  commandType: CommandAll
  highlightedString: string
  startIndex: number
  endIndex: number
  isEditing?: boolean
}

export function getCommandStates(searchKeyword: string): FastSearchCommandState[] {
  const 명령어문장작성완료 = findMatchedCommands({
    searchKeyword,
    regexp: /@(사건명|주문|법조문|당사자명|사건부호|판결요지|청구취지|신청취지)\s"([^"]*)"/,
  })
  searchKeyword = replaceHighlightsFromString(searchKeyword, 명령어문장작성완료)

  const 명령어문장작성중 = findMatchedCommands({
    searchKeyword,
    regexp: /@(사건명|주문|법조문|당사자명|사건부호|판결요지|청구취지|신청취지)\s"([^"^\n]*)/,
  })
  searchKeyword = replaceHighlightsFromString(searchKeyword, 명령어문장작성중)

  const 완전일치작성완료 = findMatchedCommands({
    searchKeyword,
    commandType: '완전일치',
    regexp: /"([^"]*)"/,
  })
  searchKeyword = replaceHighlightsFromString(searchKeyword, 완전일치작성완료)

  const 완전일치작성중 = findMatchedCommands({
    searchKeyword,
    commandType: '완전일치',
    regexp: /"([^"^\n]*)/,
    isEditing: true,
  })
  searchKeyword = replaceHighlightsFromString(searchKeyword, 완전일치작성중)

  // 이제 큰따옴표 없음
  const 명령어검색어작성중 = findMatchedCommands({
    searchKeyword,
    regexp: /@(사건명|주문|법조문|당사자명|사건부호|판결요지|청구취지|신청취지)\s([^"^\n\s]*)/,
  })
  searchKeyword = replaceHighlightsFromString(searchKeyword, 명령어검색어작성중)

  const 제외어검색어작성중 = findMatchedCommands({
    searchKeyword,
    commandType: '제외어',
    regexp: /\s-[^\s]*/,
  })
  searchKeyword = replaceHighlightsFromString(searchKeyword, 제외어검색어작성중)

  const 사건번호작성중 = findMatchedCommands({
    searchKeyword,
    commandType: '사건번호',
    regexp: getCaseNoRegex(),
  })

  return [
    ...명령어문장작성완료,
    ...명령어문장작성중,
    ...완전일치작성완료,
    ...완전일치작성중,
    ...명령어검색어작성중,
    ...제외어검색어작성중,
    ...사건번호작성중,
  ]
}

function replaceStringFromString(inputString: string, stringToReplace: string): string {
  // 제거하는 대신 대체
  const startIndex = inputString.indexOf(stringToReplace)
  const endIndex = startIndex + stringToReplace.length

  return inputString.slice(0, startIndex) + '`'.repeat(stringToReplace.length) + inputString.slice(endIndex)
}

function replaceHighlightsFromString(inputString: string, commandStates: FastSearchCommandState[]) {
  // 제거하는 대신 대체?
  for (const commandState of commandStates) {
    inputString = replaceStringFromString(inputString, commandState.highlightedString)
  }

  return inputString
}

interface FindMatchedCommandsParams {
  commandType?: CommandAll
  regexp: RegExp
  searchKeyword: string
  isEditing?: boolean
}

function findMatchedCommands({
  commandType,
  regexp,
  searchKeyword,
  isEditing,
}: FindMatchedCommandsParams): FastSearchCommandState[] {
  const fastSearchCommandStates: FastSearchCommandState[] = []

  let matched = null
  while ((matched = searchKeyword.match(regexp)) !== null) {
    const highlightedString = matched[0].trimStart()
    const startIndex = searchKeyword.indexOf(matched[0].trimStart())
    fastSearchCommandStates.push({
      commandType: commandType ?? (matched[1] as CommandWithAt),
      highlightedString, // '"검색'
      startIndex,
      endIndex: startIndex + highlightedString.length,
      isEditing,
    })
    searchKeyword = replaceStringFromString(searchKeyword, highlightedString)
  }

  return fastSearchCommandStates
}
