import type { ForwardRefRenderFunction, PropsWithChildren } from 'react'
import { forwardRef, useImperativeHandle } from 'react'
import type { Accept, DropzoneOptions } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'

export interface FileUploadAreaProps {
  rootClassName?: ({ isDragActive }: { isDragActive: boolean }) => string
  accept: Accept
  onDrop?: DropzoneOptions['onDrop']
  onAccepted?: (files: File[]) => void
  onRejected?: (files: File[]) => void
}

export interface FileUploadAreaHandle {
  openFileExplorer: VoidFunction
}

export const FileUploadArea_: ForwardRefRenderFunction<FileUploadAreaHandle, PropsWithChildren<FileUploadAreaProps>> = (
  { rootClassName, accept, children, onDrop, onAccepted, onRejected },
  ref
) => {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    onDrop,
    onDropAccepted: onAccepted,
    onDropRejected: (fileRejections) => onRejected?.(fileRejections.map(({ file }) => file)),
  })

  useImperativeHandle(ref, () => {
    return {
      openFileExplorer: open,
    }
  }, [open])

  return (
    <section {...getRootProps()} className={rootClassName?.({ isDragActive })}>
      <input {...getInputProps()} />
      {children}
    </section>
  )
}

export const FileUploadArea = forwardRef(FileUploadArea_)
