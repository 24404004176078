import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { GetLawyerProfileParams, GetLawyerProfileResponse } from '../../apis/user/getLawyerProfile'
import { getLawyerProfile } from '../../apis/user/getLawyerProfile'
import { LFIND_API_ENDPOINT } from '../../constants/api/endpoint'
import { METHOD } from '../../constants/api/method'

export const useGetLawyerProfile = (
  { lawyerId }: GetLawyerProfileParams,
  options?: Omit<UseQueryOptions<GetLawyerProfileResponse>, 'queryKey' | 'queryFn'>
) => {
  return useQuery({
    queryKey: [METHOD.get, LFIND_API_ENDPOINT.lawyer.profile.byId.name, lawyerId],
    queryFn: () => getLawyerProfile({ lawyerId }),
    ...options,
  })
}
