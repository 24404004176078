import { API_ENDPOINT } from '../../../constants/api/endpoint'
import { lboxHttpClient } from '../../../utils/api'

type MyCaseRegisterStatus = 'Upload' | string

export type RegisterMyCaseApiRequestPayload = RegisterMyCaseApiRequestPayloadItem[]

export type RegisterMyCaseApiResponseData = RegisterMyCaseApiResponseItem[]

export interface RegisterMyCaseApiRequestPayloadItem {
  fileName: string
  filePath: string
  nameHide: boolean
}

export interface RegisterMyCaseApiResponseItem {
  id: number
  createdAt: number // UNIX epoch time
  reason: string | null
  nameHide: boolean
  anonymizationDoneAt: number | null
  rejectReason: string | null
  rejectedAt: number | null
  approvedAt: number | null
  fileName: string
  filePath: string
  docId: string | null
  userId: number
  state: MyCaseRegisterStatus
}

export const registerMyCase = async (payload: RegisterMyCaseApiRequestPayload) => {
  const { data } = await lboxHttpClient.post<RegisterMyCaseApiResponseData>(API_ENDPOINT.myCaseRegister.base, payload)

  return data
}
