import { IconClock, IconX } from '@lbox/shared/components'

import cn from 'classnames'

import type { RecentHistoriesProps } from '../$types/searchDataList'
import { getBelongedCourtsDetail } from '../$utils/getBelongedCourtsDetail'
import { isSearchedJudgeItem } from '../../$utils/histories'
import SearchBarDataListItem from '../search-bar-data-list-item/SearchBarDataListItem'

const RecentHistories = (props: RecentHistoriesProps) => {
  const { recentHistories, handleClickHistoryCloseIcon, handleClickSearchedJudgeItem, handleClickHistory } = props

  return (
    <div>
      <div className={cn('flex items-center justify-between', 'pb-[16px]')}>
        <span className={cn('text-lds2-body3-medium text-zinc-500')}>최근 검색한 항목</span>
      </div>

      {recentHistories.slice(0, 5).map((history) => {
        /** 추후 히스토리 타입이 많아진다면 각자 타입에 대한 뷰가 완성되어야 함. 현재는 String 이거나 SearchedJudgeItem 임 */
        if (isSearchedJudgeItem(history)) {
          return (
            <SearchBarDataListItem
              key={history.id}
              icon={<IconClock size={20} className="shrink-0 text-zinc-500" />}
              mainText={history.name}
              subText={getBelongedCourtsDetail(history)}
              trailingIcon={
                <button type="button" className="p-[8px]" onClick={(e) => handleClickHistoryCloseIcon(e, history)}>
                  <IconX size={16} className="text-zinc-500" />
                </button>
              }
              onClickListItem={() => handleClickSearchedJudgeItem(history)}
            />
          )
        }

        return (
          <SearchBarDataListItem
            key={history}
            icon={<IconClock size={20} className="shrink-0 text-zinc-500" />}
            mainText={history}
            trailingIcon={
              <button type="button" className="p-[8px]" onClick={(e) => handleClickHistoryCloseIcon(e, history)}>
                <IconX size={16} className="text-zinc-500" />
              </button>
            }
            onClickListItem={() => handleClickHistory(history)}
          />
        )
      })}
    </div>
  )
}

export default RecentHistories
