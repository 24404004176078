import { Button, Modal, Spacing } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import useGetCaseUploadPoint from './$hooks/useGetCaseUploadPoint'

interface CaseUploadEventModalProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const CaseUploadEventModal = ({ isOpen, onClose }: CaseUploadEventModalProps) => {
  const { point } = useGetCaseUploadPoint()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Headline>판결문 등록 이벤트</Modal.Headline>
      <Modal.Body>
        <p className={cn('text-lds2-body2-regular text-zinc-900')}>
          직접 수행하신 사건의 판결문 pdf 파일을 업로드하여 등록해 주실 때마다 1건 당 {point} 포인트가 적립됩니다.
        </p>
        <Spacing size={24} />
        <h3 className={cn('text-lds2-body2-semibold text-zinc-900')}>참여방법</h3>
        <Spacing size={8} />
        <ol className={cn('list-decimal pl-[20px] text-lds2-body2-regular text-zinc-900')}>
          <li>판결문 pdf 파일을 업로드하여 등록 신청을 해주세요.</li>
          <li>판결문의 개인정보 익명화 작업 후 카카오톡, 이메일로 승인 요청을 드립니다. (1건 당 2-3 영업일 소요)</li>
          <li>
            <b>‘마이페이지 {'>'} 내 수행사건 등록’</b> 페이지에서 익명화된 판결문 등록을 승인해주시면 판결문 등록과 함께
            포인트가 자동 적립됩니다.
          </li>
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="filled" width="full" label="확인" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  )
}
