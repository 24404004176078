import { useState } from 'react'

import { Spacing } from '@lbox-kr/libra/v2'
import { useTimeout } from '@toss/react'
import { AnimatePresence } from 'framer-motion'

import { useGetTargetUser } from './api'
import ChallengeDrawerTemplate from './ChallengeDrawerTemplate'
import ChallengeGradientText from './ChallengeGradientText'
import StatusChallengeContent from './StatusChallengeContent'
import StepWrapper from './StepWrapper'

export default function RenewChallengeDrawer({
  onClose,
  isOpen,
  onCloseComplete,
}: {
  onClose?: VoidFunction
  isOpen: boolean
  onCloseComplete?: VoidFunction
}) {
  const [step, setStep] = useState(0)
  return (
    <ChallengeDrawerTemplate onClose={onClose} isOpen={isOpen} onCloseComplete={onCloseComplete}>
      <AnimatePresence>
        {
          [
            <RenewStep1 onComplete={() => setStep(1)} key="renew1" />,
            <RenewStep2 onComplete={() => setStep(2)} key="renew2" />,
            <StepWrapper key="status">
              <StatusChallengeContent onClose={onClose} />
            </StepWrapper>,
          ][step]
        }
      </AnimatePresence>
    </ChallengeDrawerTemplate>
  )
}

function RenewStep1({ onComplete }: { onComplete: VoidFunction }) {
  const getQuery = useGetTargetUser()
  useTimeout(onComplete, 1500)
  return (
    <StepWrapper className="py-12 text-center" skipEnter>
      <p className="text-lds2-heading4-semibold text-text-primary">지금까지 획득한 포인트</p>
      <Spacing size={24} />
      <p>
        <ChallengeGradientText className="text-lds2-heading2-semibold">
          {getQuery.data?.data?.totalPoint.toLocaleString()}포인트
        </ChallengeGradientText>
      </p>
    </StepWrapper>
  )
}

function RenewStep2({ onComplete }: { onComplete: VoidFunction }) {
  useTimeout(onComplete, 2000)
  return (
    <StepWrapper className="py-12 text-center">
      <p className="text-lds2-heading4-semibold text-text-primary">다음 챌린지에서도 포인트를 모아보세요</p>
    </StepWrapper>
  )
}
