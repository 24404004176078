import { lboxHttpClient } from '@lbox-kr/components/src/utils/api'

import { PROMOTIONS_API_ENDPOINTS } from './endpoints'

export interface GetPromotionsMarketingConsentResponse {
  status: number
  code: string
  data: boolean
}

export const getPromotionsMarketingConsent = async () => {
  const { data } = await lboxHttpClient.get<GetPromotionsMarketingConsentResponse>(
    PROMOTIONS_API_ENDPOINTS.marketingConsent
  )

  return data
}

export const putPromotionsMarketingConsent = async () => {
  await lboxHttpClient.put(PROMOTIONS_API_ENDPOINTS.marketingConsent)
}

export interface GetPromotionsInterestServicesResponse {
  status: number
  code: string
  data: {
    interestedInScholar: boolean
    interestedInAiEditor: boolean
    interestedInLfind: boolean
    interestedInCaseArchive: boolean
  }
}

export const getPromotionsInterestServices = async () => {
  const { data } = await lboxHttpClient.get<GetPromotionsInterestServicesResponse>(
    PROMOTIONS_API_ENDPOINTS.interestServices
  )

  return data
}

export interface PostPromotionsInterestServicesRequestBody {
  interestedInScholar: boolean
  interestedInAiEditor: boolean
  interestedInLfind: boolean
  interestedInCaseArchive: boolean
}

export const postPromotionsInterestServices = async (data: PostPromotionsInterestServicesRequestBody) => {
  await lboxHttpClient.post(PROMOTIONS_API_ENDPOINTS.interestServices, { ...data })
}

export interface GetPromotionsPeriodsResponse {
  status: number
  code: string
  data: {
    promotionName: string
    isEventInProgress: boolean
    from: string
    to: string
  }
}

export const getPromotionsPeriods = async (promotionName: string) => {
  const { data } = await lboxHttpClient.get<GetPromotionsPeriodsResponse>(PROMOTIONS_API_ENDPOINTS.periods, {
    params: {
      promotionName,
    },
  })

  return data
}
