import { API_ENDPOINT } from '../../../constants/api/endpoint'
import { lboxHttpClient } from '../../../utils/api'

export interface PostVerifyLawyerRequest {
  licenseInfo?: {
    registrationNumber: string
    issueNumber: string
  }
  lawyerInfo?: {
    testType: string
    sequence: string
    association: string
  }
}

export async function postVerifyLawyer(req: PostVerifyLawyerRequest) {
  const { data } = await lboxHttpClient.post<{ id: string; userId: string }>(API_ENDPOINT.lboxUser.verifyLawyer, req)
  return data
}
