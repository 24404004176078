import { Button, Modal } from '@lbox-kr/libra/v2'
import cn from 'classnames'

interface CaseUploadCompleteModalProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const CaseUploadCompleteModal = ({ isOpen, onClose }: CaseUploadCompleteModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Headline>판결문 등록 신청 완료</Modal.Headline>
      <Modal.Body className={cn('text-lds2-body2-regular text-zinc-900')}>
        등록 신청해 주신 판결문의 익명화 작업 후 카카오톡, 이메일로 승인 요청을 드립니다(1건 당 2-3 영업일이 소요).
        <br />
        <br />
        승인 요청을 받으신 후, <b>마이페이지 {'>'} 내 수행사건 등록</b> 페이지에서 익명화된 판결문 등록을 승인해주시면
        엘박스에 최종 등록됩니다.
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="filled" width="full" label="확인" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  )
}
