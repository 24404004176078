export const LFIND_PAGE_PATH = {
  /** 홈 */
  home: '/',
  search: {
    base: '/search',
  },
  cases: {
    /** 사건 상세 페이지 */
    base: (docId: string) => {
      const [court, ...id] = docId.split('-')
      return `/cases/${court}/${id.join('-')}`
    },
    /** 판결문 전문 페이지 */
    viewer: (docId: string) => {
      const [court, ...id] = docId.split('-')
      return `/cases/${court}/${id.join('-')}/viewer`
    },
    /** 사건을 찾을 수 없을 때의 404페이지 */
    notFound: '/cases/not-found',
  },
  lawyers: {
    /** 변호사 프로필 페이지 */
    profile: (id: string | number, name: string) => `/lawyers/${name}-${id}`,
  },
  brandStory: '/brand-story',
  /** 프로필 소개페이지 */
  profileIntro: '/profile-intro',
} as const
