import type { FormEvent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import { LBOX_PAGE_PATH, SEARCH_LOG_LOCAL_STORAGE_KEY, V2_PREFIX } from '@lbox/shared/constants'
import { webLocalStorage } from '@lbox/shared/utils'

import { useSnackbar } from '@lbox-kr/libra/v2'
import { useBooleanState } from '@toss/react'
import { isClient } from '@toss/utils'
import queryString from 'query-string'

import { useGetAvailableFastSearchCommandsByURL } from './useAvailableFastSearchCommands'
import useSearchHistories from './useSearchHistories'
import type { History, SearchedJudgeItem } from '../$types/histories'
import type { SearchBarProps } from '../$types/searchBar'
import { getAmplitudePageType } from '../$utils/getAmplitudePageType'
import { highlightSearchKeyword } from '../$utils/highlightKeyword'
import { processQuery } from '../$utils/histories'
import { JUDGE_ANALYTICS_DETAIL_URL } from '../../../constants/#legal-analytics/judge-analytics/page'

type UseSearchBarParams = Pick<SearchBarProps, 'enableOpenSearchDataPopover' | 'onFireUserEvent' | 'router'>

export const useSearchBar = ({ enableOpenSearchDataPopover, onFireUserEvent, router }: UseSearchBarParams) => {
  const { addHistory } = useSearchHistories()
  const availableFastSearchCommands = useGetAvailableFastSearchCommandsByURL()

  const windowQuery = isClient() ? window.location.search : ''

  const parsedWindowQuery = useMemo(() => queryString.parse(windowQuery), [])

  const previousSearchKeyword = router
    ? ((router.query['query'] as string) ?? '')
    : ((parsedWindowQuery['query'] as string) ?? '')
  const previousHighlightedSearchKeyword = highlightSearchKeyword({
    searchKeyword: previousSearchKeyword,
    availableFastSearchCommands,
  })

  const [searchKeyword, setSearchKeyword] = useState<string>(previousSearchKeyword)
  const [highlighted, setHighlighted] = useState<string>(previousHighlightedSearchKeyword)
  const [cursorOffset, setCursorOffset] = useState<number>(0)

  const [isSearchDataPopoverOpen, openSearchDataPopover, closeSearchDataPopover] = useBooleanState(false)
  const [isSearchCommandGuidePopoverOpen, openSearchCommandGuidePopover, closeSearchCommandGuidePopover] =
    useBooleanState(false)

  const snackbar = useSnackbar()

  const searchBarRef = useRef<HTMLFormElement>(null)
  const searchDataPopoverRef = useRef<HTMLDivElement>(null)

  const ghostTextRef = useRef<HTMLDivElement>(null)
  const highlightedRef = useRef<HTMLDivElement>(null)
  const searchKeywordRef = useRef<HTMLInputElement>(null)
  const isFocusDisabledRef = useRef<boolean>(true)

  const searchCommandGuideButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    isFocusDisabledRef.current = true
    const searchKeywordFromRoute = router
      ? ((router.query['query'] as string) ?? '')
      : ((parsedWindowQuery['query'] as string) ?? '')

    setSearchKeyword(searchKeywordFromRoute)
    setHighlighted(highlightSearchKeyword({ searchKeyword: searchKeywordFromRoute, availableFastSearchCommands }))
    setCursorOffset(0)
    closeSearchDataPopover()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query, parsedWindowQuery])

  useEffect(() => {
    // 명령어 추가된 이후의 커서 위치를 계산해서
    // 해당 위치로 포커스 및 커서 이동, 필요하면 스크롤해야 함
    if (ghostTextRef.current) {
      ghostTextRef.current.textContent = searchKeyword.slice(0, cursorOffset)
      const scrollLeft = ghostTextRef.current.scrollWidth - ghostTextRef.current.offsetWidth

      if (isFocusDisabledRef.current === true) {
        isFocusDisabledRef.current = false
      } else {
        searchKeywordRef.current?.focus()
        searchKeywordRef.current?.setSelectionRange(cursorOffset, cursorOffset)
      }

      // focus 후에 scroll 해야함
      highlightedRef.current?.scroll({ left: scrollLeft })
      searchKeywordRef.current?.scroll({ left: scrollLeft })
    }
  }, [searchKeyword, cursorOffset])

  /**
   * 실제로 새로운 검색결과로 이동할 때 거쳐가는 함수
   */
  function searchWithQuery(query: History) {
    const processedQuery = processQuery(query)

    if (processedQuery.trim().length === 0) {
      searchKeywordRef.current?.blur()
      snackbar.error({ message: '검색어를 입력해주세요' })

      return
    }

    const { pathname } = window.location
    const isMainPage = pathname === `/${V2_PREFIX}`

    const isCommentaryPage = pathname === `/${V2_PREFIX}${LBOX_PAGE_PATH.search.commentary}`
    const typeQuery = isCommentaryPage ? { type: '검색' } : null

    const stringifiedQuery = new URLSearchParams({
      ...parsedWindowQuery,
      ...typeQuery,
      query: processedQuery,
      page: '1',
    })

    const searchSessionId = webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.SEARCH_SESSION_ID, null)

    onFireUserEvent(isMainPage ? 'click_main_search' : 'click_search', {
      keyword: processedQuery,
      page: getAmplitudePageType(pathname),
      isLeafEvent: true,
      search_session_id: searchSessionId,
    })

    addHistory(processedQuery)

    // NOTE: react에서는 router로 이동, angular에서는 window로 이동
    if (!router) {
      window.location.assign(`/${V2_PREFIX}${getTargetUrlPath()}?${stringifiedQuery}`)
      closeSearchDataPopover()

      return
    }

    router.push({
      pathname: getTargetUrlPath(),
      query: {
        ...router.query,
        ...typeQuery,
        query: processedQuery,
        page: '1',
      },
    })

    closeSearchDataPopover()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleInputSearchKeyword(e: any) {
    // iOS에서 앞/뒤 큰따옴표 사용 방지
    const newValue = e.currentTarget.value.replace(/[“”]/g, '"').replace(/\n/g, ' ')
    setSearchKeyword(newValue)
    setHighlighted(highlightSearchKeyword({ searchKeyword: newValue, availableFastSearchCommands }))
    // 상태값 바꾸기 전 커서 위치
    setCursorOffset(searchKeywordRef.current?.selectionStart ?? 0)
    openSearchDataPopover()
  }

  // 명령어 태그 클릭해서 검색어에 추가
  function handleClickTag(command: string) {
    let searchKeywordWithCommand = ''
    let targetCursorOffset = cursorOffset

    onFireUserEvent('click_fastsearch', { keyword: command })

    switch (command) {
      case '"완전일치"': {
        searchKeywordWithCommand = `${searchKeyword.slice(0, cursorOffset)}"${searchKeyword.slice(cursorOffset)}`
        targetCursorOffset = cursorOffset + 1
        break
      }
      case '-제외어': {
        searchKeywordWithCommand = `${searchKeyword.slice(0, cursorOffset)} -${searchKeyword.slice(cursorOffset)}`
        targetCursorOffset = cursorOffset + 2
        break
      }
      default: {
        // 명령어 입력 시 명령어와 중첩되는 텍스트 제거
        // 예) @법무 까지 입력하고 @법무법인 클릭할 경우 @법무 문자열을 제거한 후 @법무법인 추가
        let atIndex = cursorOffset
        for (; atIndex > 0; atIndex--) {
          const letter = searchKeyword.at(atIndex)
          if (letter === '@') {
            break
          }
        }
        searchKeywordWithCommand = `${searchKeyword.slice(0, atIndex)}${command} ${searchKeyword.slice(cursorOffset)}`
        targetCursorOffset = cursorOffset + command.length + 1 - (cursorOffset - atIndex)
      }
    }

    setSearchKeyword(searchKeywordWithCommand)
    setHighlighted(highlightSearchKeyword({ searchKeyword: searchKeywordWithCommand, availableFastSearchCommands }))
    setCursorOffset(targetCursorOffset)
    openSearchDataPopover()
  }

  function handleSearchJudgeDataList(searchedJudgeItem: SearchedJudgeItem) {
    setSearchKeyword(searchedJudgeItem.name)

    addHistory(searchedJudgeItem)

    // NOTE: react에서는 router로 이동, angular에서는 window로 이동
    if (router) {
      router.push(`${JUDGE_ANALYTICS_DETAIL_URL}/${searchedJudgeItem.name}/${searchedJudgeItem.serial}`)
      return
    }

    window.location.assign(
      `/${V2_PREFIX}${JUDGE_ANALYTICS_DETAIL_URL}/${searchedJudgeItem.name}/${searchedJudgeItem.serial}`
    )
  }

  function handleClickSearchButton(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    searchWithQuery(searchKeyword)
  }

  function handleSearchDataList(query: History) {
    searchWithQuery(query)
  }

  function handleClickSearchBar($event: React.MouseEvent) {
    const clickedElem = $event.target as HTMLElement
    const searchCommandGuideButton = searchCommandGuideButtonRef.current

    if (!searchCommandGuideButton?.contains(clickedElem) && enableOpenSearchDataPopover) {
      openSearchDataPopover()
    }
  }

  function handleClickSearchCommandGuideTriggerButton() {
    openSearchCommandGuidePopover()
    onFireUserEvent('click_main_searchguide')
  }

  return {
    isSearchDataPopoverOpen,
    isSearchCommandGuidePopoverOpen,
    highlighted,
    searchKeyword,
    cursorOffset,
    searchBarRef,
    searchDataPopoverRef,
    ghostTextRef,
    highlightedRef,
    searchKeywordRef,
    searchCommandGuideButtonRef,
    handleInputSearchKeyword,
    handleClickTag,
    handleSearchJudgeDataList,
    handleClickSearchButton,
    handleSearchDataList,
    handleClickSearchBar,
    handleClickSearchCommandGuideTriggerButton,
    closeSearchDataPopover,
    closeSearchCommandGuidePopover,
  }
}

/**
 * 검색 화면으로 이동하는 경로를 반환하는 함수입니다.
 * 이미 검색 페이지인 경우 현재 경로를 반환하고, 아닌 경우 검색 페이지 경로를 반환합니다.
 */
function getTargetUrlPath() {
  const { pathname } = window.location
  const isSearchPage = pathname.includes(`/${V2_PREFIX}/search`)
  const targetPath = isSearchPage ? pathname.replace(`/${V2_PREFIX}`, '') : LBOX_PAGE_PATH.search.case

  return targetPath
}
