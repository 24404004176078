import { useMutation } from '@tanstack/react-query'

import { uploadMyCaseToAwsS3 } from '../$apis/uploadMyCaseToAwsS3'
import { METHOD } from '../../../constants/api/method'

export const useUploadMyCaseToAwsS3 = () => {
  return useMutation({
    mutationKey: [METHOD.post, 'aws-s3-bucket-hosts'],
    mutationFn: uploadMyCaseToAwsS3,
  })
}
