import { polymorphicFactory } from '@lbox/shared/utils'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils/twMergeLDS'

interface HyperlinkProps {
  size?: '14px' | '16px' | '18px'
  level?: 'medium' | 'low'
}

export const Hyperlink = polymorphicFactory<{ defaultAs: 'a'; defaultRef: HTMLAnchorElement; props: HyperlinkProps }>(
  function Hyperlink({ as, size = '16px', level = 'medium', className, children, ...props }, ref) {
    const Component = as || 'a'

    return (
      <Component
        ref={ref}
        className={twMergeLDS(
          cn(
            'w-fit cursor-pointer rounded-[4px] px-[2px] underline interaction-state-high-inline',
            {
              'text-lds2-body1-regular': size === '18px',
              'text-lds2-body2-regular': size === '16px',
              'text-lds2-body3-regular': size === '14px',
            },
            { 'text-text-primary': level === 'medium', 'text-text-tertiary': level === 'low' },
            className
          )
        )}
        {...props}
      >
        {children}
      </Component>
    )
  }
)
