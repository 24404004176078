import { useMutation } from '@tanstack/react-query'

import { registerMyCase } from '../$apis/registerMyCase'
import { API_ENDPOINT } from '../../../constants/api/endpoint'
import { METHOD } from '../../../constants/api/method'

export interface UseRegisterMyCaseParams {
  onSuccess?: VoidFunction
  onError?: VoidFunction
}

export const useRegisterMyCase = ({ onSuccess, onError }: UseRegisterMyCaseParams = {}) => {
  return useMutation({
    mutationKey: [METHOD.post, API_ENDPOINT.myCaseRegister.base],
    mutationFn: registerMyCase,
    onSuccess,
    onError,
  })
}
