import cn from 'classnames'

export interface TagFastSearchCommandProps {
  label: string
  onClick?: VoidFunction
}

/** 빠른검색명령어 태그 */
export const TagFastSearchCommand = ({ label, onClick }: TagFastSearchCommandProps) => {
  return (
    <button
      className={cn('rounded-[4px] bg-zinc-50 p-1.5', 'hover:bg-state-layer-on-light-01', 'text-body4 text-zinc-700')}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  )
}
