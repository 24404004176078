import type { MouseEventHandler } from 'react'

import { IconDotsNine, IconMagnifyingGlass } from '@lbox/shared/components'
import { TIME } from '@lbox/shared/constants'
import { logger } from '@lbox/shared/utils'

import { IconOnlyButton, Tooltip } from '@lbox-kr/libra/v2'
import { useQueryClient } from '@tanstack/react-query'
import cn from 'classnames'

import { getTipAdjust } from '../../$utils/getTipAdjust'
import { LOG_MESSAGES } from '../../../../apis/header/logMessages'
import {
  POST_USER_ARARM_LIST_DEFAULT_PAGE,
  POST_USER_ARARM_LIST_DEFAULT_SIZE,
} from '../../../../apis/user-alarm/constants'
import { postUserAlarmList } from '../../../../apis/user-alarm/fetchers'
import { USER_ALARM_QUERY_KEYS, useGetUserAlarmCount } from '../../../../apis/user-alarm/queries'
import { isRestrictedUser } from '../../../../utils/user/checkIsRestrictedUser'
import ProfileButton from '../profile-button/ProfileButton'
import CaseUploadButton from './case-upload-button/CaseUploadButton'
import NotificationWithBadgeIcon from './notification-with-badge-icon/NotificationWithBadgeIcon'

interface UserButtonsBaseProps {
  isJobLawyer: boolean
  name: string
  onClickRegisterMyCaseButton: VoidFunction
  onClickNotificationButton: MouseEventHandler<HTMLButtonElement>
  onClickProfileButton: MouseEventHandler<HTMLButtonElement>
}

interface UserButtonsType1Props extends UserButtonsBaseProps {
  type: 'type1'
  onClickSearchButton: VoidFunction
  onClickMenuButton: MouseEventHandler<HTMLButtonElement>
}

interface UserButtonsType2Props extends UserButtonsBaseProps {
  type: 'type2'
  onClickMenuButton: MouseEventHandler<HTMLButtonElement>
}

interface UserButtonsType3Props extends UserButtonsBaseProps {
  type: 'type3'
}

type UserButtonsProps = UserButtonsType1Props | UserButtonsType2Props | UserButtonsType3Props

/**
 * 공통 - 내 판결문 등록 버튼 / 알림 버튼 / 유저 프로필 버튼
 * type1 - 검색창 열기 버튼 / 서비스 메뉴 버튼
 * type2 - 서비스 메뉴 버튼
 */
const UserButtons = (props: UserButtonsProps) => {
  const { type, isJobLawyer, name, onClickRegisterMyCaseButton, onClickNotificationButton, onClickProfileButton } =
    props

  const queryClient = useQueryClient()

  const { data: notificationsCount = 0 } = useGetUserAlarmCount()

  const tipAdjust = getTipAdjust()

  const prefetchAlarmList = async () => {
    const queryParams = {
      types: [],
      page: POST_USER_ARARM_LIST_DEFAULT_PAGE,
      size: POST_USER_ARARM_LIST_DEFAULT_SIZE,
    }
    try {
      queryClient.prefetchQuery({
        queryKey: USER_ALARM_QUERY_KEYS.list(queryParams),
        queryFn: () => postUserAlarmList(queryParams),
        staleTime: TIME['1분'],
      })
    } catch (error) {
      logger.error(
        `${LOG_MESSAGES.postUserAlarmList} prefetchQuery`,
        {
          occured_at: 'UserButtons',
        },
        error as Error
      )
    }
  }

  return (
    <div className="grid grid-flow-col items-center gap-[8px]">
      <CaseUploadButton isJobLawyer={isJobLawyer} onClickRegisterMyCaseButton={onClickRegisterMyCaseButton} />

      {type === 'type1' && (
        <div className="block lds2-tablet:hidden">
          <Tooltip description="검색창 열기" color="white" tipAdjust={tipAdjust} isPortalTooltip>
            <IconOnlyButton
              className={cn('flex')}
              buttonType="ghost"
              icon={IconMagnifyingGlass}
              onClick={props.onClickSearchButton}
            />
          </Tooltip>
        </div>
      )}

      {(type === 'type1' || type === 'type2') && (
        <Tooltip description="서비스" color="white" tipAdjust={tipAdjust} isPortalTooltip>
          <IconOnlyButton
            buttonType="ghost"
            icon={IconDotsNine}
            iconProps={{ weight: 'bold' }}
            onClick={props.onClickMenuButton}
          />
        </Tooltip>
      )}

      {!isRestrictedUser() && (
        <Tooltip description="알림" color="white" tipAdjust={tipAdjust} isPortalTooltip>
          <NotificationWithBadgeIcon
            notificationsCount={notificationsCount}
            onClick={onClickNotificationButton}
            onMouseEnter={prefetchAlarmList}
            onFocus={prefetchAlarmList}
          />
        </Tooltip>
      )}

      <div className="block lds2-tablet:hidden">
        <ProfileButton isIconOnly onClick={onClickProfileButton} />
      </div>

      <div className="hidden lds2-tablet:block">
        <ProfileButton userName={name} onClick={onClickProfileButton} />
      </div>
    </div>
  )
}

export default UserButtons
