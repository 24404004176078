import { Portal } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { SLIDING_FROM_RIGHT } from '../../../constants/animation'
import { IconCloseLarge } from '../../atoms/icons'
import { Backdrop } from '../../v2/backdrop/Backdrop'

export interface DrawerProps {
  isOpen: boolean
  onClickTopRightCloseButton: VoidFunction
  onClickBackdrop: VoidFunction
}

export const Drawer = ({
  isOpen,
  onClickTopRightCloseButton,
  onClickBackdrop,
  children,
}: StrictPropsWithChildren<DrawerProps>) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Portal rootId="drawer-root" isOpen={isOpen}>
          <Backdrop isDimmed isFadeAnimated onClick={onClickBackdrop} />

          <motion.section
            className="fixed right-0 top-0 h-full overflow-y-auto bg-white shadow-[0_0_25px_rgba(0,0,0,0.15)]"
            {...SLIDING_FROM_RIGHT}
          >
            <button
              type="button"
              className={cn('absolute right-2 top-4', 'p-2', 'text-zinc-900')}
              onClick={onClickTopRightCloseButton}
            >
              <IconCloseLarge />
            </button>
            {children}
          </motion.section>
        </Portal>
      )}
    </AnimatePresence>
  )
}
