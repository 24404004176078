import { useGetPromotionsPeriods } from '../../../apis/promotions/queries'

const useGetCaseUploadPoint = () => {
  const { data: promotionPeriods } = useGetPromotionsPeriods({
    promotionName: 'LAWYER_GROWTH_20000',
  })

  const isEventPeriod = Boolean(promotionPeriods?.data.isEventInProgress)

  return {
    point: isEventPeriod ? '10,000' : '5,000',
    isEventPeriod,
  }
}

export default useGetCaseUploadPoint
