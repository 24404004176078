import type { ReactNode } from 'react'

import { IconX } from '@lbox/shared/components'

import { Button, IconOnlyButton } from '@lbox-kr/libra/v2'
import cn from 'classnames'

interface SearchCommandGuideContentProps {
  isMobile?: boolean
  onClickSearchCommandRequestButton?: VoidFunction
  onClickCloseButton?: VoidFunction
}

export const SearchCommandGuideContent = ({
  isMobile = false,
  onClickSearchCommandRequestButton,
  onClickCloseButton,
}: SearchCommandGuideContentProps) => {
  return (
    <div>
      {/* heading 영역 */}
      <div className={cn('flex justify-between')}>
        <p className={cn('self-start', 'text-lds2-body3-medium text-zinc-500')}>검색 가이드</p>
        <IconOnlyButton buttonType="ghost" icon={IconX} size="small" onClick={onClickCloseButton} />
      </div>
      {/* body 영역 */}
      <div className={cn('mt-[16px]', 'overflow-y-auto', { 'max-h-[calc(var(--custom-vh,1vh)*100-200px)]': isMobile })}>
        <SearchCommandGuideContent.Row
          commandName="모두 포함"
          command={<SearchCommandGuideContent.CommandBadge label="Space" />}
          commandExplanation="모든 단어를 포함한 결과 제공"
          commandExplanationExample="ex.) 기본권 자유 검색 시, 기본권과 자유가 모두 포함된 판결문 검색"
        />
        <SearchCommandGuideContent.Row
          commandName="제외어"
          command={
            <div className={cn('flex items-center justify-center gap-x-[8px]', 'w-full', 'h-fit', 'px-[8px]')}>
              <span className={cn('text-orange-700')}>가</span>
              <SearchCommandGuideContent.CommandBadge label="-나" />
            </div>
          }
          commandExplanation={
            <div className="flex flex-col gap-y-[4px]">
              <div>
                <span className={cn('text-orange-700')}>가</span> 내용 중{' '}
                <span className={cn('text-blue-700')}>나</span>를 제외한 결과 제공
              </div>
              <div>
                *<span className={cn('text-orange-700')}>가</span> 와 <span className={cn('text-blue-700')}>-나</span>{' '}
                사이에 공백 필요
              </div>
            </div>
          }
          commandExplanationExample={
            <span>
              ex.) <span className="text-orange-700">소유권</span> <span className="text-blue-700">-양도</span> 검색 시,
              소유권이 포함되고, 양도는 포함되지 않은 판결문 검색
            </span>
          }
        />
        <SearchCommandGuideContent.Row
          commandName="완전일치"
          command={
            <div className={cn('flex items-center justify-between', 'w-full', 'h-fit')}>
              <SearchCommandGuideContent.CommandBadge label="“" />
              <span className={cn('text-blue-700')}>내용</span>
              <SearchCommandGuideContent.CommandBadge label="“" />
            </div>
          }
          commandExplanation={
            <>
              큰 따옴표 안의 <span className={cn('text-blue-700')}>내용</span>과 정확히 일치하는 결과 검색
            </>
          }
          commandExplanationExample='ex.) "항소를 기각한다" 검색 시, 정확히 “항소를 기각한다” 라는 구문이 포함된 판결문 검색'
        />
      </div>

      {/* 하단 버튼 영역 */}
      {!isMobile && (
        <div className={cn('flex justify-end', 'mt-[16px]')}>
          <Button
            type="button"
            buttonType="outlined"
            label="검색 연산자 요청"
            size="medium"
            onClick={onClickSearchCommandRequestButton}
          />
        </div>
      )}
    </div>
  )
}

interface RowProps {
  commandName: ReactNode
  command: ReactNode
  commandExplanation: ReactNode
  commandExplanationExample: ReactNode
}

SearchCommandGuideContent.Row = function Row({
  commandName,
  command,
  commandExplanation,
  commandExplanationExample,
}: RowProps) {
  function VerticalLine() {
    return <div className={cn('h-full', 'mx-[8px]', 'border-l-[0.5px] border-l-zinc-200')} />
  }

  return (
    <div className={cn('flex', 'border-t border-t-zinc-100', 'pt-[8px] not-last-of-type:pb-[8px]')}>
      <div className={cn('shrink-0 basis-[70px]')}>{commandName}</div>
      <VerticalLine />
      <div className={cn('flex shrink-0 basis-[84px] justify-center', 'text-lds2-body2-medium')}>{command}</div>
      <VerticalLine />
      <div className={cn('grid grid-rows-[repeat(2,fit-content(100%))]', 'grow')}>
        <div className={cn('text-lds2-body2-regular text-zinc-900')}>{commandExplanation}</div>
        <div className={cn('text-lds2-body3-regular text-zinc-600', 'mt-[4px]')}>{commandExplanationExample}</div>
      </div>
    </div>
  )
}

interface CommandBadgeProps {
  label: string
}

SearchCommandGuideContent.CommandBadge = function CommandBadge({ label }: CommandBadgeProps) {
  return (
    <div
      className={cn(
        'flex justify-center',
        'h-fit',
        'px-[8px] py-[4px]',
        'text-lds2-body-medium text-blue-700',
        'bg-zinc-100',
        'rounded-[8px] border border-zinc-300'
      )}
    >
      {label}
    </div>
  )
}
