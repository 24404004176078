import { IconInfo } from '@lbox/shared/components'

import { Button, Modal, Spacing } from '@lbox-kr/libra/v2'
import cn from 'classnames'

interface CaseUploadFileLimitModalProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const CaseUploadFileLimitModal = ({ isOpen, onClose }: CaseUploadFileLimitModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Headline>업로드 실패</Modal.Headline>
      <Modal.Body>
        원활한 서비스 이용을 위해 판결문 업로드는 한번에 30개까지 가능합니다. 판결문의 개수를 확인하신 후 다시
        시도해주세요.
        <Spacing size={24} />
        <p className={cn('flex gap-x-[4px] text-lds2-body3-regular text-zinc-500')}>
          <IconInfo size={16} className={cn('mt-[4px] shrink-0 fill-zinc-500')} />
          100개 이상의 판결문을 업로드 하실 경우 help@lbox.kr로 파일을 첨부하여 보내주시면 등록 신청을 도와드리겠습니다.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="filled" size="large" width="full" label="확인" onClick={onClose} />
      </Modal.Footer>
    </Modal>
  )
}
