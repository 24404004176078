import { Portal } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import { Backdrop } from '../../v2/backdrop/Backdrop'

export interface PopoverProps extends PopoverPosition {
  isOpen: boolean
  handleClickBackdrop?: VoidFunction
}

export interface PopoverPosition {
  top: number
  left: number | null
  right: number | null
  hasBackdrop?: boolean
}

/** 버튼 클릭 시 버튼 좌/우 끝 기준으로 표시되는 팝오버 */
export function Popover({
  isOpen,
  top,
  left,
  right,
  handleClickBackdrop,
  hasBackdrop = true,
  children,
}: StrictPropsWithChildren<PopoverProps>) {
  return (
    <Portal isOpen={isOpen} rootId="modal-root">
      {hasBackdrop && <Backdrop onClick={handleClickBackdrop} />}

      <section
        className="absolute rounded-[5px] border-[1px] border-zinc-200 bg-white shadow-[0_4px_16px_rgba(0,0,0,0.15)]"
        style={{
          top,
          left: left ?? 'unset',
          right: right ?? 'unset',
        }}
      >
        <div className="overflow-y-auto">{children}</div>
      </section>
    </Portal>
  )
}
