import { ROUTE_HANDLER_API_ENDPOINT } from './endpoints'
import type { GetSearchCommandRequestNotifiedProps, GetSearchCommandRequestNotifiedResponse } from './types'
import { lboxHttpClient } from '../../utils/api'

export const getSearchCommandRequestNotified = async ({
  username,
  email,
  title,
  description,
}: GetSearchCommandRequestNotifiedProps) => {
  const { data } = await lboxHttpClient.get<GetSearchCommandRequestNotifiedResponse>(
    ROUTE_HANDLER_API_ENDPOINT.searchCommandRequestNotified,
    {
      baseURL: '/v2',
      params: new URLSearchParams({
        username,
        email,
        title,
        description,
      }),
    }
  )

  return data
}
