/**
 * 사건번호 추출용 Regex
 *
 * @example
 * "2023가나1234567"
 * "2019가단32314"
 */
export const getCaseNoRegex = () => {
  return /(((?:20|42|43|49)\d{2}|([6-9]\d))(?!조의)[가-힣]{1,3}\d{1,7})(-\d{1,2})?/
}

/**
 * 문자열에 포함된 정규식 특수문자들을 전부 escape 처리
 * @param str
 * @returns
 */
export const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
