import { useEffect, useState } from 'react'

import { IconCheckCircle, IconWarning } from '@lbox/shared/components'

import { Tooltip } from '@lbox-kr/libra/v2'
import cn from 'classnames'

interface CaseUploadIndicatorProps {
  status: 'uploading' | 'success' | 'error'
  tooltipText?: string
}

export const CaseUploadIndicator = ({ status, tooltipText }: CaseUploadIndicatorProps) => {
  if (status === 'error') {
    const tooltipProps = tooltipText
      ? ({ disableHoverListener: false, isOpen: null } as const)
      : ({ disableHoverListener: true, isOpen: false } as const)

    return (
      <Tooltip
        {...tooltipProps}
        triggerWrapperClassName={cn('flex items-center gap-x-[8px]')}
        description={tooltipText}
        color="white"
        nonTip
        isPortalTooltip
      >
        <span className={cn('mr-[8px] text-lds2-body3-regular-underline text-rose-700 underline')}>업로드 실패</span>
        <IconWarning size={20} weight="fill" className={cn('text-rose-600')} />
      </Tooltip>
    )
  }

  if (status === 'success') {
    return <IconCheckCircle size={24} weight="fill" className={cn('text-green-600')} />
  }

  return <ProgressCircle />
}

const ProgressCircle = () => {
  const [factor, setFactor] = useState(0)

  useEffect(() => {
    let request: number

    const update = () => {
      request = requestAnimationFrame(() => {
        setFactor((factor) => factor + 0.05)
        update()
      })
    }

    update()

    return () => {
      cancelAnimationFrame(request)
    }
  }, [])

  return (
    <div className={cn('relative flex h-[24px] w-[24px] items-center justify-center')}>
      <div
        className={cn('relative h-[20px] w-[20px] rounded-[50%]')}
        style={{
          background: `conic-gradient(#4F46E5 calc(atan(${factor}) * 4), #D9D9D9 0)`,
        }}
      >
        <div className={cn('absolute left-[3px] top-[3px] h-[14px] w-[14px] rounded-[50%] bg-white')} />
      </div>
    </div>
  )
}
