import cn from 'classnames'

import { FAST_SEARCH_COMMAND_GUIDE } from '../$constants/fastSearchCommandGuide'
import type { FastSearchCommandsProps } from '../$types/searchDataList'
import { TagFastSearchCommand } from '../../tag-fast-search-command/TagFastSearchCommand'

const FastSearchCommands = (props: FastSearchCommandsProps) => {
  const {
    filteredFastSearchCommands,
    showsFastSearchCommandGuide,
    typingFastSearchCommand,
    handleClickFastSearchCommandTag,
  } = props

  return (
    <div>
      <div className={cn('flex items-center justify-between', 'pb-[16px]')}>
        <span className={cn('text-lds2-body3-medium text-zinc-500')}>빠른 검색 명령어</span>
      </div>

      {filteredFastSearchCommands && (
        <div className="flex flex-wrap items-center gap-[8px]">
          {filteredFastSearchCommands.map((fastSearchCommand) => (
            <TagFastSearchCommand
              key={fastSearchCommand}
              label={fastSearchCommand}
              onClick={() => handleClickFastSearchCommandTag(fastSearchCommand)}
            />
          ))}
        </div>
      )}

      {showsFastSearchCommandGuide && typingFastSearchCommand !== null && (
        <div className="flex items-center justify-between overflow-x-scroll text-body4 no-scrollbar">
          <span className="mr-[16px] whitespace-nowrap">
            {FAST_SEARCH_COMMAND_GUIDE[typingFastSearchCommand]?.leftText}
          </span>
          <div className="flex flex-wrap items-center justify-end gap-x-[16px] gap-y-[4px]">
            <div className="rounded-[4px] bg-zinc-50 p-[6px]">
              {FAST_SEARCH_COMMAND_GUIDE[typingFastSearchCommand]?.rightTagText}
            </div>
            <span className="whitespace-nowrap text-caption2 text-zinc-400">
              {FAST_SEARCH_COMMAND_GUIDE[typingFastSearchCommand]?.rightExampleText}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default FastSearchCommands
