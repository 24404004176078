import { LFIND_API_ENDPOINT } from '../../constants/api/endpoint'
import { lfindHttpClient } from '../../utils/api'

export interface GetLawyerProfileResponse {
  privacyAgreement: boolean
  onboarding: {
    step: 'INTRODUCTION' | 'CASE_REGISTER' | 'SPECIALIZED' | 'DONE'
  }
  profile: {
    lawyerId: number
    imageUri: string | null
    introduction: string | null
    introductionDetail: string | null
    contact: {
      phone: string
      phonePublic: boolean
      additionalPhones: { phone: string; public: boolean }[]
      email: string
      emailPublic: boolean
      additionalEmails: { email: string; public: boolean }[]
      company: string | null
      companyAddress: string | null
      companyAddressDetail: string | null
      companyAddressManual: boolean
      companyContactNumber: string | null
      companyContactNumberPublic: boolean
    }
    qualificationType: string
    turn: string
    consulting: {
      startHour: number
      startMinute: number
      endHour: number
      endMinute: number
    }
    specialities: []
  }
  lawyerMyCaseInfos: {
    title: string
    docId: string
    court: string
    caseName: string
  }[]
}

export interface GetLawyerProfileParams {
  lawyerId: number
}

export const getLawyerProfile = async ({ lawyerId }: GetLawyerProfileParams) => {
  const { data } = await lfindHttpClient.get<GetLawyerProfileResponse>(LFIND_API_ENDPOINT.lawyer.profile.byId(lawyerId))
  return data
}
