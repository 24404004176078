import { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'

import { IconCaretUp } from '@lbox/shared/components'
import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'

import type { ModalProps } from '@lbox-kr/libra/v2'
import { Button, Checkbox, Hyperlink, InputField, Modal } from '@lbox-kr/libra/v2'
import { useToggleState } from '@toss/react'
import cn from 'classnames'

import { usePostVerifyLawyer } from './$hooks/usePostVerifyLawyer'
import type { Inputs } from './types'

interface LawyerVerifyingModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  onVerifyLawyerSuccess: VoidFunction
  onFireUserEvent: (eventType: string, eventProperties?: Record<string, unknown>) => void
}

const getChannel = () => {
  const { pathname } = window.location

  if (pathname.startsWith(`/${V2_PREFIX}${LBOX_PAGE_PATH.register.complete.index}`)) {
    return '회원 가입 페이지'
  }

  if (pathname.includes(LBOX_PAGE_PATH.legalAnalytics.caseAnalytics.index)) {
    return '사건분석'
  }

  if (pathname.includes(LBOX_PAGE_PATH.legalAnalytics.judgeAnalytics.index)) {
    return '판사분석'
  }

  return '내 판례등록'
}

export default function LawyerVerifyingModal({
  isOpen,
  onVerifyLawyerSuccess,
  onClose,
  onFireUserEvent,
}: LawyerVerifyingModalProps) {
  const { mutateAsync: verifyLawyer, isPending } = usePostVerifyLawyer()

  const {
    control,
    formState: { errors, isValid, isDirty },
    watch,
    setError,
  } = useForm<Inputs>({ mode: 'onChange' })

  const registerNo = watch('registerNo')
  const licenseNo = watch('licenseNo')

  const { field: registerNoField } = useController({
    control,
    name: 'registerNo',
    rules: {
      required: {
        value: true,
        message: '신분증 등록번호를 입력해 주세요.',
      },
    },
  })

  const { field: licenseNoField } = useController({
    control,
    name: 'licenseNo',
    rules: {
      required: {
        value: true,
        message: '신분증 발급번호를 입력해 주세요.',
      },
    },
  })

  // 개인정보 처리방침 동의여부
  const [isTermAgreed, toggleTermAgreed] = useToggleState()
  // 개인정보 처리방침 상세 내용 아코디언 표시 여부
  const [showsTermDetails, toggleShowsTermDetails] = useToggleState()

  const disabled = !isDirty || !isValid || !isTermAgreed

  function filterNumbers(inputValue: string) {
    return inputValue.replace(/[^0-9]/g, '')
  }

  function handleClickFAQButton() {
    onFireUserEvent('click_lawyerauth_faq', { channel: getChannel() })
    window.open('https://support.lbox.kr/hc/ko/articles/7528239282191', '_blank')
  }

  async function handleClickConfirmButton() {
    if (disabled) {
      return
    }

    onFireUserEvent('click_lawyerauth_cta', { channel: getChannel() })

    try {
      await verifyLawyer({
        licenseInfo: {
          registrationNumber: registerNo,
          issueNumber: licenseNo,
        },
      })

      onFireUserEvent('complete_lawyerauth', { channel: getChannel() })
      onVerifyLawyerSuccess()
    } catch {
      setError('registerNo', {
        type: 'validate',
      })
      setError('licenseNo', {
        type: 'validate',
        message: '입력한 정보와 일치하는 신분증 정보가 없습니다.',
      })
    }
  }

  useEffect(() => {
    if (isOpen) {
      onFireUserEvent('view_lawyerauth', { channel: getChannel() })
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} maxHeightFull onClose={onClose}>
      <Modal.Headline>대한변호사협회 신분증 확인</Modal.Headline>
      <Modal.Body>
        <div className={cn('flex flex-col gap-y-[20px]', 'overflow-hidden')}>
          <div className={cn('text-lds2-body2-regular text-text-primary')}>
            대한변호사협회 신분증 정보를 입력해주세요.
          </div>

          <div className={cn('flex flex-col gap-y-[16px]')}>
            <InputField.Text
              label="대한변협 신분증 등록번호"
              placeholder="등록번호를 입력해주세요"
              value={registerNoField.value}
              isInvalid={Boolean(errors.registerNo)}
              helperText={errors.registerNo?.message}
              onChange={(event) => {
                const { value } = event.target
                const onlyNumber = filterNumbers(value)
                registerNoField.onChange(onlyNumber)
              }}
            />

            <InputField.Text
              label="대한변협 신분증 발급번호"
              placeholder="발급번호를 입력해주세요"
              value={licenseNoField.value}
              isInvalid={Boolean(errors.licenseNo)}
              helperText={errors.licenseNo?.message}
              onChange={(event) => {
                const { value } = event.target
                const onlyNumber = filterNumbers(value)
                licenseNoField.onChange(onlyNumber)
              }}
            />
          </div>

          <Hyperlink level="low" onClick={handleClickFAQButton}>
            대한변호사협회 신분증 확인에 어려움이 있으신가요?
          </Hyperlink>

          <div
            className={cn('flex flex-col', {
              'gap-y-[12px]': showsTermDetails,
            })}
          >
            <div className="flex items-center justify-between">
              <Checkbox
                label="개인정보 수집 및 이용에 동의합니다."
                checked={isTermAgreed}
                onChange={toggleTermAgreed}
              />
              <button
                type="button"
                onClick={toggleShowsTermDetails}
                className={cn({ 'rotate-180': !showsTermDetails })}
              >
                <IconCaretUp size={20} />
              </button>
            </div>

            <div
              className={cn('max-h-0 overflow-hidden opacity-0 transition-all duration-300', {
                'max-h-[300px] opacity-100': showsTermDetails,
              })}
            >
              <h5 className={cn('text-lds2-caption1-medium text-text-primary')}>개인정보 수집 및 이용 내역</h5>

              <div className={cn('mb-[20px] mt-[8px]', 'rounded-[4px] border-[0.5px] border-zinc-300')}>
                <table className={cn('break-all')}>
                  <thead>
                    <tr
                      className={cn(
                        'flex bg-background-secondary',
                        'text-lds2-caption1-medium text-text-primary',
                        'rounded-t-[4px]'
                      )}
                    >
                      <th className={cn('basis-1/3', 'p-[10px]')}>항목</th>
                      <th className={cn('basis-1/3', 'p-[10px]', 'border-l-[0.5px] border-r-[0.5px] border-zinc-300')}>
                        처리 목적
                      </th>
                      <th className={cn('basis-1/3', 'p-[10px]')}>보유 기간</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr className={cn('flex', 'text-lds2-body3-regular text-zinc-700')}>
                      <td className={cn('flex flex-col gap-y-[8px]', 'basis-1/3', 'p-[10px]')}>
                        대한변협 신분증 등록번호, 대한변협 신분증 발급번호
                      </td>
                      <td className={cn('basis-1/3', 'p-[10px]', 'border-l-[0.5px] border-r-[0.5px] border-zinc-300')}>
                        변호사 전용 서비스 제공
                      </td>
                      <td className={cn('basis-1/3', 'p-[10px]', 'text-lds2-body3-semibold')}>
                        서비스 이용 만료시 또는 개인정보 수집 이용 목적 달성시까지
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <p className={cn('text-lds2-caption1-regular text-text-secondary')}>
                귀하는 위 개인정보 수집∙이용 등에 대한 동의를 거부하실 수 있으며, 동의하지 않을 경우 서비스 이용에
                제한이 있을 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button width="full" label="취소" buttonType="tonal" onClick={onClose} />
        <Button
          width="full"
          label="인증하기"
          buttonType="filled"
          disabled={disabled}
          isLoading={isPending}
          onClick={handleClickConfirmButton}
        />
      </Modal.Footer>
    </Modal>
  )
}
