import type { IconProps } from './interfaces'

export const IconCloseSmall = ({ size = 24 }: IconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.18484 7.18484C7.43128 6.93839 7.83085 6.93839 8.0773 7.18484L12 11.1075L15.9227 7.18484C16.1691 6.93839 16.5687 6.93839 16.8152 7.18484C17.0616 7.43128 17.0616 7.83085 16.8152 8.0773L12.8925 12L16.8152 15.9227C17.0616 16.1691 17.0616 16.5687 16.8152 16.8152C16.5687 17.0616 16.1691 17.0616 15.9227 16.8152L12 12.8925L8.0773 16.8152C7.83085 17.0616 7.43128 17.0616 7.18484 16.8152C6.93839 16.5687 6.93839 16.1691 7.18484 15.9227L11.1075 12L7.18484 8.0773C6.93839 7.83085 6.93839 7.43128 7.18484 7.18484Z"
      fill="currentColor"
    />
  </svg>
)
