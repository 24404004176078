import { useState } from 'react'
import Lottie from 'react-lottie'

import { IconCheck } from '@lbox/shared/components'

import { Spacing } from '@lbox-kr/libra/v2'
import { useTimeout } from '@toss/react'
import cn from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import ChallengeDrawerTemplate from './ChallengeDrawerTemplate'
import ChallengeGradientText from './ChallengeGradientText'
import confettiLottie from './confetti.lottie.json'
import StatusChallengeContent from './StatusChallengeContent'
import StepWrapper from './StepWrapper'

export default function WelcomeChallengeDrawer({
  onClose,
  isOpen,
  onCloseComplete,
}: {
  onClose?: VoidFunction
  isOpen: boolean
  onCloseComplete?: VoidFunction
}) {
  const [step, setStep] = useState(0)
  return (
    <ChallengeDrawerTemplate onClose={onClose} isOpen={isOpen} onCloseComplete={onCloseComplete}>
      <AnimatePresence>
        {
          [
            <Step1 onComplete={() => setStep(1)} key="step1" />,
            <Step2 onComplete={() => setStep(2)} key="step2" />,
            <Step3 onComplete={() => setStep(3)} key="step3" />,
            <StepWrapper key="status">
              <StatusChallengeContent onClose={onClose} />
            </StepWrapper>,
          ][step]
        }
      </AnimatePresence>
    </ChallengeDrawerTemplate>
  )
}

function Step1({ onComplete }: { onComplete: VoidFunction }) {
  return (
    <StepWrapper skipEnter className="py-12 text-center">
      <motion.div
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 360 * 4 }}
        transition={{ duration: 1.5, ease: 'easeOut' }}
        className="mx-auto w-fit"
      >
        <CoinSvg />
      </motion.div>
      <Spacing size={28} />
      <p className="text-lds2-heading4-semibold text-text-primary">
        변호사 고객님의 효율적 업무를 위해 <br />
        <ChallengeGradientText>20,000포인트</ChallengeGradientText>가 지급되었습니다
      </p>
      <Lottie
        options={{ animationData: confettiLottie, autoplay: true, loop: false }}
        eventListeners={[{ eventName: 'complete', callback: onComplete }]}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          width: '100%',
          height: 'auto',
          top: 0,
          left: 0,
          aspectRatio: '1/1',
        }}
      />
    </StepWrapper>
  )
}

function Step2({ onComplete }: { onComplete: VoidFunction }) {
  useTimeout(onComplete, 5000)
  return (
    <StepWrapper className="p-6">
      <p className="text-lds2-heading4-semibold text-text-primary">법률 전문가를 위한 AI 리서치를 시작해 보세요.</p>
      <Spacing size={16} />
      <IconList
        items={[
          {
            icon: <IconCheck className="text-foreground-brand" size={24} />,
            text: '복잡한 판례 분석이 필요할 때',
          },
          {
            icon: <IconCheck className="text-foreground-brand" size={24} />,
            text: '여러 사실 관계가 얽힌 경우에 대한 리서치가 필요할 때',
          },
          {
            icon: <IconCheck className="text-foreground-brand" size={24} />,
            text: '광범위한 판례 케이스 수집이 필요할 때',
          },
        ]}
      />
    </StepWrapper>
  )
}

function Step3({ onComplete }: { onComplete: VoidFunction }) {
  useTimeout(onComplete, 5000)
  return (
    <StepWrapper className="p-6">
      <p className="text-lds2-heading4-semibold text-text-primary">
        매일 활용하지 않으셔도 괜찮습니다.
        <br />
        실무에 도움이 필요한 날에 LBOX AI를 활용해 보세요.
      </p>
      <Spacing size={16} />
      <IconList
        items={[
          {
            icon: <CoinSvg size={24} />,
            text: (
              <>
                활용하시는 날마다 <ChallengeGradientText>1000포인트</ChallengeGradientText>를 드립니다
              </>
            ),
          },
          {
            icon: <CoinSvg size={24} />,
            text: (
              <>
                한달에 15회 질문 남기면 <ChallengeGradientText>10,000포인트</ChallengeGradientText>를 더 드려요
              </>
            ),
          },
        ]}
      />
    </StepWrapper>
  )
}

function CoinSvg({ className, size = 64 }: { className?: string; size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className={className}
      viewBox="0 0 64 64"
    >
      <circle cx="32" cy="32" r="31.75" fill="#F6B514" stroke="#fff" strokeWidth=".5" />
      <circle cx="32" cy="32" r="30.4" fill="#F7D95F" />
      <g filter="url(#a)">
        <circle cx="32" cy="32" r="25.6" fill="#F7B614" />
      </g>
      <g filter="url(#b)">
        <path
          fill="#F7D95F"
          d="M23.2 46.245V20h10.295c5.981 0 9.389 3.661 9.389 8.845 0 5.256-3.48 8.845-9.534 8.845h-4.713v8.555H23.2Zm5.438-12.941h3.842c3.226 0 4.785-1.813 4.785-4.459 0-2.61-1.559-4.386-4.785-4.386h-3.842v8.845Z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="51.2"
          height="51.2"
          x="6.4"
          y="6.4"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="16" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.717952 0 0 0 0 0.464987 0 0 0 0 0.0580415 0 0 0 0.4 0" />
          <feBlend in2="shape" result="effect1_innerShadow_5863_109944" />
        </filter>
        <filter
          id="b"
          width="39.684"
          height="46.245"
          x="13.2"
          y="10"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0.91193 0 0 0 0 0.683948 0 0 0 0 0 0 0 0 1 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_5863_109944" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_5863_109944" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

function IconList({
  className,
  items,
}: {
  className?: string
  items: { icon: React.ReactNode; text: React.ReactNode }[]
}) {
  return (
    <ul className={cn('flex flex-col gap-y-2', className)}>
      {items.map((item, index) => (
        <li
          key={index}
          className="flex items-center gap-x-2 rounded-lg border border-background-primary bg-background-primary/65 p-3 text-lds2-body1-semibold text-text-primary"
        >
          {item.icon}
          <p>{item.text}</p>
        </li>
      ))}
    </ul>
  )
}
