import type { SearchedJudgeItem } from '../../$types/histories'

export const getBelongedCourtsDetail = (searchedJudgeItem: SearchedJudgeItem) => {
  const { retired, court, bar_type: 연수원기수 } = searchedJudgeItem

  const PREFIX_FOR_PREVIOUS_JOB = '전) '
  const 법원명 = (retired ? PREFIX_FOR_PREVIOUS_JOB : '') + court

  if (연수원기수) {
    return `${법원명} · ${연수원기수}`
  } else {
    return 법원명
  }
}
