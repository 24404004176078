import { CSROnly } from '@lbox/shared/components'
import { useMediaQuery } from '@lbox/shared/hooks'

import type { PopoverProps } from '@lbox-kr/libra/v1'
import { Drawer, Popover } from '@lbox-kr/libra/v1'

import MenuContent from './menu-content/MenuContent'
import type { OnFireClientTrackingEvent, OnFireUserEvent } from '../../../../types/event'

interface MenuDrawerAndPopoverProps extends PopoverProps {
  onFireUserEvent: OnFireUserEvent
  onFireClientTrackingEvent?: OnFireClientTrackingEvent
}

/**
 * 프로필 버튼 클릭 시 나타나는 드로어(모바일) 겸 팝오버(태블릿/데스크탑)
 * - 비로그인 상태인 경우에는 모바일에서만 표시
 * - 로그인 상태인 경우에는 항상 표시
 * - 변호사 여부에 따라 항목이 달라짐
 */
const MenuDrawerAndPopover = ({
  isOpen,
  handleClickBackdrop = () => undefined,
  top,
  left,
  right,
  onFireUserEvent,
  onFireClientTrackingEvent,
}: MenuDrawerAndPopoverProps) => {
  const { isMobile, isTabletOrDesktop } = useMediaQuery()

  return (
    <CSROnly>
      {isMobile && (
        <Drawer isOpen={isOpen} onClickTopRightCloseButton={handleClickBackdrop} onClickBackdrop={handleClickBackdrop}>
          <MenuContent onFireUserEvent={onFireUserEvent} />
        </Drawer>
      )}

      {isTabletOrDesktop && (
        <Popover isOpen={isOpen} top={top} left={left} right={right} handleClickBackdrop={handleClickBackdrop}>
          <MenuContent onFireUserEvent={onFireUserEvent} onFireClientTrackingEvent={onFireClientTrackingEvent} />
        </Popover>
      )}
    </CSROnly>
  )
}

export default MenuDrawerAndPopover
