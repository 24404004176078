import type { ChangeEvent } from 'react'

import { generateUUID } from '@lbox/shared/utils'

import type { FetchAwsUploadSignatureApiResponseData } from '../$apis/fetchAwsUploadSignature'
import { useFetchAwsUploadSignature } from '../$hooks/useFetchAwsUploadSignature'
import { useUploadMyCaseToAwsS3 } from '../$hooks/useUploadMyCaseToAwsS3'

export interface FileUploadResultItem {
  id: string
  file: File
  awsUploadSignatureInfo: FetchAwsUploadSignatureApiResponseData
  isFileTypeError: boolean
  isAlreadyUploadedError: boolean
  isUploadFailedError: boolean
  isUploadStarted: boolean
  isUploadEnded: boolean
  existsAnyError: boolean
}

export type ErrorCallback = (message: string, context: Record<string, unknown>, error: Error) => void

export interface UseMyCaseUploadParams {
  onUploadStart?: (fileUploadResultItems: FileUploadResultItem[]) => void
  onUploadEnd?: (fileUploadResultItems: FileUploadResultItem[]) => void
  onError?: ErrorCallback
}

export const useMyCaseUpload = ({ onUploadStart, onUploadEnd, onError }: UseMyCaseUploadParams) => {
  const { mutateAsync: fetchAwsUploadSignature } = useFetchAwsUploadSignature()
  const { mutateAsync: uploadMyCaseToAwsS3 } = useUploadMyCaseToAwsS3()

  function handleChangeFiles($event: ChangeEvent<HTMLInputElement>) {
    const fileList = $event.target.files

    if (!fileList || fileList.length === 0) {
      return
    }

    uploadFiles(Array.from(fileList))
  }

  function handleDropFiles(acceptedFiles: File[]) {
    if (acceptedFiles.length === 0) {
      return
    }

    uploadFiles(acceptedFiles)
  }

  function uploadFiles(files: File[]) {
    const pdfFiles = files.filter(({ type }) => type === 'application/pdf')

    executeUploadProcess(pdfFiles.map(createFileUploadResultItem))
  }

  function fetchAwsUploadSignatures(
    fileUploadIntermediateResultItems: FileUploadResultItem[]
  ): Promise<FileUploadResultItem[]> {
    return Promise.all(
      fileUploadIntermediateResultItems.map((intermediateResultItem) => {
        if (intermediateResultItem.existsAnyError) {
          return intermediateResultItem
        }

        return fetchAwsUploadSignature({
          name: intermediateResultItem.file.name,
          size: intermediateResultItem.file.size,
        })
          .then((awsUploadSignature) => {
            if (!awsUploadSignature) {
              throw Error()
            }

            return { ...intermediateResultItem, awsUploadSignatureInfo: awsUploadSignature }
          })
          .catch((error) => {
            onError?.('내 판결문 등록 - AWS S3 시그니처 획득 실패', { intermediateResultItem }, error)

            return {
              ...intermediateResultItem,
              isUploadFailedError: true,
              isUploadEnded: true,
              existsAnyError: true,
            }
          })
      })
    )
  }

  function uploadToAwsS3(fileUploadIntermediateResultItems: FileUploadResultItem[]): Promise<FileUploadResultItem[]> {
    return Promise.all(
      fileUploadIntermediateResultItems.map((intermediateResultItem) => {
        if (intermediateResultItem.existsAnyError) {
          return intermediateResultItem
        }

        return uploadMyCaseToAwsS3({
          awsSignatureInfo: intermediateResultItem.awsUploadSignatureInfo,
          file: intermediateResultItem.file,
        })
          .then((isFailed) => {
            if (isFailed) {
              throw Error()
            }

            return { ...intermediateResultItem, isUploadEnded: true }
          })
          .catch((error) => {
            onError?.('내 판결문 등록 - AWS S3 업로드 실패', { intermediateResultItem }, error)

            return {
              ...intermediateResultItem,
              isUploadFailedError: true,
              isUploadEnded: true,
              existsAnyError: true,
            }
          })
      })
    )
  }

  async function executeUploadProcess(initialFileUploadResultItems: FileUploadResultItem[]) {
    if (typeof onUploadStart === 'function') {
      onUploadStart(initialFileUploadResultItems)
    }

    // AWS S3 에 업로드하기 위한 signature 정보 얻어오기
    const awsUploadSignatureResponses = await fetchAwsUploadSignatures(initialFileUploadResultItems)
    // AWS S3 에 업로드하기
    const awsUploadResultResponses = await uploadToAwsS3(awsUploadSignatureResponses)

    if (typeof onUploadEnd === 'function') {
      onUploadEnd(awsUploadResultResponses)
    }
  }

  return { handleChangeFiles, handleDropFiles }
}

function createFileUploadResultItem(file: File) {
  return {
    id: generateUUID(),
    file,
    awsUploadSignatureInfo: {} as FileUploadResultItem['awsUploadSignatureInfo'],
    isFileTypeError: false,
    isAlreadyUploadedError: false,
    isUploadFailedError: false,
    isUploadStarted: true,
    isUploadEnded: false,
    existsAnyError: false,
  }
}
