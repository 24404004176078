import type { PropsWithChildren, ReactNode } from 'react'

import { V2_PREFIX } from '@lbox/shared/constants'

import cn from 'classnames'

import type {
  OnFireClientTrackingEvent,
  OnFireClientTrackingEventParams,
  OnFireUserEvent,
} from '../../../../../../types/event'
import { getAuthority } from '../../../../../../utils/getAuthority'
import { loginLog, registerLog } from '../../../../shared/guest-buttons/utils'

interface MenuItemProps {
  type: string
  label: string
  labelComponent?: ReactNode
  hasThickBottomBorder?: boolean
  targetPathV1?: string
  targetPathV2?: string
  targetURL?: string
  onClick?: VoidFunction
  onFireUserEvent: OnFireUserEvent
  onFireClientTrackingEvent?: OnFireClientTrackingEvent
}

const MenuItem = ({
  hasThickBottomBorder,
  targetPathV1,
  targetPathV2,
  targetURL,
  onClick,
  onFireUserEvent,
  onFireClientTrackingEvent,
  type,
  label,
  labelComponent,
  children,
}: PropsWithChildren<MenuItemProps>) => {
  const onFireClientTrackingEventParams: OnFireClientTrackingEventParams = {
    type: 'click_gnb_mypage_list',
    value: label,
  }

  async function handleClick() {
    onFireUserEvent('click_gnb_mypage_list', {
      type,
    })

    if (type === '로그인') {
      loginLog(onFireUserEvent).clickLogin()
    }

    if (type === '회원가입') {
      registerLog(onFireUserEvent).clickSignup()
    }

    const url = targetURL
      ? targetURL
      : targetPathV1
        ? `${window.location.origin}${targetPathV1}`
        : targetPathV2
          ? `${window.location.origin}/${V2_PREFIX}${targetPathV1}`
          : null

    if (url !== null) {
      onFireClientTrackingEventParams.url = url
    }

    await onFireClientTrackingEvent?.(onFireClientTrackingEventParams)

    if (targetPathV1) {
      window.location.assign(getAuthority() + targetPathV1)
      return
    }

    if (targetPathV2) {
      window.location.assign(`/${V2_PREFIX}${targetPathV2}`)
    }

    if (targetURL) {
      window.open(targetURL, '_blank')
      return
    }

    onClick?.()
  }

  return (
    <div
      className={cn(
        'box-content',
        'flex items-center justify-between',
        'h-[48px] px-[16px]',
        'text-lds2-body3-regular text-zinc-600 hover:bg-zinc-50',
        'cursor-pointer',
        {
          'border-b border-solid border-zinc-50': !hasThickBottomBorder,
          'border-b-[8px] border-solid border-zinc-50': hasThickBottomBorder,
        }
      )}
      role="button"
      onClick={handleClick}
    >
      <div className={cn('flex items-center')}>
        {label}
        {labelComponent}
      </div>
      {children}
    </div>
  )
}

export default MenuItem
