import { getNonProfitOrganizationInfoFromSession } from './getNonProfitOrganizationInfoFromSession'
import { isPublicChannel } from './isPublicChannel'

/**
 * 비영리 유저 + 업로드 기능 제한된 유저를 판단하기 위한 함수
 *
 * ex) 법무부
 */
export function isRestrictedUser() {
  const nonProfitOrganizationInfo = getNonProfitOrganizationInfoFromSession()
  return isPublicChannel() && nonProfitOrganizationInfo?.securityLevel === 'UPLOAD_AND_INPUT_DISABLE'
}
