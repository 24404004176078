// 서울고등법원-2016누32314 to 2016누32314
// 서울남부지방법원-2011고단2145-1 to 2011고단2145-1
export const extractCaseNum = (caseId: string) => {
  return caseId.split('-').slice(1).join('-')
}

// 서울고등법원-2016누32314 to 서울고등법원
// 서울남부지방법원-2011고단2145-1 to 서울남부지방법원
export const extractCourt = (caseId: string) => {
  return caseId.split('-')[0]
}

export const extractCaseNoAndCourt = (caseId: string) => {
  return {
    caseno: extractCaseNum(caseId),
    court: extractCourt(caseId),
  }
}
