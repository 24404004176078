import { useCallback, useEffect, useMemo, useState } from 'react'

import { generateUUID } from '@lbox/shared/utils'

import { usePrevious } from '@toss/react'

import type { ErrorCallback, FileUploadResultItem } from '../$hooks/useMyCaseUpload'
import { useMyCaseUpload } from '../$hooks/useMyCaseUpload'

export type UploadedFileStatus = 'uploading' | 'success' | 'error'
export interface UploadedFile {
  id: string
  filePath: string
  fileName: string
  status: UploadedFileStatus
  isFileTypeError?: boolean
  isAlreadyUploadedError?: boolean
}

interface UseUploadFilesParams {
  initialFiles?: UploadedFile[]
  onError?: ErrorCallback
}

/**
 * @description
 * 판결문 업로드 과정 로직을 관리하는 훅.
 * 화면에 보여지는 view model의 상태를 관리하며, 파일 업로드/등록/실패 등의 로직을 처리하고 해당 메서드를 외부로 노출한다.
 * API 호출은 useMyCaseUpload 훅에서 담당한다.
 */
export function useUploadFiles({ initialFiles, onError }: UseUploadFilesParams = {}) {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  const previousUploadedFiles = usePrevious(uploadedFiles)

  const { handleDropFiles: upload } = useMyCaseUpload({
    onUploadStart: (uploadStartedFiles) => {
      setUploadedFiles((uploadedFiles) => [...uploadStartedFiles.map(createUploadedFile), ...uploadedFiles])
    },
    onUploadEnd: (uploadEndedFiles) => {
      setUploadedFiles((uploadedFiles) => [
        ...uploadEndedFiles.map(createUploadedFile),
        ...uploadedFiles.filter(({ id }) => uploadEndedFiles.every((resultItem) => resultItem.id !== id)),
      ])
    },
    onError,
  })

  useEffect(() => {
    if (initialFiles) {
      setUploadedFiles(initialFiles)
    }
  }, [initialFiles])

  const sortedUploadedFiles: UploadedFile[] = useMemo(() => uploadedFiles.sort(compareUploadedFiles), [uploadedFiles])

  const undo = useCallback(() => {
    setUploadedFiles(previousUploadedFiles)
  }, [previousUploadedFiles])

  const clear = useCallback(() => {
    setUploadedFiles([])
  }, [])

  const handleAcceptFiles = useCallback(
    (files: File[]) => {
      const duplicateFiles = files.filter((file) => uploadedFiles.some(({ fileName }) => fileName === file.name))
      const newFiles = files.filter((file) => uploadedFiles.every(({ fileName }) => fileName !== file.name))

      setUploadedFiles((uploadedFiles) => [
        ...duplicateFiles.map((file) => ({
          id: generateUUID(),
          fileName: file.name,
          filePath: '',
          status: 'error' as UploadedFileStatus,
          isAlreadyUploadedError: true,
        })),
        ...uploadedFiles,
      ])

      upload(newFiles)
    },
    [uploadedFiles, upload]
  )

  const handleRejectFiles = useCallback((files: File[]) => {
    setUploadedFiles((uploadedFiles) => [
      ...files.map((file) => ({
        id: generateUUID(),
        fileName: file.name,
        filePath: '',
        status: 'error' as UploadedFileStatus,
        isFileTypeError: file.type !== 'application/pdf',
      })),
      ...uploadedFiles,
    ])
  }, [])

  const handleRemoveFile = useCallback((id: string) => {
    setUploadedFiles((uploadedFiles) => uploadedFiles.filter((file) => file.id !== id))
  }, [])

  return {
    uploadedFiles: sortedUploadedFiles,
    handleAcceptFiles,
    handleRejectFiles,
    handleRemoveFile,
    undo,
    clear,
  }
}

const createUploadedFile = ({
  id,
  awsUploadSignatureInfo,
  file,
  isUploadEnded,
  existsAnyError,
  isAlreadyUploadedError,
}: FileUploadResultItem): UploadedFile => {
  return {
    id,
    fileName: file.name,
    filePath: awsUploadSignatureInfo?.key || '',
    status: (existsAnyError ? 'error' : isUploadEnded ? 'success' : 'uploading') as UploadedFileStatus,
    isAlreadyUploadedError,
  }
}

/**
 * 업로드 실패한 파일, 업로드 중인 파일, 업로드 성공한 파일 순으로 정렬
 * 상태가 같다면 순서를 유지한다.
 */
const compareUploadedFiles = (a: UploadedFile, b: UploadedFile) => {
  if (a.status === b.status) {
    return 0
  }

  if (a.status === 'error') {
    return -1
  }

  if (b.status === 'error') {
    return 1
  }

  if (a.status === 'uploading') {
    return -1
  }

  if (b.status === 'uploading') {
    return 1
  }

  return 0
}
