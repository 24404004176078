import type { Ref } from 'react'
import { forwardRef } from 'react'

import { useDebouncedValue, useMediaQuery } from '@lbox/shared/hooks'

import { CustomScrollBar } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import useSearchDataList from '../$hooks/useSearchDataList'
import type { SearchBottomSheetDataListProps } from '../$types/searchDataList'
import { useSearchBar } from '../../$hooks/useSearchBar'
import { SearchCommandGuidePopover } from '../../search-command-guide-popover/SearchCommandGuidePopover'
import FastSearchCommands from '../fast-search-commands/FastSearchCommands'
import RecentHistories from '../recent-histories/RecentHistories'
import Suggestions from '../suggestions/Suggestions'

/**
 * 검색어 입력에 따라 다양한 보조 정보를 표시하는 데이터 목록
 * - 빠른 검색 명령어, 최근 검색한 항목, 추천 검색어, 판사 순으로 표시
 * - 모바일에서는 바텀시트에 검색바와 함꼐 위치
 * - 태블릿/데스크탑에서는 검색바 아래쪽에 노출되는 팝오버 안에 위치
 */
const SearchBottomSheetDataList = forwardRef(function SearchBarDataList(
  { query, cursorOffset, onSearch, onSearchJudge, onClickTag, onFireUserEvent }: SearchBottomSheetDataListProps,
  ref: Ref<HTMLDivElement>
) {
  const { isTabletOrDesktop } = useMediaQuery()

  const debouncedQuery = useDebouncedValue(query, 300)

  const { isSearchCommandGuidePopoverOpen, searchCommandGuideButtonRef, closeSearchCommandGuidePopover } = useSearchBar(
    { onFireUserEvent }
  )

  const {
    filteredFastSearchCommands,
    typingFastSearchCommand,
    searchedJudges,
    suggestions,
    recentHistories,
    availableFastSearchCommands,
    showsFastSearchCommandGuide,
    showsFastSearchCommandResult,
    showsRecentHistoryResult,
    showsSuggestionsResult,
    showsSearchedJudgesResult,
    handleClickHistory,
    handleClickHistoryCloseIcon,
    handleClickSuggestion,
    handleClickFastSearchCommandTag,
    handleClickSearchedJudgeItem,
  } = useSearchDataList({ query: debouncedQuery, cursorOffset, onSearch, onSearchJudge, onClickTag, onFireUserEvent })

  return (
    <div
      ref={ref}
      className={cn(
        'bg-white',
        'h-[calc(var(--custom-vh,1vh)*100-119px)] w-full tablet:h-auto min-[769px]:max-w-[440px] min-[1800px]:max-w-[580px]',
        'overflow-y-auto overflow-x-hidden',
        'pb-[24px] tablet:pb-[8px]',
        'rounded-[4px] tablet:border tablet:border-zinc-200'
      )}
    >
      <CustomScrollBar style={{ maxHeight: isTabletOrDesktop ? 500 : undefined }}>
        <div className={cn('flex flex-col gap-y-[32px]')}>
          {/* 빠른 검색 명령어 */}
          {showsFastSearchCommandResult && (
            <FastSearchCommands
              filteredFastSearchCommands={filteredFastSearchCommands}
              showsFastSearchCommandGuide={showsFastSearchCommandGuide}
              typingFastSearchCommand={typingFastSearchCommand}
              handleClickFastSearchCommandTag={handleClickFastSearchCommandTag}
            />
          )}

          {/* 최근 검색한 항목 */}
          {showsRecentHistoryResult && (
            <RecentHistories
              recentHistories={recentHistories}
              handleClickHistoryCloseIcon={handleClickHistoryCloseIcon}
              handleClickSearchedJudgeItem={handleClickSearchedJudgeItem}
              handleClickHistory={handleClickHistory}
            />
          )}

          {/* 추천 검색어 */}
          {!showsFastSearchCommandGuide && (showsSuggestionsResult || showsSearchedJudgesResult) && (
            <Suggestions
              searchedJudges={searchedJudges}
              suggestions={suggestions}
              availableFastSearchCommands={availableFastSearchCommands}
              showsSearchedJudgesResult={showsSearchedJudgesResult}
              showsSuggestionsResult={showsSuggestionsResult}
              handleClickSuggestion={handleClickSuggestion}
              handleClickSearchedJudgeItem={handleClickSearchedJudgeItem}
            />
          )}
        </div>
      </CustomScrollBar>

      {/* 검색 가이드 Popover */}
      {isSearchCommandGuidePopoverOpen && (
        <SearchCommandGuidePopover
          isMobile
          isOpen={isSearchCommandGuidePopoverOpen}
          renderTarget={searchCommandGuideButtonRef.current}
          closePopover={closeSearchCommandGuidePopover}
        />
      )}
    </div>
  )
})

export default SearchBottomSheetDataList
