import cn from 'classnames'
import { motion } from 'framer-motion'

const backgroundImage = `linear-gradient(90deg,${Array(2)
  .fill(['#F35BDF', '#932DFF', '#3396FF', '#932DFF'])
  .flat()
  .map((color, i, { length }) => {
    return `${color} ${i * (100 / (length - 1))}%`
  })
  .join(',')})`

export default function ChallengeGradientText({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <motion.span
      className={cn('bg-clip-text text-transparent', 'bg-[length:248%_100%] bg-[position:var(--x)_center]', className)}
      style={{ backgroundImage }}
      initial={{ '--x': '0%' }}
      animate={{ '--x': '100%' }}
      transition={{
        duration: 2,
        repeat: Infinity,
        ease: 'linear',
      }}
    >
      {children}
    </motion.span>
  )
}
