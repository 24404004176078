import { IconX } from '@lbox/shared/components'

import cn from 'classnames'

import type { UploadedFile } from './$hooks/useUploadFiles'
import { CaseList } from './CaseList'
import { CaseUploadIndicator } from './CaseUploadIndicator'

interface UploadFileListProps {
  uploadFiles: UploadedFile[]
  onRemoveFile: (id: string) => void
  className?: string
}

export const UploadFileList = ({ uploadFiles, onRemoveFile, className }: UploadFileListProps) => (
  <CaseList className={cn('max-h-[270px] overflow-hidden overflow-y-auto', className)}>
    {uploadFiles.map(({ id, fileName, status, isAlreadyUploadedError, isFileTypeError }) => {
      const errorMessage = isAlreadyUploadedError
        ? '이미 등록되었거나 등록 중인 판결문입니다.'
        : isFileTypeError
          ? '등록할 수 없는 형식의 파일입니다. PDF 파일을 업로드해주세요.'
          : '파일 업로드 중 에러가 발생했습니다.'

      return (
        <CaseList.Item
          key={id}
          caseId={fileName}
          actions={
            <span className={cn('flex items-center')}>
              <CaseUploadIndicator status={status} tooltipText={errorMessage} />
              <span className={cn('mx-[12px] inline-block h-[20px] w-[1px] bg-zinc-200')} />
              <button
                type="button"
                onClick={() => onRemoveFile(id)}
                className={cn('cursor-pointer text-black opacity-40 hover:opacity-80')}
              >
                <IconX size={20} weight="regular" />
              </button>
            </span>
          }
        />
      )
    })}
  </CaseList>
)
