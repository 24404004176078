import { IconCaretRight } from '@lbox/shared/components'

import cn from 'classnames'

import useGetCaseUploadPoint from './$hooks/useGetCaseUploadPoint'

interface CaseUploadEventBannerProps {
  onClick: VoidFunction
}

export const CaseUploadEventBanner = ({ onClick }: CaseUploadEventBannerProps) => {
  const { point } = useGetCaseUploadPoint()

  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(
        'flex w-full items-center gap-x-[10px]',
        'rounded-[4px] border border-zinc-300',
        'px-[16px] py-[12px]',
        'text-lds2-body3-regular text-zinc-700'
      )}
    >
      <IconCoin />
      <span className={cn('flex-1 text-left')}>판결문 등록 시 1건 당 {point} 포인트 적립 이벤트!</span>
      <IconCaretRight weight="regular" size={16} />
    </button>
  )
}

const IconCoin = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10.5" cy="10.5" r="9.5" fill="#DB9000" />
    <circle cx="9.5" cy="9.5" r="9.5" fill="#FFB601" />
    <circle opacity="0.4" cx="9.5" cy="9.5" r="7.5" fill="white" />
    <path
      d="M6.40039 4.60156H10.2832C11.0169 4.60156 11.6504 4.74284 12.1836 5.02539C12.7168 5.30794 13.1224 5.70215 13.4004 6.20801C13.6829 6.70931 13.8242 7.28581 13.8242 7.9375C13.8242 8.59831 13.6807 9.18164 13.3936 9.6875C13.111 10.1888 12.6986 10.5785 12.1562 10.8564C11.6185 11.1344 10.9759 11.2734 10.2285 11.2734H8.45117V14.5H6.40039V4.60156ZM9.90039 9.61914C10.4928 9.61914 10.9417 9.46875 11.2471 9.16797C11.557 8.86263 11.7096 8.45247 11.7051 7.9375C11.7096 7.43164 11.557 7.0306 11.2471 6.73438C10.9417 6.43815 10.4928 6.28776 9.90039 6.2832H8.45117V9.61914H9.90039Z"
      fill="#DB9000"
    />
  </svg>
)
