import { useMutation } from '@tanstack/react-query'

import { postUserLogout } from '../../apis/user/api'
import { API_ENDPOINT } from '../../constants/api/endpoint'
import { METHOD } from '../../constants/api/method'

export default function usePostUserLogout() {
  return useMutation({
    mutationKey: [METHOD.post, API_ENDPOINT.user.logout],
    mutationFn: postUserLogout,
  })
}
