import { getCommandStates } from './getCommandStates'
import { COMMANDS_WITH_AT } from '../$constants/commandsWithAt'
import type { CommandAll, CommandWithAt } from '../$types/command'
import { escapeRegExp } from '../../../utils/regex'

interface HighlightKeywordParams {
  searchKeyword: string
  /** 하이라이트 시 적용할 CSS 스타일 */
  styleString: string
  /** 허용하는 빠른검색명령어 목록(없으면 전체 허용) */
  availableFastSearchCommands: CommandAll[]
}

type HighlightSearchKeywordParams = Omit<HighlightKeywordParams, 'styleString'>

export function highlightSearchKeyword({ searchKeyword, availableFastSearchCommands }: HighlightSearchKeywordParams) {
  return highlightKeyword({
    searchKeyword,
    styleString: 'background: rgba(36,72,255,.08); display: inline-block; height: auto; border-radius: 2px;',
    availableFastSearchCommands,
  })
}

export function highlightSearchKeywordInPopover({
  searchKeyword,
  availableFastSearchCommands,
}: HighlightSearchKeywordParams) {
  return highlightKeyword({
    searchKeyword,
    styleString:
      'background: rgba(36,72,255,.05); display: inline-block; padding: 6px; margin-left: 2px; margin-right: 2px; height: auto; border-radius: 4px;',
    availableFastSearchCommands,
  })
}

function highlightKeyword({ searchKeyword, styleString, availableFastSearchCommands }: HighlightKeywordParams) {
  let highlighted = searchKeyword.slice()

  const commandStates = getCommandStates(searchKeyword).filter((state) =>
    availableFastSearchCommands.includes(state.commandType)
  )

  commandStates.forEach(({ highlightedString: highlight, isEditing, commandType }) => {
    const regex = new RegExp(escapeRegExp(highlight), 'g')

    let matched = null
    while ((matched = regex.exec(highlighted)) !== null) {
      // 완전일치 예외처리
      if (highlight === '"') {
        // 앞에 일부 특수문자 올 경우 continue (CSS 코드)
        // 예) 'style="', '4px;"'
        if (highlighted[matched.index - 1] === '=' || highlighted[matched.index - 1] === ';') {
          continue
        }
      }
      if (highlight.startsWith('"') && isEditing) {
        continue
      }
      // 제외어 예외처리
      if (highlight === '-') {
        // 앞에띄어쓰기가 아니면 continue (CSS 코드)
        // 예) 'inline-block', 'margin-left'
        if (highlighted[matched.index - 1] !== ' ') {
          continue
        }
      }
      // 명령어 예외처리: @ 들어간 빠른검색명령어 다음 띄어쓰기가 있을 경우에는 하이라이트하지 않는다.
      // "@사건명 "까지 입력했을 때는 하이라이트하지 않고, "@사건명 ㄱ"처럼 띄어쓰기 후 글자를 입력하면 그 때부터 하이라이트한다.
      if (COMMANDS_WITH_AT.includes(commandType as CommandWithAt) && highlight.endsWith(' ')) {
        continue
      }
      // 문구 왼쪽에 >, 오른쪽에 <가 없는 경우에만 변환(중복 하이라이트 방지)
      if (highlighted[matched.index - 1] !== '>' && highlighted[matched.index + matched[0].length] !== '<') {
        highlighted = `${highlighted.slice(
          0,
          matched.index
        )}<span style="${styleString}">${highlight}</span>${highlighted.slice(matched.index + highlight.length)}`
        return
      }
    }
  })

  return highlighted
}
