import { API_ENDPOINT } from '../../../constants/api/endpoint'
import { lboxHttpClient } from '../../../utils/api'

export interface fetchAwsUploadSignaturesParams {
  name: string
  size: number
}

export interface FetchAwsUploadSignatureApiResponseData {
  acl: string
  key: string
  'Content-Type': string
  Policy: string
  'X-Amz-Algorithm': string
  'X-Amz-Credential': string
  'X-Amz-Date': string
  'x-amz-meta-uuid': string
  'X-Amz-Signature': string
  Host: string
}

/**
 * AWS S3 bucket 에 유저의 판결문 pdf 파일을 업로드 하기 위한
 * signature 정보를 조회한다.
 *
 * @param name 파일명
 * @param size 파일 사이즈 (in bytes)
 */
export const fetchAwsUploadSignature = async ({ name, size }: fetchAwsUploadSignaturesParams) => {
  const { data } = await lboxHttpClient.post<FetchAwsUploadSignatureApiResponseData>(API_ENDPOINT.upload.myCase, {
    filename: name,
    size,
  })

  return data
}
