import Link from 'next/link'

import { externalUrl } from '@lbox/shared/constants'

import { Button, Checkbox, Modal, Spacing } from '@lbox-kr/libra/v2'
import { useBooleanState, useDidUpdate } from '@toss/react'
import cn from 'classnames'

interface CaseUploadConfirmModalProps {
  isOpen: boolean
  onOk: VoidFunction
  onClose: VoidFunction
}

export const CaseUploadConfirmModal = ({ isOpen, onOk, onClose }: CaseUploadConfirmModalProps) => {
  const [isChecked, , uncheck, toggleIsChecked] = useBooleanState(false)

  useDidUpdate(() => {
    uncheck()
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Headline>내 판결문 등록 서비스 운영정책 동의</Modal.Headline>
      <Modal.Body className={cn('text-lds2-body2-regular text-zinc-700')}>
        원활한 판결문 등록을 위해 서비스 운영정책에 대한 동의가 필요합니다.
        <Spacing size={48} />
        <div className={cn('flex gap-x-[8px]')}>
          <Checkbox
            checked={isChecked}
            onChange={toggleIsChecked}
            label={
              <span className={cn('pl-[4px] text-lds2-body2-regular text-zinc-700')}>
                내 판결문 등록 서비스 운영정책에 동의합니다.
              </span>
            }
          />
          <Link
            className={cn('text-lds2-body2-regular-underline text-zinc-500 underline')}
            href={externalUrl.내판결문등록_운영정책}
            target="_blank"
          >
            내용보기
          </Link>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="tonal" width="full" size="large" label="취소" onClick={onClose} />
        <Button buttonType="filled" width="full" label="확인" disabled={!isChecked} onClick={onOk} />
      </Modal.Footer>
    </Modal>
  )
}
