import { useEffect, useState } from 'react'

import Link from 'next/link'

import { IconGear } from '@lbox/shared/components'
import { LBOX_PAGE_PATH, LOG_MESSAGES, V2_PREFIX } from '@lbox/shared/constants'
import { useMediaQuery } from '@lbox/shared/hooks'
import { logger } from '@lbox/shared/utils'

import type { PopoverProps, TabItem } from '@lbox-kr/libra/v1'
import { IconCloseSmall, IconSetting, Popover, Tabs } from '@lbox-kr/libra/v1'
import { BottomSheet } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import NotificationPopoverItem from './notification-popover-item/NotificationPopoverItem'
import { USER_ALARM_API_ENDPOINT } from '../../../../apis/user-alarm/endpoints'
import { usePostUserAlarmList } from '../../../../apis/user-alarm/queries'
import type { UserAlarm } from '../../../../apis/user-alarm/types'
import { MAPPING_NOTIFICATION_CATEGORY_VALUE_TO_TYPES, NOTIFICATION_CATEGORIES } from '../../../../constants/#header'
import { useHorizontalScrollByDragAndDrop } from '../../../../hooks/useHorizontalScrollByDragAndDrop'
import type { PageableData } from '../../../../types/common'
import type { OnFireClientTrackingEvent, OnFireUserEvent } from '../../../../types/event'
import { getAuthority } from '../../../../utils/getAuthority'

interface NotificationPopoverProps extends PopoverProps {
  onFireUserEvent: OnFireUserEvent
  onFireClientTrackingEvent?: OnFireClientTrackingEvent
}

const NotificationPopover = ({
  isOpen,
  handleClickBackdrop,
  left,
  right,
  top,
  onFireUserEvent,
  onFireClientTrackingEvent,
}: NotificationPopoverProps) => {
  const [selectedTabItem, setSelectedTabItem] = useState<TabItem>(NOTIFICATION_CATEGORIES[0])
  const [categoryTypes, setCategoryTypes] = useState<string[]>([])

  const { data: userAlarmList, refetch: refetchUserAlarmList } = usePostUserAlarmList(
    { types: categoryTypes },
    {
      meta: {
        onSuccess: (data) => {
          if ((data as PageableData<UserAlarm[]>).content) {
            return
          }

          logger.log(LOG_MESSAGES.noneSpecificDataField(USER_ALARM_API_ENDPOINT.list), {
            payload: {
              categoryTypes,
            },
            data,
          })
        },
      },
    }
  )

  const { containerRef, handleMouseDown, handleMouseMove, handleMouseUp } = useHorizontalScrollByDragAndDrop()

  const { isMobile } = useMediaQuery()

  // NOTE: 운영계에서 정상 동작을 위해서 content에 빈 배열 처리 / 개발계에서는 디버깅을 위해서 undefined가 나올 수 있도록 처리
  const content = process.env.NODE_ENV === 'production' ? (userAlarmList?.content ?? []) : userAlarmList?.content
  const contentLength = content?.length

  async function handleChangeTab(item: TabItem) {
    onFireUserEvent('click_gnb_alarm_tab', { type: item.value })
    await onFireClientTrackingEvent?.({ type: 'click_gnb_alarm', value: item.label })

    setSelectedTabItem(item)

    const types = MAPPING_NOTIFICATION_CATEGORY_VALUE_TO_TYPES[item.value] ?? []
    setCategoryTypes(types)
  }

  useEffect(() => {
    refetchUserAlarmList()
  }, [refetchUserAlarmList, categoryTypes])

  return (
    <>
      {isMobile && (
        <BottomSheet
          isOpen={isOpen}
          showsCloseButton
          rootClassName={cn('h-[calc(var(--custom-vh,1vh)*80)]')}
          onClose={() => {
            handleClickBackdrop?.()
          }}
        >
          <BottomSheet.Headline>
            알림
            <IconGear size={16} weight="fill" className={cn('ml-[4px]')} />
          </BottomSheet.Headline>
          <BottomSheet.Body className={cn('px-0')}>
            {/** Tab */}
            <div className="relative border-b border-solid border-zinc-50">
              <div className="absolute right-0 h-full w-[24px] bg-gradient-to-l from-white" />
              <div className="flex max-w-full overflow-x-scroll pl-[16px] pr-[24px] font-[500] no-scrollbar">
                <Tabs
                  type="underline"
                  items={NOTIFICATION_CATEGORIES}
                  value={selectedTabItem}
                  onChangeTab={handleChangeTab}
                />
              </div>
            </div>
            {/** Tab content */}
            <div>
              {(!userAlarmList || contentLength === 0) && (
                <div
                  className={cn(
                    'border-b border-solid border-zinc-50 px-[24px] py-[16px]',
                    'hover:bg-primary-blue-light-1'
                  )}
                >
                  <div className="text-[14px]">
                    <p>새로운 알림이 없습니다.</p>
                  </div>
                </div>
              )}
              {userAlarmList?.content?.map((notification) => (
                <NotificationPopoverItem
                  key={notification.id}
                  notification={notification}
                  isCaseLink={selectedTabItem.label === '판결문 요청'}
                />
              ))}
            </div>
          </BottomSheet.Body>
        </BottomSheet>
      )}
      {!isMobile && (
        <Popover isOpen={isOpen} handleClickBackdrop={handleClickBackdrop} top={top} left={left} right={right}>
          {/* 100vh-96px: 팝오버가 화면 바깥으로 나가지 않도록 [상하 여백 96px] */}
          <div className="max-h-[calc(100vh-96px)] max-w-[430px] overflow-y-hidden">
            <div className="my-[16px] ml-[24px] mr-[16px] flex items-center justify-between">
              <div className="flex items-center">
                <span className="text-[20px] font-[500]">알림</span>
                <Link
                  href={`${getAuthority()}/${V2_PREFIX}${LBOX_PAGE_PATH.user.myPage.alarm}`}
                  className="block p-[4px]"
                >
                  <IconSetting size={16} />
                </Link>
              </div>
              <button type="button" className="p-[8px]" onClick={handleClickBackdrop}>
                <IconCloseSmall />
              </button>
            </div>

            <div className="max-w-full">
              <div className="relative border-b border-solid border-zinc-50">
                <div className="absolute right-0 h-full w-[24px] bg-gradient-to-l from-white" />
                <div
                  className="flex max-w-full overflow-x-scroll px-[24px] font-[500] no-scrollbar"
                  ref={containerRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <Tabs
                    type="underline"
                    items={NOTIFICATION_CATEGORIES}
                    value={selectedTabItem}
                    onChangeTab={handleChangeTab}
                  />
                </div>
              </div>

              {/* 100vh-210px: 팝오버 탭 내 콘텐츠만 스크롤되도록 [상하 여백 96px] + [타이틀 + 탭 높이 115px]  */}
              <div className="max-h-[calc(100vh-211px)] overflow-y-auto">
                {(!userAlarmList || contentLength === 0) && (
                  <div
                    className={cn(
                      'border-b border-solid border-zinc-50 px-[24px] py-[16px]',
                      'hover:bg-primary-blue-light-1'
                    )}
                  >
                    <div className="text-[14px]">
                      <p>새로운 알림이 없습니다.</p>
                    </div>
                  </div>
                )}

                {userAlarmList?.content?.map((notification) => {
                  return (
                    <NotificationPopoverItem
                      key={notification.id}
                      notification={notification}
                      isCaseLink={selectedTabItem.label === '판결문 요청'}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </Popover>
      )}
    </>
  )
}

export default NotificationPopover
