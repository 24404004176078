import type { MouseEvent } from 'react'
import { useRef, useState } from 'react'

/**
 * 가로로 드래그에서 스크롤할 수 있도록 도와주는 훅
 */
export function useHorizontalScrollByDragAndDrop() {
  const containerRef = useRef<HTMLDivElement>(null)

  const [isDragging, setIsDragging] = useState(false)
  const [dragStartX, setDragStartX] = useState(0)

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    setDragStartX(event.clientX)
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging) {
      return
    }

    const container = containerRef.current
    const dragDistance = dragStartX - event.clientX
    const scrollIncrement = 1 // Adjust this value for faster or slower scrolling

    // Use requestAnimationFrame for smoother scrolling
    const scrollUpdate = () => {
      if (container) {
        container.scrollLeft += dragDistance * scrollIncrement
        setDragStartX(event.clientX)
      }
    }
    requestAnimationFrame(scrollUpdate)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  return {
    containerRef,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  }
}
