import type { ReactNode } from 'react'
import { useEffect } from 'react'

import { IconBellRinging, IconCheckCircle, IconEyeSlash } from '@lbox/shared/components'
import { noop } from '@lbox/shared/utils'

import { Button, Modal } from '@lbox-kr/libra/v2'
import { usePreservedCallback } from '@toss/react'
import cn from 'classnames'

interface CaseUploadProcessModal {
  isOpen: boolean
  onOk: VoidFunction
  onClose: VoidFunction
  onOpen?: VoidFunction
}

export const CaseUploadProcessModal = ({ isOpen, onOk, onClose, onOpen = noop }: CaseUploadProcessModal) => {
  const handleOpen = usePreservedCallback(onOpen)

  useEffect(() => {
    if (isOpen) {
      handleOpen()
    }
  }, [isOpen, handleOpen])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Headline>변호사님의 판결문이 엘박스에 안전하게 등록되는 과정</Modal.Headline>
      <Modal.Body>
        <div className={cn('flex flex-col items-center gap-y-[24px] py-[16px]')}>
          <Step
            icon={<IconEyeSlash size={24} weight="fill" className={cn('fill-foreground-brand')} />}
            description={
              <>
                <div className={cn('text-lds2-body3-semibold text-text-secondary')}>1. 개인정보 비실명화</div>
                <p className={cn('text-lds2-body3-regular text-text-secondary')}>
                  판결문 1건 당 엘박스의 전문 교육을 이수한 2명 이상의 검수자가 배정되어, 내용을 직접 확인하고 인물 및
                  사건을 특정할 수 없도록 비실명화 합니다.
                </p>
              </>
            }
          />
          <IconTriangle />
          <Step
            icon={<IconBellRinging size={24} weight="fill" className={cn('fill-amber-400')} />}
            description={
              <>
                <div className={cn('text-lds2-body3-semibold text-text-secondary')}>2. 승인 요청 알림 발송</div>
                <p className={cn('text-lds2-body3-regular text-text-secondary')}>
                  비실명화 후 카카오톡을 통해 승인 요청 알림을 드립니다.
                </p>
                <p className={cn('text-lds2-caption1-regular text-text-tertiary')}>(1건 당 2-3 영업일 소요)</p>
              </>
            }
          />
          <IconTriangle />
          <Step
            icon={<IconCheckCircle size={24} weight="fill" className={cn('fill-utility-green-dark')} />}
            description={
              <>
                <div className={cn('text-lds2-body3-semibold text-text-secondary')}>3. 승인 후 최종 등록</div>
                <p className={cn('text-lds2-body3-regular text-text-secondary')}>
                  변호사님의 승인 후 엘박스에 최종 등록됩니다.
                </p>
                <p className={cn('text-lds2-caption1-regular text-text-tertiary')}>
                  (‘마이페이지 {'>'} 내 수행사건 등록’ 페이지에서 비실명화된 판결문에 대한 승인 및 수정 요청 가능)
                </p>
              </>
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="filled" width="full" label="확인" onClick={onOk} />
      </Modal.Footer>
    </Modal>
  )
}

const Step = ({ icon, description }: { icon: ReactNode; description: ReactNode }) => {
  return (
    <div className={cn('flex w-full gap-x-[16px]')}>
      <div
        className={cn(
          'flex shrink-0 items-center justify-center',
          'h-[88px] w-[100px] rounded-[8px] border-[0.5px] border-border-secondary bg-background-secondary'
        )}
      >
        {icon}
      </div>
      <div className={cn('flex-1 break-keep')}>{description}</div>
    </div>
  )
}

const IconTriangle = () => (
  <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2546 -9.81479e-08C17.1204 -6.03038e-08 17.5771 1.02544 16.9979 1.66896L10.2433 9.17412C9.84605 9.6155 9.15395 9.6155 8.7567 9.17412L2.00207 1.66897C1.4229 1.02544 1.87959 -7.26501e-07 2.74536 -6.88657e-07L16.2546 -9.81479e-08Z"
      fill="#D4D4D8"
    />
  </svg>
)
