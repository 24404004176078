import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'

import DesktopGuestButtons from './desktop/DesktopGuestButtons'
import MobileGuestButtons from './mobile/MobileGuestButtons'
import type { GuestButtonsProps } from './types'
import { loginLog, registerLog } from './utils'

const GuestButtons = (props: GuestButtonsProps) => {
  const { onFireUserEvent } = props

  const handleClickLogin = () => {
    onFireUserEvent('click_gnb_login')
    loginLog(onFireUserEvent).clickLogin()
    window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.login.index()}`)
  }

  const handleClickRegister = () => {
    onFireUserEvent('click_gnb_signup')
    registerLog(onFireUserEvent).clickSignup()
    window.location.assign(`/${V2_PREFIX}${LBOX_PAGE_PATH.register.index()}`)
  }

  return (
    <>
      <div className="block lds2-tablet:hidden">
        <MobileGuestButtons {...props} onClickLogin={handleClickLogin} />
      </div>

      <div className="hidden lds2-tablet:block">
        <DesktopGuestButtons {...props} onClickLogin={handleClickLogin} onClickRegister={handleClickRegister} />
      </div>
    </>
  )
}

export default GuestButtons
