import { Button, Modal } from '@lbox-kr/libra/v2'
import cn from 'classnames'

interface CaseUploadCancelModalProps {
  isOpen: boolean
  onOk: VoidFunction
  onClose: VoidFunction
}

export const CaseUploadCancelModal = ({ isOpen, onOk, onClose }: CaseUploadCancelModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Headline>판결문 등록 신청을 취소하시겠습니까?</Modal.Headline>
      <Modal.Body className={cn('text-lds2-body2-regular text-zinc-900')}>
        취소하시는 경우 업로드된 판결문은 등록되지 않습니다.
      </Modal.Body>
      <Modal.Footer>
        <Button buttonType="tonal" width="full" size="large" label="돌아가기" onClick={onClose} />
        <Button buttonType="filled" width="full" size="large" label="등록 신청 취소" onClick={onOk} />
      </Modal.Footer>
    </Modal>
  )
}
