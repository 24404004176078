import { cloneDeep } from '@lbox/shared/utils'

export const isObjectType = (args: unknown): args is object => {
  return args !== null && typeof args === 'object'
}

/**
 * Object 의 key, value pair 를 주어진 predicate 조건에 맞게 필터링하여 반환한다
 */
export const filterObject = <T>(target: Record<string, T>, predicate: (item: [string, T]) => boolean) => {
  const deepCopied = cloneDeep(target)
  const filtered = Object.entries(deepCopied).filter((item) => predicate(item))

  return Object.fromEntries(filtered)
}

/**
 * Object의 속성 중에 null 값이 있는지 체크하는 함수
 */
export const hasNullValue = <T extends Record<PropertyKey, unknown>>(obj: T) => {
  return Object.values(obj).some((value) => value === null)
}
