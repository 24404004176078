/**
 * 주어진 시간으로부터 현재까지 경과한 시간을 적절한 단위로 표시
 *
 * @param from Date 기준 시간(ms). 예) 1684819332279
 * @returns 경과 시간. 예) 2달 전, 13분 전, 방금 전
 */
export function getElapsedTime(from: number | null) {
  if (!from) {
    return ''
  }

  const diffInSeconds = (new Date().getTime() - from) / 1000

  const TIME_UNITS_DESC = [
    { text: '년', seconds: 60 * 60 * 24 * 365 },
    { text: '달', seconds: 60 * 60 * 24 * 30 },
    { text: '일', seconds: 60 * 60 * 24 },
    { text: '시간', seconds: 60 * 60 },
    { text: '분', seconds: 60 },
  ]

  for (const unit of TIME_UNITS_DESC) {
    const elapsedTime = Math.floor(diffInSeconds / unit.seconds)

    if (elapsedTime > 0) {
      return `${elapsedTime}${unit.text} 전`
    }
  }

  // 시간 차이가 1분 미만인 경우
  return '방금 전'
}
