import Lottie from 'react-lottie'

import { useIsClient } from '@lbox/shared/hooks'

import { Button } from '@lbox-kr/libra/v2'

import caseUploadLottie from './case-upload-lottie/case-upload.lottie.json'
import { isRestrictedUser } from '../../../../../utils/user/checkIsRestrictedUser'
import useGetCaseUploadPoint from '../../../../my-case-upload-modal/$hooks/useGetCaseUploadPoint'

const PDP_PAGE_PATHS = [
  '/case',
  '/text-viewer',
  '/pdf-viewer',
  '/statute',
  '/publication/commentary',
  '/publication/practice',
  '/reference',
]

interface CaseUploadButtonProps {
  isJobLawyer: boolean
  onClickRegisterMyCaseButton: VoidFunction
}

const CaseUploadButton = ({ isJobLawyer, onClickRegisterMyCaseButton }: CaseUploadButtonProps) => {
  const isClient = useIsClient()
  const { isEventPeriod } = useGetCaseUploadPoint()

  if (!isJobLawyer || isRestrictedUser()) {
    return null
  }

  const isPdpPage = isClient
    ? PDP_PAGE_PATHS.some((path) => window.location.pathname.replace('/v2', '').startsWith(path))
    : true

  if (isEventPeriod) {
    return (
      <>
        {!isPdpPage && (
          <div className="group hidden lds2-tablet:block">
            <div className="group-hover:hidden">
              <Lottie
                width={127.49}
                height={40}
                options={{
                  animationData: caseUploadLottie,
                  autoplay: true,
                }}
              />
            </div>

            <Button
              className="hidden group-hover:block"
              type="button"
              buttonType="tonal"
              size="medium"
              label="내 판결문 등록"
              onClick={onClickRegisterMyCaseButton}
            />
          </div>
        )}

        {isPdpPage && (
          <Button
            type="button"
            buttonType="tonal"
            size="medium"
            label="내 판결문 등록"
            onClick={onClickRegisterMyCaseButton}
          />
        )}
      </>
    )
  }

  return (
    <Button
      type="button"
      buttonType="tonal"
      size="medium"
      label="내 판결문 등록"
      onClick={onClickRegisterMyCaseButton}
    />
  )
}

export default CaseUploadButton
