import cn from 'classnames'
import { motion } from 'framer-motion'

export default function StepWrapper({
  children,
  className,
  skipEnter,
}: {
  children: React.ReactNode
  className?: string
  skipEnter?: boolean
}) {
  return (
    <motion.div
      animate={
        skipEnter
          ? undefined
          : {
              opacity: [0, 1],
              y: [100, 0],
              transition: { duration: 0.5, ease: 'linear' },
            }
      }
      exit={{
        opacity: 0,
        y: -100,
        transition: { duration: 0.5, ease: 'linear' },
      }}
      className={cn('absolute w-full', className)}
    >
      {children}
    </motion.div>
  )
}
