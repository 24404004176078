import { isServer } from '@toss/utils'

/**
 * 경찰청 복지몰과 연결된 도메인인지 판단한다
 */
export const isPoliceChannel = () => {
  if (isServer()) {
    return false
  }

  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return false
  }

  return hostname === 'police-non-profit.lbox.kr'
}
