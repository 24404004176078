import usePostUserLogout from '../../hooks/user/usePostUserLogout'
import { logoutProcess } from '../../utils/api'

/**
 * 로그아웃 시 동작 4개
 * 1. 쿠키 제거
 * 2. 세션 스토리지 제거
 * 3. 로컬 스토리지 제거
 * 4. logout API 호출
 */
export function useLogout({ deviceId }: { deviceId: string | null }) {
  const { mutate: mutateLogout } = usePostUserLogout()

  function logout() {
    logoutProcess()

    if (!deviceId) {
      return
    }
    mutateLogout({ deviceId })
  }

  return { logout }
}
