import { IconMagnifyingGlass, IconUserCircle } from '@lbox/shared/components'

import cn from 'classnames'

import type { SuggestionsProps } from '../$types/searchDataList'
import { getBelongedCourtsDetail } from '../$utils/getBelongedCourtsDetail'
import { highlightSearchKeywordInPopover } from '../../$utils/highlightKeyword'
import SearchBarDataListItem from '../search-bar-data-list-item/SearchBarDataListItem'

const Suggestions = (props: SuggestionsProps) => {
  const {
    searchedJudges,
    suggestions,
    availableFastSearchCommands,
    showsSearchedJudgesResult,
    showsSuggestionsResult,
    handleClickSuggestion,
    handleClickSearchedJudgeItem,
  } = props

  return (
    <div>
      <div className={cn('flex items-center justify-between', 'pb-[16px]')}>
        <span className={cn('text-lds2-body3-medium text-zinc-500')}>추천 검색어</span>
      </div>

      {showsSearchedJudgesResult &&
        searchedJudges.map(({ id, name, serial, retired, court, bar_type }) => {
          const searchedJudgeItem = { id, name, serial, retired, court, bar_type }

          return (
            <SearchBarDataListItem
              key={id}
              icon={<IconUserCircle size={20} className="text-zinc-500" />}
              mainText={name}
              subText={getBelongedCourtsDetail(searchedJudgeItem)}
              onClickListItem={() => handleClickSearchedJudgeItem(searchedJudgeItem)}
            />
          )
        })}
      {showsSuggestionsResult &&
        suggestions.map((suggestion) => (
          <div
            key={suggestion}
            role="button"
            className={cn(
              'flex items-center gap-[12px]',
              'h-[40px]',
              'px-[8px]',
              'cursor-pointer whitespace-nowrap rounded-[4px] hover:bg-state-layer-on-light-01'
            )}
            onClick={() => handleClickSuggestion(suggestion)}
          >
            <div className="h-[20px] shrink-0">
              <IconMagnifyingGlass size={20} className="text-zinc-500" />
            </div>
            <div
              className="truncate text-lds2-body2-regular"
              dangerouslySetInnerHTML={{
                __html: highlightSearchKeywordInPopover({
                  searchKeyword: suggestion,
                  availableFastSearchCommands,
                }),
              }}
            />
          </div>
        ))}
    </div>
  )
}

export default Suggestions
