import { API_ENDPOINT } from '../../constants/api/endpoint'
import type { UserHasPlan } from '../../types/user/userHasPlan'
import { lboxHttpClient } from '../../utils/api'

export const getUserHasPlan = async () => {
  const { data } = await lboxHttpClient.get<UserHasPlan>(API_ENDPOINT.userHasPlan.base)
  return data
}

export const getUserHasPlanInServerSide = async (xForwardedFor: string | string[] | undefined) => {
  const { data } = await lboxHttpClient.get<UserHasPlan>(API_ENDPOINT.userHasPlan.base, {
    headers: {
      'X-Forwarded-For': xForwardedFor,
    },
  })

  return data
}
