import { IconCaretLeft } from '@lbox/shared/components'

import { BottomSheet } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import type { SearchBottomSheetProps } from '../$types/searchBar'
import SearchBarMobile from '../search-bar-mobile/SearchBarMobile'

export const SearchBottomSheet = ({
  isOpen,
  placeholder,
  onClickPreviousButton: handleClickPreviousButton,
  router,
  onFireUserEvent,
}: SearchBottomSheetProps) => {
  return (
    <BottomSheet isOpen={isOpen} isShowUpAnimated={false} maxHeightFull>
      <BottomSheet.Body>
        <div className={cn('w-full overflow-hidden', 'h-[calc(var(--custom-vh,1vh)*100-16px)]')}>
          <div className="relative flex h-[64px] w-full items-center justify-center text-body1">
            <button
              type="button"
              className={cn(
                'absolute left-0',
                'h-[40px] w-[40px]',
                'flex items-center justify-center',
                'text-zinc-700'
              )}
              onClick={handleClickPreviousButton}
            >
              <IconCaretLeft size={24} />
            </button>
            <span className={cn('inline-block select-none text-lds2-body1-medium text-zinc-700')}>통합검색</span>
          </div>

          <SearchBarMobile placeholder={placeholder} router={router} onFireUserEvent={onFireUserEvent} />
        </div>
      </BottomSheet.Body>
    </BottomSheet>
  )
}
