import React from 'react'

import cn from 'classnames'

import type { SearchBarDataListItemProps } from '../$types/searchDataList'
import { useGetAvailableFastSearchCommandsByURL } from '../../$hooks/useAvailableFastSearchCommands'
import { highlightSearchKeywordInPopover } from '../../$utils/highlightKeyword'

const SearchBarDataListItem = ({
  icon: Icon,
  mainText,
  subText,
  trailingIcon: TrailingIcon,
  onClickListItem,
}: SearchBarDataListItemProps) => {
  const availableFastSearchCommands = useGetAvailableFastSearchCommandsByURL()

  return (
    <div
      role="button"
      className={cn(
        'flex items-center gap-[16px]',
        'h-[40px]',
        'px-[8px]',
        'cursor-pointer whitespace-nowrap rounded-[4px] hover:bg-state-layer-on-light-01'
      )}
      onClick={onClickListItem}
    >
      <div className="h-[20px] shrink-0">{Icon}</div>

      <div className={cn('flex flex-1', 'max-w-[calc(100%-84px)]', 'gap-[12px]')}>
        <div
          className="truncate text-lds2-body2-regular text-zinc-900"
          dangerouslySetInnerHTML={{
            __html: highlightSearchKeywordInPopover({
              searchKeyword: mainText,
              availableFastSearchCommands,
            }),
          }}
        />
        {subText && <div className="text-lds2-body3-regular text-zinc-500">{subText}</div>}
      </div>

      {TrailingIcon}
    </div>
  )
}

export default SearchBarDataListItem
