import { isServer } from '@toss/utils'

import { SESSION_STORAGE_KEYS } from '../../constants/sessionStorage'

interface NonProfitOrganizationInfo {
  name: string
  securityLevel: 'UPLOAD_AND_INPUT_DISABLE'
}

/**
 * 접속한 비영리단체 정보
 */
export function getNonProfitOrganizationInfoFromSession(): NonProfitOrganizationInfo | null {
  if (isServer()) {
    return null
  }

  const nonProfitOrganizationInfoSerialized = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.nonProfitOrgInfo) ?? ''

  try {
    return JSON.parse(nonProfitOrganizationInfoSerialized)
  } catch {
    return null
  }
}
