import type { FetchAwsUploadSignatureApiResponseData } from '@lbox-kr/components/src/components//my-case-upload-modal/$apis/fetchAwsUploadSignature'

import { lboxHttpClient } from '../../../utils/api'

export interface UploadMyCaseToAwsS3 {
  awsSignatureInfo: FetchAwsUploadSignatureApiResponseData
  file: File
}

export type UploadMyCaseToAwsApiResponseData = never

export const uploadMyCaseToAwsS3 = async ({ awsSignatureInfo, file }: UploadMyCaseToAwsS3) => {
  const { status } = await lboxHttpClient.post<UploadMyCaseToAwsApiResponseData>(
    `https://${awsSignatureInfo.Host}`,
    constructFormData(awsSignatureInfo, file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: false,
    }
  )

  return 200 <= status && status < 300 ? false : true
}

const constructFormData = (awsSignatureInfo: UploadMyCaseToAwsS3['awsSignatureInfo'], file: File) => {
  const formData = new FormData()
  Object.entries(awsSignatureInfo)
    .filter(([key]) => key !== 'Host')
    .forEach(([key, value]) => formData.append(key, value))
  formData.append('file', file)

  return formData
}
