import { LBOX_PAGE_PATH, V2_PREFIX, V1_PAGE_PATH } from '@lbox/shared/constants'
import { isPublicChannel } from '@lbox/shared/utils'

import type { OnFireUserEvent } from '../../../../types/event'

const getChannel = () => {
  const { pathname } = window.location

  if (pathname === `/${V2_PREFIX}`) {
    return 'main'
  }

  if (pathname.startsWith(`/${V2_PREFIX}/login`)) {
    return 'login'
  }

  if (pathname.startsWith(`/${V2_PREFIX}${LBOX_PAGE_PATH.pricing.index}`)) {
    return '요금제'
  }

  if (pathname.startsWith(`/${V2_PREFIX}${LBOX_PAGE_PATH.search.index}`)) {
    return 'SRP'
  }

  if (
    pathname.startsWith(V1_PAGE_PATH.case.index) ||
    pathname.startsWith(`/${V2_PREFIX}${LBOX_PAGE_PATH.statute.index}`) ||
    pathname.startsWith(V1_PAGE_PATH.reference.dbpia.index) ||
    pathname.startsWith(`/${V2_PREFIX}${LBOX_PAGE_PATH.pdfViewer.index}`) ||
    pathname.startsWith(`/${V2_PREFIX}${LBOX_PAGE_PATH.textViewer.index}`)
  ) {
    return 'PDP'
  }

  return '그 외'
}

export const registerLog = (onFireUserEvent: OnFireUserEvent) => {
  return {
    clickSignup: () => {
      onFireUserEvent('click_signup', {
        isPublic: isPublicChannel() ? 'Y' : 'N',
        channel: getChannel(),
      })
    },
  }
}

export const loginLog = (onFireUserEvent: OnFireUserEvent) => {
  return {
    clickLogin: () => {
      onFireUserEvent('click_login', {
        isPublic: isPublicChannel() ? 'Y' : 'N',
      })
    },
  }
}
