import { Fragment } from 'react'

import { LBOX_PAGE_PATH, V2_PREFIX } from '@lbox/shared/constants'

import cn from 'classnames'

import type { UserAlarm } from '../../../../../types/#header'
import { getElapsedTime } from '../../../../../utils/getElapsedTime'
import { extractCaseNoAndCourt } from '../../../../case-pdp-right-panel/case-list/$utils/extractFromDocId'

export interface NotificationPopoverItemProps {
  notification: UserAlarm
  isCaseLink: boolean
}

const NotificationPopoverItem = ({ notification, isCaseLink }: NotificationPopoverItemProps) => {
  const { link, message, linkText, updatedAt } = notification

  const handleClick = () => {
    if (!link) {
      return
    }

    if (link.startsWith('http')) {
      window.location.assign(link)
      return
    }

    if (isCaseLink) {
      window.location.assign(
        `/${V2_PREFIX}${LBOX_PAGE_PATH.case.withDocId(extractCaseNoAndCourt(link.replace('/', '')))}`
      )
      return
    }

    const absolutePath = `/${link.replace(/^\//, '')}`
    window.location.assign(absolutePath)
  }

  return (
    <div
      className={cn('border-b border-solid border-zinc-50 px-[24px] py-[16px]', 'hover:bg-primary-blue-light-1', {
        'hover:cursor-pointer': link !== null,
      })}
      onClick={handleClick}
    >
      <div className="text-[14px]">
        <p>
          {message.split('\n').map((line, index) => {
            return (
              <Fragment key={index}>
                <span>{line}</span>
                <br />
              </Fragment>
            )
          })}
        </p>
      </div>
      {link && <p className="mt-[8px] text-[14px] text-lbox-blue">{linkText}</p>}
      <p className="mt-[8px] text-[12px] text-zinc-400">{getElapsedTime(updatedAt)}</p>
    </div>
  )
}

export default NotificationPopoverItem
