import { useEffect, useState } from 'react'

import { isStringType } from '@lbox/shared/utils'

import type { History } from '../$types/histories'
import { addGuestHistory, getGuestHistories, isSameHistory, removeGuestHistory } from '../$utils/histories'
import { useGetUserCurrent } from '../../../apis/user/user-current/queries'
import useMe from '../../../hooks/useMe'
import usePostUserUpdateData from '../../../hooks/user/usePostUserUpdateData'
import type { User } from '../../../types/user/user'
import { parseJSON } from '../../../utils/parseJSON'

/**
 * 상태: histories
 * 상태 갱신: 검색어 변경, 데이터 갱신
 * 데이터: user.data.storage.history
 * 데이터 갱신: addHistory, removeHistory
 */
export default function useSearchHistories() {
  const [histories, setHistories] = useState<History[]>([])

  const { isLoggedIn } = useMe()
  const { data: user } = useGetUserCurrent({ enabled: isLoggedIn })
  const { mutateAsync: updateUser } = usePostUserUpdateData()

  useEffect(() => {
    if (!isLoggedIn) {
      setHistories(filterNotLawyerSearch(getGuestHistories()))
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      const allHistories = parseHistoriesFromUser(user)
      setHistories(allHistories)
    }
  }, [user?.data?.storage?.history, isLoggedIn])

  function addHistory(selectedHistory: History) {
    if (isLoggedIn) {
      addUserHistory(selectedHistory)
      return
    }

    addGuestHistory(selectedHistory)
    setHistories(getGuestHistories())
  }

  function addUserHistory(selectedHistory: History) {
    const allHistories = parseHistoriesFromUser(user)
    // 기존에 존재할 경우, 제거한 후 맨앞으로 이동
    const removedHistories = allHistories.filter((history) => !isSameHistory(history, selectedHistory))
    const addedHistories = [selectedHistory, ...removedHistories]
    setHistories(addedHistories)

    const updatedStorage = {
      ...(user?.data?.storage ?? {}),
      history: JSON.stringify(addedHistories),
    }
    updateUser({ storage: updatedStorage })
  }

  function removeHistory(selectedHistory: History) {
    if (isLoggedIn) {
      removeUserHistory(selectedHistory)
      return
    }

    removeGuestHistory(selectedHistory)
    setHistories(getGuestHistories())
  }

  function removeUserHistory(selectedHistory: History) {
    const allHistories = parseHistoriesFromUser(user)
    const removedHistories = allHistories.filter((history) => !isSameHistory(history, selectedHistory))
    setHistories(removedHistories)

    const updatedStorage = {
      ...(user?.data?.storage ?? {}),
      history: JSON.stringify(removedHistories),
    }
    updateUser({ storage: updatedStorage })
  }

  return {
    histories,
    addHistory,
    removeHistory,
  }
}

/** TODO: API Spec이 변경되면 사라질 코드. */
function parseHistoriesFromUser(user?: User) {
  if (user === undefined) {
    return []
  }

  const parsedHistoryList = parseJSON<History[] | string>(user?.data?.storage?.history ?? '[]') || []

  if (!Array.isArray(parsedHistoryList)) {
    return []
  }

  return parsedHistoryList.filter((parsedHistory) => !isStringType(parsedHistory) || !parsedHistory.startsWith('{'))
}

/**
 * 기존 판사검색 시 판사 객체를 저장했기 때문에 문자열로 표현 시 괄호로 시작함
 */
function filterNotLawyerSearch(histories: string[]) {
  return histories.filter((history) => (isStringType(history) ? !history.startsWith('{') : false))
}
