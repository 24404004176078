import { API_ENDPOINT } from '../../constants/api/endpoint'
import type { Judge } from '../../types/judge'
import type { LboxUser } from '../../types/user/lboxUser'
import { lboxHttpClient } from '../../utils/api'

/**
 * 쿠키 정보를 이용해 현재 유저에 대한 아주 많은 정보 조회
 */
export async function getLboxUser() {
  const { data } = await lboxHttpClient.get<LboxUser>(API_ENDPOINT.lboxUser.base)
  return data
}

/**
 * 이름에 검색어를 포함하는 판사 목록 (최대 5개)
 */
export async function getJudgeList(query: string) {
  const urlSearchParams = new URLSearchParams({
    name: query,
  })

  try {
    const {
      data: { content: judges },
    } = await lboxHttpClient.get<{ content: Judge[] }>(`${API_ENDPOINT.judge.list}`, {
      params: urlSearchParams,
    })
    return judges ?? []
  } catch {
    return []
  }
}
