import { useState } from 'react'

import { Alert, Button, Checkbox, Modal, Snackbar, Spacing } from '@lbox-kr/libra/v2'
import { useBooleanState, useDidUpdate, useToggleState } from '@toss/react'
import cn from 'classnames'

import useMe from '../../hooks/useMe'
import LawyerVerifyingModal from '../lawyer-verifying-modal/LawyerVerifyingModal'
import type { ErrorCallback } from './$hooks/useMyCaseUpload'
import { useRegisterMyCase } from './$hooks/useRegisterMyCase'
import { useUploadFiles } from './$hooks/useUploadFiles'
import { CaseUploadArea } from './CaseUploadArea'
import { CaseUploadCancelModal } from './CaseUploadCancelModal'
import { CaseUploadCompleteModal } from './CaseUploadCompleteModal'
import { CaseUploadConfirmModal } from './CaseUploadConfirmModal'
import { CaseUploadEventBanner } from './CaseUploadEventBanner'
import { CaseUploadEventModal } from './CaseUploadEventModal'
import { CaseUploadFileLimitModal } from './CaseUploadFileLimitModal'
import { CaseUploadNotice } from './CaseUploadNotice'
import { CaseUploadProcessModal } from './CaseUploadProcessModal'
import { DocumentImage } from './DocumentImage'
import { UploadFileList } from './UploadFileList'

const MAX_FILE_COUNT = 30

interface MyCaseUploadModalProps {
  isOpen: boolean
  onClose: VoidFunction
  onSuccessRegister?: VoidFunction
  onFireUserEvent: (eventType: string, eventProperties?: Record<string, unknown>) => void
  onFireErrorLog?: ErrorCallback
}

export const MyCaseUploadModal = ({
  isOpen,
  onClose,
  onSuccessRegister,
  onFireUserEvent,
  onFireErrorLog,
}: MyCaseUploadModalProps) => {
  const { lboxUser, isLboxUserDataLoading } = useMe()

  const {
    uploadedFiles,
    handleAcceptFiles,
    handleRejectFiles,
    handleRemoveFile,
    undo: restoreRemovedFile,
    clear: clearUploadedFiles,
  } = useUploadFiles({ onError: onFireErrorLog })

  const [isNameHidden, toggleIsNameHidden] = useToggleState(false)
  const [isUploadModalOpen, openUploadModal, closeUploadModal] = useBooleanState(false)
  const [isProcessModalOpen, openProcessModal, closeProcessModal] = useBooleanState(false)
  const [isEventModalOpen, openEventModal, closeEventModal] = useBooleanState(false)
  const [isCancelModalOpen, openCancelModal, closeCancelModal] = useBooleanState(false)
  const [isCompleteModalOpen, openCompleteModal, closeCompleteModal] = useBooleanState(false)
  const [isVerifyingModalOpen, openVerifyingModal, closeVerifyingModal] = useBooleanState(false)
  const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] = useBooleanState(false)
  const [isFileLimitModalOpen, openFileLimitModal, closeFileLimitModal] = useBooleanState(false)
  const [lastRemovedFileName, setLastRemovedFileName] = useState('')
  const [isSnackbarOpen, openSnackbar, closeSnackbar] = useBooleanState(false)
  const [isVerifyingSuccessSnackbarOpen, openVerifyingSuccessSnackbar, closeVerifyingSuccessSnackbar] =
    useBooleanState(false)
  const [isRegisterErrorSnackbarOpen, openRegisterErrorSnackbar, closeRegisterErrorSnackbar] = useBooleanState(false)
  const { mutate: registerMyCase, isPending: isRegistering } = useRegisterMyCase()

  const uploadSuccessCount = uploadedFiles.filter(({ status }) => status === 'success').length
  const uploadErrorCount = uploadedFiles.filter(({ status }) => status === 'error').length
  const uploadingCount = uploadedFiles.filter(({ status }) => status === 'uploading').length
  const isUploading = uploadingCount > 0
  const checkboxDisabled = isUploading
  const buttonDisabled = uploadSuccessCount === 0 || isUploading

  useDidUpdate(() => {
    if (!isOpen) {
      return
    }
    if (lboxUser?.myCaseRegisterAgreement === true) {
      openUploadModal()
    } else {
      openConfirmModal()
      onFireUserEvent('view_caseregister_policy')
    }
  }, [isOpen])

  useDidUpdate(() => {
    if (isOpen) {
      onFireUserEvent('view_caseregister_modal')
    }
  }, [isOpen])

  function handleRegisterMyCase() {
    registerMyCase(
      uploadedFiles
        .filter(({ status }) => status === 'success')
        .map(({ fileName, filePath }) => ({ fileName, filePath, nameHide: isNameHidden })),
      {
        onSuccess: () => {
          onFireUserEvent('complete_caseregister_modal')
          onSuccessRegister?.()
          closeUploadModal()
          openCompleteModal()
        },
        onError: openRegisterErrorSnackbar,
      }
    )
  }

  function handleAccept(files: File[]) {
    if (uploadSuccessCount + files.length > MAX_FILE_COUNT) {
      openFileLimitModal()
      return
    }
    handleAcceptFiles(files)
  }

  function handleClose() {
    clearUploadedFiles()
    closeUploadModal()
    closeProcessModal()
    closeEventModal()
    closeCancelModal()
    closeCompleteModal()
    closeVerifyingModal()
    closeConfirmModal()
    closeFileLimitModal()
    onClose()
  }

  if (isLboxUserDataLoading || !lboxUser) {
    return null
  }

  return (
    <>
      <Modal size="medium" isOpen={isUploadModalOpen} onClose={handleClose}>
        <Modal.Headline>내 판결문 등록 신청</Modal.Headline>
        <Modal.Body>
          <p className={cn('text-lds2-body2-regular text-zinc-900')}>
            등록 신청해 주신 판결문은 개인/사건을 특정할 수 없도록 익명화한 후, 변호사님의 동의를 거쳐 안전하게 등록하고
            있습니다.{' '}
            <button type="button" className={cn('text-lds2-body2-regular text-zinc-500')} onClick={openProcessModal}>
              더 알아보기
            </button>
          </p>
          <Spacing size={24} />
          <CaseUploadEventBanner onClick={openEventModal} />
          <Spacing size={24} />
          <CaseUploadArea
            accept={{
              'application/pdf': ['.pdf'],
            }}
            onAccepted={handleAccept}
            onRejected={handleRejectFiles}
          >
            {uploadedFiles.length > 0 ? (
              <p className={cn('text-lds2-body1-regular text-zinc-700')}>
                추가할 판결문 PDF파일을 이곳으로 드래그하세요.
              </p>
            ) : (
              <>
                <DocumentImage />
                <Spacing size={8} />
                <p className={cn('text-lds2-body1-regular text-zinc-700')}>판결문 PDF파일을 이곳으로 드래그하세요.</p>
                <p className={cn('text-lds2-body3-regular text-zinc-500')}>
                  PDF 파일, 최대 {MAX_FILE_COUNT}개 업로드 가능
                </p>
              </>
            )}
          </CaseUploadArea>
          {uploadedFiles.length > 0 ? (
            <>
              <Spacing size={24} />
              <h3 className={cn('text-lds2-body2-semibold text-[#111]')}>
                {isUploading
                  ? `업로드 중(${uploadingCount}/${MAX_FILE_COUNT})`
                  : `업로드 (${uploadSuccessCount}/${MAX_FILE_COUNT})`}
              </h3>
              {uploadErrorCount > 0 && (
                <>
                  <Spacing size={16} />
                  <Alert.Error title={`${uploadErrorCount}개 항목의 업로드에 실패하였습니다.`} />
                </>
              )}
              <Spacing size={16} />
              <UploadFileList
                uploadFiles={uploadedFiles}
                onRemoveFile={(id) => {
                  setLastRemovedFileName(uploadedFiles.find((file) => file.id === id)?.fileName ?? '')
                  openSnackbar()
                  handleRemoveFile(id)
                }}
              />
              <Spacing size={16} />
              <Checkbox
                checked={isNameHidden}
                onChange={toggleIsNameHidden}
                disabled={checkboxDisabled}
                label={
                  <span className={cn('pl-[4px] text-lds2-body3-regular text-zinc-900')}>
                    판결문 내 변호사님(본인) 성함 공개 안 함
                  </span>
                }
              />
            </>
          ) : (
            <>
              <Spacing size={16} />
              <CaseUploadNotice />
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="box-border">
          <Button
            buttonType="tonal"
            width="full"
            size="large"
            label="취소"
            onClick={() => {
              onFireUserEvent('click_caseregister_modal_cancel')
              uploadedFiles.length > 0 ? openCancelModal() : handleClose()
            }}
          />
          <Button
            buttonType="filled"
            width="full"
            size="large"
            label="등록 신청하기"
            isLoading={isRegistering}
            disabled={buttonDisabled}
            onClick={lboxUser.lawyerVerified ? handleRegisterMyCase : openVerifyingModal}
          />
        </Modal.Footer>
      </Modal>
      <CaseUploadProcessModal isOpen={isProcessModalOpen} onClose={closeProcessModal} onOk={closeProcessModal} />
      <CaseUploadEventModal isOpen={isEventModalOpen} onClose={closeEventModal} />
      <CaseUploadCancelModal isOpen={isCancelModalOpen} onClose={closeCancelModal} onOk={handleClose} />
      <LawyerVerifyingModal
        isOpen={isVerifyingModalOpen}
        onVerifyLawyerSuccess={() => {
          closeVerifyingModal()
          openVerifyingSuccessSnackbar()
          handleRegisterMyCase()
        }}
        onClose={closeVerifyingModal}
        onFireUserEvent={onFireUserEvent}
      />
      <CaseUploadConfirmModal
        isOpen={isConfirmModalOpen}
        onOk={() => {
          closeConfirmModal()
          openUploadModal()
        }}
        onClose={handleClose}
      />
      <CaseUploadCompleteModal isOpen={isCompleteModalOpen} onClose={handleClose} />
      <CaseUploadFileLimitModal isOpen={isFileLimitModalOpen} onClose={closeFileLimitModal} />
      <Snackbar
        isOpen={isSnackbarOpen}
        onClose={closeSnackbar}
        autoHideDuration={4000}
        message={`${lastRemovedFileName} 파일을 삭제했습니다.`}
        rightTrailingComponent={
          <button
            type="button"
            onClick={() => {
              restoreRemovedFile()
              closeSnackbar()
            }}
            className={cn('shrink-0 text-lds2-body2-medium text-brand-400')}
          >
            실행취소
          </button>
        }
      />
      <Snackbar
        isOpen={isVerifyingSuccessSnackbarOpen}
        onClose={closeVerifyingSuccessSnackbar}
        state="success"
        autoHideDuration={3000}
        message="변호사 인증이 완료되었습니다."
      />
      <Snackbar
        isOpen={isRegisterErrorSnackbarOpen}
        onClose={closeRegisterErrorSnackbar}
        state="error"
        autoHideDuration={3000}
        message="등록 요청에 실패하였습니다."
      />
    </>
  )
}
