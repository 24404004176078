import type { FocusEventHandler, MouseEventHandler } from 'react'

import { IconBell } from '@lbox/shared/components'
import { noop } from '@lbox/shared/utils'

import { Badge, IconOnlyButton } from '@lbox-kr/libra/v2'
import cn from 'classnames'

export interface NotificationWithBadgeIconProps {
  notificationsCount: number
  onClick: MouseEventHandler
  onMouseEnter: MouseEventHandler
  onFocus: FocusEventHandler
}

const NotificationWithBadgeIcon = ({
  notificationsCount = 0,
  onClick = noop,
  onMouseEnter,
  onFocus,
}: NotificationWithBadgeIconProps) => {
  return (
    <div className={cn('relative')}>
      <IconOnlyButton
        buttonType="ghost"
        icon={IconBell}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
      />
      {notificationsCount > 0 && (
        <Badge.Number value={notificationsCount} className={cn('absolute right-[-4px] top-[-4px]')} />
      )}
    </div>
  )
}

export default NotificationWithBadgeIcon
