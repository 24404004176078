import type { RefObject } from 'react'
import { useEffect } from 'react'

interface UseRegisterSearchBarFocusParams {
  searchKeywordRef: RefObject<HTMLInputElement>
}

const useRegisterSearchBarFocus = ({ searchKeywordRef }: UseRegisterSearchBarFocusParams) => {
  /**
   * 검색 창의 focus 가 out 된 상태에서,
   * a~z, A~Z, 0~9, 한글 자모의 입력이 발생하면 검색창으로 포커스를 이동시켜 준다
   */
  const registerSearchBarFocusHandler = () => {
    const keydownHandler = ($event: globalThis.KeyboardEvent) => {
      const { key, metaKey, altKey, ctrlKey, shiftKey } = $event
      const searchKeywordInputElement = searchKeywordRef.current

      const isSpecialKey = metaKey || altKey || ctrlKey || shiftKey

      const focusTriggeringKeys = /[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ]/

      const needsFocus =
        searchKeywordInputElement /** 검색창 input element 가 존재 */ &&
        document.activeElement === document.body /** 화면 상 focus 된 요소가 없어야 함 */ &&
        !isSpecialKey /** 특수 키가 같이 눌리지 않아야 함 */ &&
        key?.length === 1 /** 'a', '1' 과 같은 단일 키 값이어야 함 */ &&
        key?.match(focusTriggeringKeys)

      if (needsFocus) {
        searchKeywordInputElement.focus()
      }
    }

    window.addEventListener('keydown', keydownHandler)

    return () => {
      window.removeEventListener('keydown', keydownHandler)
    }
  }

  useEffect(() => {
    const unregister = registerSearchBarFocusHandler()

    return () => {
      unregister()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useRegisterSearchBarFocus
