import type { ChangeEvent } from 'react'
import { useState } from 'react'

import { Button, InputField, Modal, Spacing, TextAreaField, useSnackbar } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { SEARCH_BAR_Z_INDEX } from '../$constants/zIndex'
import { getSearchCommandRequestNotified } from '../../../apis/route-handler/fetchers'
import useMe from '../../../hooks/useMe'

interface SearchCommandRequestModalProps {
  isOpen: boolean
  onClose: VoidFunction
}

export const SearchCommandRequestModal = ({ isOpen, onClose }: SearchCommandRequestModalProps) => {
  const { email, name } = useMe()

  const [titleText, setTitleText] = useState('')
  const [descriptionText, setDescriptionText] = useState('')
  const [isRequestOnFlight, setIsRequestOnFlight] = useState(false)
  const snackbar = useSnackbar()

  function handleTitleTextInput($event: ChangeEvent<HTMLInputElement>) {
    const userInput = $event.target.value
    setTitleText(userInput)
  }

  function handleTextAreaInput($event: ChangeEvent<HTMLTextAreaElement>) {
    const userInput = $event.target.value
    setDescriptionText(userInput)
  }

  async function requestSearchCommand() {
    setIsRequestOnFlight(true)

    try {
      const { status } = await getSearchCommandRequestNotified({
        username: name ?? '',
        email: email ?? '',
        title: titleText,
        description: descriptionText,
      })

      if (status === 'success') {
        snackbar.success({ message: '검색 연산자 요청이 완료되었습니다.' })
        onClose()
      }
    } catch {
      snackbar.error({ message: '검색 연산자 요청이 실패하였습니다. 잠시 후 다시 시도해주세요.' })
    } finally {
      setIsRequestOnFlight(false)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      showsCloseButton
      backdropClassName={cn(`${SEARCH_BAR_Z_INDEX.searchCommandRequestModal}`)}
      rootClassName={cn(`${SEARCH_BAR_Z_INDEX.searchCommandRequestModal}`)}
      onClose={onClose}
    >
      <Modal.Headline>검색 연산자 요청</Modal.Headline>
      <Modal.Body>
        <InputField.Text
          placeholder="(필수) 연산자 종류 ex) OR"
          label="요청할 연산자*"
          value={titleText}
          onInput={handleTitleTextInput}
        />
        <Spacing size={24} />
        <TextAreaField
          placeholder="(선택) 원하는 연산자에 대한 설명 ex) A OR B 입력 시 A 또는 B 중 하나라도 포함된 검색 결과가 제공된다."
          label="추가 의견"
          textAreaClassName="h-[195px]"
          maxLength={200}
          value={descriptionText}
          onInput={handleTextAreaInput}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          label="보내기"
          buttonType="filled"
          color="lbox-black"
          width="full"
          isLoading={isRequestOnFlight}
          disabled={titleText.length === 0 || isRequestOnFlight}
          onClick={requestSearchCommand}
        />
      </Modal.Footer>
    </Modal>
  )
}
