import { isServer } from '@toss/utils'

import { isPoliceChannel } from './isPoliceChannel'

/**
 * 비영리단체 채널로 접속했는가
 * - URL에 non-profit 텍스트 포함 -> 비영리단체 URL로 접속한 경우
 * - URL이 lbox.kr로 끝나지 않음 -> 프록시 서버로 접속하는 경우
 */
export function isPublicChannel() {
  if (isServer()) {
    return false
  }

  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return false
  }

  // FIXME: non-profit.lbox.kr 이 public.lbox.kr 로 대체되고 나면 'non-profit' 관련 조건 지우기 (함수 주석도 업데이트 하기)
  const isPublicPlanDomain = hostname.includes('non-profit') || hostname.includes('public')
  const isUsingProxyServer = !hostname.endsWith('lbox.kr')

  return isPublicPlanDomain || isUsingProxyServer || isPoliceChannel()
}

export const isPublicChannelInServerSide = (host?: string) => {
  if (!host) {
    return false
  }

  if (host.includes('localhost')) {
    return false
  }

  const isPublicPlanDomain = host.includes('non-profit') || host.includes('public')
  const isUsingProxyServer = !host.endsWith('lbox.kr')
  const isPolice = host === 'police-non-profit.lbox.kr'

  return isPublicPlanDomain || isUsingProxyServer || isPolice
}
