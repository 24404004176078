import { useGetLawyerProfile } from './useGetLawyerProfile'
import useMe from '../../hooks/useMe'

export const useLawyerProfileOnboarding = () => {
  const user = useMe()
  const userId = user.isLoggedIn ? Number(user.userId) : 0
  const { data: lawyerProfile, isInitialLoading } = useGetLawyerProfile(
    { lawyerId: userId },
    { enabled: user.isJobLawyer, retry: false }
  )

  return {
    isInitialLoading: user.isLboxUserDataLoading || isInitialLoading,
    onboardingStep: lawyerProfile?.onboarding?.step,
    hasProfile: Boolean(lawyerProfile?.onboarding.step === 'DONE'),
  }
}
