import { isServer } from '@toss/utils'

/**
 * 경찰청 복지몰과 연결된 도메인인지 판단한다
 */
export const isPoliceChannel = () => {
  if (isServer()) {
    return false
  }

  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return false
  }

  return hostname === 'police-non-profit.lbox.kr' || hostname === 'police-public.lbox.kr'
}

/**
 * 비영리단체 채널로 접속했는가
 * - URL에 non-profit 텍스트 포함 -> 비영리단체 URL로 접속한 경우
 * - URL이 lbox.kr로 끝나지 않음 -> 프록시 서버로 접속하는 경우
 */
export function isPublicChannel() {
  if (isServer()) {
    return false
  }

  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return false
  }

  // FIXME: non-profit.lbox.kr 이 public.lbox.kr 로 대체되고 나면 'non-profit' 관련 조건 지우기 (함수 주석도 업데이트 하기)
  const isPublicPlanDomain = hostname.includes('non-profit') || hostname.includes('public')
  const isUsingProxyServer = !hostname.endsWith('lbox.kr')

  return isPublicPlanDomain || isUsingProxyServer || isPoliceChannel()
}
