import { IconDotsNine, IconMagnifyingGlass } from '@lbox/shared/components'

import { Button, IconOnlyButton, Tooltip } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { getTipAdjust } from '../../../$utils/getTipAdjust'
import ProfileButton from '../../profile-button/ProfileButton'
import type { MobileGuestButtonsProps } from '../types'

const MobileGuestButtons = (props: MobileGuestButtonsProps) => {
  const { type, onClickLogin, onClickProfileButton } = props

  if (type === 'type1') {
    return (
      <div className={cn('flex items-center gap-[8px]')}>
        <Tooltip description="검색창 열기" color="white" tipAdjust={getTipAdjust()} isPortalTooltip>
          <IconOnlyButton
            className={cn('flex')}
            buttonType="ghost"
            icon={IconMagnifyingGlass}
            onClick={props.onClickSearchButton}
          />
        </Tooltip>
        <ProfileButton isIconOnly onClick={onClickProfileButton} />
      </div>
    )
  }

  // NOTE: type2, type3
  return (
    <div className={cn('flex items-center gap-[8px]')}>
      <Button label="로그인" type="button" size="small" buttonType="filled" width="fit" onClick={onClickLogin} />

      <IconOnlyButton
        buttonType="ghost"
        icon={IconDotsNine}
        iconProps={{ weight: 'bold' }}
        onClick={onClickProfileButton}
      />
    </div>
  )
}

export default MobileGuestButtons
