import type { MouseEvent } from 'react'
import { useEffect, useState } from 'react'

import { SEARCH_LOG_LOCAL_STORAGE_KEY } from '@lbox/shared/constants'
import { webLocalStorage } from '@lbox/shared/utils'

import type { UseSearchDataListParams } from '../$types/searchDataList'
import { useGetAvailableFastSearchCommandsByURL } from '../../$hooks/useAvailableFastSearchCommands'
import useSearchHistories from '../../$hooks/useSearchHistories'
import type { CommandAll } from '../../$types/command'
import type { History, SearchedJudgeItem } from '../../$types/histories'
import { getAmplitudePageType } from '../../$utils/getAmplitudePageType'
import { getCommandStates } from '../../$utils/getCommandStates'
import { hasSameHistoryByQuery } from '../../$utils/histories'
import { getJudgeList } from '../../../../apis/header'
import { FAST_SEARCH_COMMAND_VALUES } from '../../../../constants/#header'
import type { FastSearchCommandValue } from '../../../../types/#header'
import type { Judge } from '../../../../types/judge'
import { getFilteredFastSearchCommands } from '../../../../utils/#header'

const useSearchDataList = ({
  query,
  cursorOffset,
  onSearch,
  onSearchJudge,
  onClickTag,
  onFireUserEvent,
}: UseSearchDataListParams) => {
  const [filteredFastSearchCommands, setFilteredFastSearchCommands] = useState<FastSearchCommandValue[]>([])
  const [typingFastSearchCommand, setTypingFastSearchCommand] = useState<CommandAll | null>(null)
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [searchedJudges, setSearchedJudges] = useState<Judge[]>([])

  const { histories, removeHistory } = useSearchHistories()

  const availableFastSearchCommands = useGetAvailableFastSearchCommandsByURL()

  const recentHistories = histories
    .reduce<History[]>((prev, history) => {
      /** 쿼리 문자열이 입력된 경우 쿼리 문자열과 합치되지 않는 히스토리는 들어가지 않는다. */
      if (query.trim().length !== 0 && !hasSameHistoryByQuery(history, query)) {
        return prev
      }

      return [...prev, history]
    }, [])
    .slice(0, 5)

  const showsFastSearchCommandGuide = typingFastSearchCommand !== null && typingFastSearchCommand !== '사건번호'
  const showsFastSearchCommandResult = filteredFastSearchCommands.length > 0 || showsFastSearchCommandGuide
  const showsRecentHistoryResult = recentHistories.length > 0
  const showsSuggestionsResult = suggestions.length > 0
  const showsSearchedJudgesResult = searchedJudges.length > 0

  useEffect(() => {
    if (query.length === 0) {
      setFilteredFastSearchCommands(FAST_SEARCH_COMMAND_VALUES)
      setTypingFastSearchCommand(null)
      setSuggestions([])
      setSearchedJudges([])

      return
    }

    ;(async () => {
      const judges = await getJudgeList(query)
      setSearchedJudges(judges)
    })()

    setFilteredFastSearchCommands(getFilteredFastSearchCommands(query) ?? [])
    const commandStates = getCommandStates(query)
    const typingFastSearchCommand = commandStates.find(
      (state) => state.startIndex <= cursorOffset && cursorOffset <= state.endIndex
    )?.commandType
    setTypingFastSearchCommand(typingFastSearchCommand ?? null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  // handler
  const handleClickHistory = (history: History) => {
    onSearch(history)
  }

  const handleClickHistoryCloseIcon = (e: MouseEvent<HTMLButtonElement>, selectedHistory: History) => {
    e.stopPropagation()
    removeHistory(selectedHistory)
  }

  const handleClickSuggestion = (suggestion: string) => {
    onSearch(suggestion)
  }

  const handleClickFastSearchCommandTag = (command: string) => {
    onClickTag(command)
  }

  const handleClickSearchedJudgeItem = (searchedJudgeItem: SearchedJudgeItem) => {
    const searchSessionId = webLocalStorage.getItem<string | null>(SEARCH_LOG_LOCAL_STORAGE_KEY.SEARCH_SESSION_ID, null)

    onFireUserEvent('click_search', {
      keyword: query,
      page: getAmplitudePageType(window.location.pathname),
      judge: `${searchedJudgeItem.name}_${searchedJudgeItem.serial}`,
      isLeafEvent: true,
      search_session_id: searchSessionId,
    })

    onSearchJudge(searchedJudgeItem)
  }

  return {
    filteredFastSearchCommands,
    typingFastSearchCommand,
    searchedJudges,
    suggestions,
    recentHistories,
    availableFastSearchCommands,
    showsFastSearchCommandGuide,
    showsFastSearchCommandResult,
    showsRecentHistoryResult,
    showsSuggestionsResult,
    showsSearchedJudgesResult,
    handleClickHistory,
    handleClickHistoryCloseIcon,
    handleClickSuggestion,
    handleClickFastSearchCommandTag,
    handleClickSearchedJudgeItem,
  }
}

export default useSearchDataList
