import type { Ref } from 'react'
import { forwardRef, useEffect } from 'react'

import { useDebouncedValue, useMediaQuery } from '@lbox/shared/hooks'

import { CustomScrollBar } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import useSearchDataList from '../$hooks/useSearchDataList'
import type { SearchBarDataListProps } from '../$types/searchDataList'
import { SEARCH_BAR_Z_INDEX } from '../../$constants/zIndex'
import FastSearchCommands from '../fast-search-commands/FastSearchCommands'
import RecentHistories from '../recent-histories/RecentHistories'
import Suggestions from '../suggestions/Suggestions'

/**
 * 검색어 입력에 따라 다양한 보조 정보를 표시하는 데이터 목록
 * - 빠른 검색 명령어, 최근 검색한 항목, 추천 검색어, 판사 순으로 표시
 * - 모바일에서는 바텀시트에 검색바와 함꼐 위치
 * - 태블릿/데스크탑에서는 검색바 아래쪽에 노출되는 팝오버 안에 위치
 */
const SearchBarDataList = forwardRef(function SearchBarDataList(
  {
    query,
    cursorOffset,
    size,
    hasSearchBarData,
    setHasSearchBarData,
    onSearch,
    onSearchJudge,
    onClickTag,
    onFireUserEvent,
  }: SearchBarDataListProps,
  ref: Ref<HTMLDivElement>
) {
  const { isTabletOrDesktop } = useMediaQuery()

  const debouncedQuery = useDebouncedValue(query, 300)

  const {
    filteredFastSearchCommands,
    typingFastSearchCommand,
    searchedJudges,
    suggestions,
    recentHistories,
    availableFastSearchCommands,
    showsFastSearchCommandGuide,
    showsSuggestionsResult,
    showsSearchedJudgesResult,
    handleClickHistory,
    handleClickHistoryCloseIcon,
    handleClickSuggestion,
    handleClickFastSearchCommandTag,
    handleClickSearchedJudgeItem,
  } = useSearchDataList({ query: debouncedQuery, cursorOffset, onSearch, onSearchJudge, onClickTag, onFireUserEvent })

  useEffect(() => {
    if (
      filteredFastSearchCommands.length === 0 &&
      !showsFastSearchCommandGuide &&
      recentHistories.length === 0 &&
      suggestions.length === 0
    ) {
      setHasSearchBarData(false)
      return
    }

    setHasSearchBarData(true)
  }, [
    filteredFastSearchCommands.length,
    recentHistories.length,
    setHasSearchBarData,
    showsFastSearchCommandGuide,
    suggestions.length,
  ])

  if (!hasSearchBarData && size === 'l') {
    return (
      <div
        className={cn(
          'absolute',
          'bg-white',
          'h-[16px] w-full',
          'rounded-bl-[16px] rounded-br-[16px] border-b border-l border-r border-zinc-200',
          'z-main-page-search-data-popover'
        )}
      />
    )
  }

  return (
    <div
      ref={ref}
      className={cn(
        `${SEARCH_BAR_Z_INDEX.searchBarDataList}`,
        'bg-white',
        'tablet:shadow-lg tablet:no-scrollbar',
        'w-full',
        'absolute top-[100%]',
        hasSearchBarData
          ? ['rounded-bl-[16px] rounded-br-[16px] border-b border-l border-r border-solid border-zinc-200']
          : 'hidden',
        {
          'max-w-[800px] min-[1430px]:max-w-[628px]': size === 'm',
        }
      )}
    >
      <CustomScrollBar
        className={cn({
          'p-[16px_14px_12px]': size === 'm',
          'p-[16px_30px_25px]': size === 'l',
        })}
        style={{ maxHeight: isTabletOrDesktop ? 500 : '100%' }}
        autoHide
      >
        <div className={cn('flex flex-col gap-y-[32px]')}>
          {/* 빠른 검색 명령어 */}
          {(filteredFastSearchCommands.length > 0 || showsFastSearchCommandGuide) && (
            <FastSearchCommands
              filteredFastSearchCommands={filteredFastSearchCommands}
              showsFastSearchCommandGuide={showsFastSearchCommandGuide}
              typingFastSearchCommand={typingFastSearchCommand}
              handleClickFastSearchCommandTag={handleClickFastSearchCommandTag}
            />
          )}

          {/* 최근 검색한 항목 */}
          {recentHistories.length > 0 && (
            <RecentHistories
              recentHistories={recentHistories}
              handleClickHistoryCloseIcon={handleClickHistoryCloseIcon}
              handleClickSearchedJudgeItem={handleClickSearchedJudgeItem}
              handleClickHistory={handleClickHistory}
            />
          )}

          {/* 추천 검색어 */}
          {!showsFastSearchCommandGuide && (showsSuggestionsResult || showsSearchedJudgesResult) && (
            <Suggestions
              searchedJudges={searchedJudges}
              suggestions={suggestions}
              availableFastSearchCommands={availableFastSearchCommands}
              showsSearchedJudgesResult={showsSearchedJudgesResult}
              showsSuggestionsResult={showsSuggestionsResult}
              handleClickSuggestion={handleClickSuggestion}
              handleClickSearchedJudgeItem={handleClickSearchedJudgeItem}
            />
          )}
        </div>
      </CustomScrollBar>
    </div>
  )
})

export default SearchBarDataList
