import type { PropsWithChildren } from 'react'
import { useRef } from 'react'

import { Spacing } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import type { FileUploadAreaHandle, FileUploadAreaProps } from './FileUploadArea'
import { FileUploadArea } from './FileUploadArea'

export type CaseUploadAreaProps = Omit<FileUploadAreaProps, 'className'>

export const CaseUploadArea = ({
  accept,
  onDrop,
  onAccepted,
  onRejected,
  children,
}: PropsWithChildren<FileUploadAreaProps>) => {
  const fileUploadAreaRef = useRef<FileUploadAreaHandle>(null)

  return (
    <FileUploadArea
      ref={fileUploadAreaRef}
      rootClassName={({ isDragActive }) =>
        cn(
          'flex flex-col items-center justify-center rounded-[4px] border  p-[40px_24px]',
          { 'border-zinc-500/50 bg-zinc-50/50 border-dashed': !isDragActive },
          { 'border-lbox-brand-600 bg-lbox-brand-50 shadow-[0_0_0_1px_#2563EB_inset]': isDragActive }
        )
      }
      accept={accept}
      onDrop={onDrop}
      onAccepted={onAccepted}
      onRejected={onRejected}
    >
      <>
        <div className={cn('flex flex-col items-center')}>{children}</div>
        <Spacing size={20} />
        <button
          type="button"
          onClick={() => fileUploadAreaRef.current?.openFileExplorer?.()}
          className={cn('text-lds2-body3-regular-underline text-zinc-500 underline')}
        >
          파일 선택
        </button>
      </>
    </FileUploadArea>
  )
}
