import type { Size } from '../$types/searchBar'

const COMMON_STYLE = {
  s: ['flex items-center', 'px-[14px]', 'max-w-[800px] min-[1430px]:max-w-[628px]'],
  l: ['flex items-center', 'w-[700px] px-[30px]'],
}

export const getConditionalSearchBarStyle = ({
  size,
  isSearchDataPopoverOpen,
  hasSearchBarData,
}: {
  size: Size
  isSearchDataPopoverOpen: boolean
  hasSearchBarData: boolean
}) => {
  if (size === 'l') {
    if (!isSearchDataPopoverOpen) {
      return [
        ...COMMON_STYLE.l,
        'rounded-full border border-solid border-brand-600',
        'p-[16px]',
        'h-[84px]',
        'hover:shadow-md',
      ]
    }

    return [
      ...COMMON_STYLE.l,
      'rounded-tl-[16px] rounded-tr-[16px] border-l border-r border-t border-solid border-zinc-200',
      'h-[74px]',
      'p-[25px_30px_16px]',
      'shadow-lg',
    ]
  }

  // NOTE: size === 's' || size === 'm'
  if (!isSearchDataPopoverOpen) {
    return [
      ...COMMON_STYLE.s,
      'rounded-full border border-solid border-zinc-200',
      'p-[16px]',
      // mobile인 경우 searchbar의 height를 40px로 고정
      size === 'm' ? 'h-[48px]' : 'h-[40px]',
      'hover:shadow-md',
    ]
  }

  if (!hasSearchBarData) {
    return [
      ...COMMON_STYLE.s,
      'rounded-[16px] border-l border-r border-t border-solid border-zinc-200',
      // mobile인 경우 searchbar의 height를 40px로 고정
      size === 'm' ? 'h-[48px]' : 'h-[40px]',
      'shadow-lg',
    ]
  }

  return [
    ...COMMON_STYLE.s,
    'rounded-tl-[16px] rounded-tr-[16px] border-l border-r border-t border-solid border-zinc-200',
    // mobile인 경우 searchbar의 height를 40px로 고정
    size === 'm' ? 'h-[48px]' : 'h-[40px]',
    'shadow-lg',
  ]
}
