import { useQuery } from '@tanstack/react-query'

import { getUserHasPlan } from '../../apis/user/getUserHasPlan'
import { API_ENDPOINT } from '../../constants/api/endpoint'
import type { UserHasPlan } from '../../types/user/userHasPlan'

export const useGetUserHasPlan = () => {
  return useQuery({
    queryKey: [API_ENDPOINT.userHasPlan.base],
    queryFn: getUserHasPlan,
    select: (response) => {
      const planName = response?.planName

      return {
        data: response ?? ({} as UserHasPlan),
        userPlanDisplayName: getUserPlanDisplayName(planName),
      }
    },
  })
}

const getUserPlanDisplayName = (planName: string) => {
  if (!planName) {
    return '무료 이용 중'
  }

  if (planName.includes('스탠다드')) {
    return '스탠다드'
  }

  if (planName.includes('비즈니스')) {
    return '비즈니스'
  }

  if (planName.includes('비영리단체')) {
    return '퍼블릭'
  }

  return planName.replace('플랜', '')
}
