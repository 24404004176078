import { LBOX_PAGE_PATH } from '@lbox/shared/constants'

import { isClient } from '@toss/utils'

import { COMMANDS_ALL } from '../$constants/commandsWithAt'
import type { CommandAll } from '../$types/command'

/** 허용하는 빠른검색명령어 목록(없으면 전체 허용) */
export function useGetAvailableFastSearchCommandsByURL(): CommandAll[] {
  const pathname = isClient() ? window.location.pathname : ''
  const pathnameWithoutPrefixV2 = pathname.replace(/\/v2/g, '')

  const only완전일치and제외어AvailablePathnames: string[] = [
    LBOX_PAGE_PATH.search.statute, // 검색결과 > 법령
    LBOX_PAGE_PATH.search.commentary, // 검색결과 > 주석서
    LBOX_PAGE_PATH.search.reference, // 검색결과 > 논문
    LBOX_PAGE_PATH.search.trialDecision, // 검색결과 > 결정례
    LBOX_PAGE_PATH.search.news, // 검색결과 > 뉴스
    LBOX_PAGE_PATH.search.interpretation, // 검색결과 > 유권해석
  ]

  // router.pathname에는 "v2" prefix가 포함되지 않음
  if (only완전일치and제외어AvailablePathnames.includes(pathnameWithoutPrefixV2)) {
    return ['완전일치', '제외어']
  }

  return COMMANDS_ALL
}
