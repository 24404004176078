import { IconFileText } from '@lbox/shared/components'
import type { StrictPropsWithChildren } from '@lbox/shared/types'

import cn from 'classnames'

interface CaseListProps {
  className?: string
}

export const CaseList = ({ children, className }: StrictPropsWithChildren<CaseListProps>) => {
  return (
    <ul
      className={cn('rounded-[4px] border-[0.5px] border-solid border-zinc-400', 'flex flex-col py-[12px]', className)}
    >
      {children}
    </ul>
  )
}

interface CaseListItemProps {
  caseId: string
  actions?: React.ReactNode
}

CaseList.Item = function CaseListItem({ caseId, actions }: CaseListItemProps) {
  return (
    <li className={cn('flex items-start justify-between gap-x-[6px] p-[8px_16px] hover:bg-state-layer-on-light-01')}>
      <span className={cn('flex gap-x-[6px] overflow-hidden')}>
        <IconFileText size={20} className={cn('flex-shrink-0')} />
        <span className={cn('line-clamp-2 break-words text-lds2-body3-regular text-zinc-900')}>{caseId}</span>
      </span>
      <span className={cn('flex-shrink-0')}>{actions}</span>
    </li>
  )
}
