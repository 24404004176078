import { externalUrl } from '@lbox/shared/constants'

import cn from 'classnames'

interface LBoxLogoProps {
  className?: string
}

/**
 * 2024년 4월 리브랜딩한 LBox 로고 이미지
 */
export const LBoxLogo = ({ className }: LBoxLogoProps) => {
  return (
    <img
      className={cn('h-auto w-[70px] min-w-[70px] lds2-tablet:w-[90px] lds2-tablet:min-w-[90px]', className)}
      src={`${externalUrl.staticAssetsCloudFrontUrl}/images/lbox/service/lbox-re-brand-logo.webp`}
      alt="LBOX"
    />
  )
}
