import { SwitchCase } from '@toss/react'

import { useGetTargetUser, useIsParticipating, useParticipateAiBoosting } from './api'
import ChallengeGradientText from './ChallengeGradientText'
import openSingleDrawer from './openSingleDrawer'
import RenewChallengeDrawer from './RenewChallengeDrawer'
import StatusChallengeDrawer from './StatusChallengeDrawer'
import WelcomeChallengeDrawer from './WelcomeChallengeDrawer'

export default function ChallengeStatusButton() {
  const getQuery = useGetTargetUser()
  const participateMutation = useParticipateAiBoosting()
  const isParticipating = useIsParticipating()

  const status =
    !getQuery.data?.data || getQuery.data?.data.excluded
      ? '대상아님'
      : getQuery.data?.data.participated
        ? '참여중'
        : '참여대기중'

  return (
    <SwitchCase
      value={status}
      caseBy={{
        참여중: (
          <button
            type="button"
            className="w-full rounded-lg bg-background-secondary p-2 text-lds2-body2-medium text-text-secondary interaction-state-low"
            onClick={() => {
              const isRenew = getQuery.data?.data?.monthlyPoint === 0 && getQuery.data?.data?.totalPoint > 0
              openSingleDrawer(({ isOpen, close, unmount }) =>
                isRenew ? (
                  <RenewChallengeDrawer isOpen={isOpen} onClose={close} onCloseComplete={unmount} />
                ) : (
                  <StatusChallengeDrawer isOpen={isOpen} onClose={close} onCloseComplete={unmount} />
                )
              )
            }}
          >
            <ChallengeGradientText>AI 꾸준히 챌린지 진행 중</ChallengeGradientText>
          </button>
        ),
        참여대기중: (
          <button
            type="button"
            className="w-full rounded-lg bg-background-secondary p-2 text-lds2-body2-medium text-text-secondary interaction-state-low disabled:opacity-50"
            disabled={isParticipating}
            onClick={async () => {
              await participateMutation.mutateAsync()
              openSingleDrawer(({ isOpen, close, unmount }) => (
                <WelcomeChallengeDrawer isOpen={isOpen} onClose={close} onCloseComplete={unmount} />
              ))
            }}
          >
            엘박스 AI에 질문하고
            <br />
            <ChallengeGradientText>20,000포인트</ChallengeGradientText> 챙겨가세요
          </button>
        ),
      }}
    />
  )
}
