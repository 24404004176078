import type { CommandAll, CommandWithAt } from '../$types/command'

export const COMMANDS_WITH_AT: CommandWithAt[] = [
  '사건명',
  '주문',
  '법조문',
  // '법무법인',
  '당사자명',
  '사건부호',
  '판결요지',
  '청구취지',
  '신청취지',
]

export const COMMANDS_ALL: CommandAll[] = [...COMMANDS_WITH_AT, '완전일치', '제외어', '사건번호']
