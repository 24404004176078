import type { MouseEventHandler } from 'react'

import { Avatar } from '@lbox-kr/libra/v2'
import cn from 'classnames'

import { useGetUserHasPlan } from '../../../../hooks/user/useGetUserHasPlan'

interface ProfileButtonCommon {
  onClick?: MouseEventHandler
}

export type ProfileButtonProps = ProfileButtonCommon &
  (
    | {
        userName?: never
        isIconOnly?: true
      }
    | {
        userName?: string
        isIconOnly?: never
      }
  )

const ProfileButton = ({ userName, isIconOnly, onClick }: ProfileButtonProps) => {
  const { data: { userPlanDisplayName } = {} } = useGetUserHasPlan()

  return (
    <button
      type="button"
      className={cn('flex items-center', 'h-[48px] w-max', 'px-[8px] py-[4px]', 'rounded-[4px]', 'hover-state')}
      onClick={onClick}
    >
      <Avatar type="placeholder" size={32} />
      {!isIconOnly && (
        <p className={cn('flex flex-col', 'ml-[8px]', 'min-w-[42px]')}>
          <span className={cn('text-left text-lds2-body3-medium text-zinc-900')}>{userName}</span>
          <span className={cn('text-left text-lds2-caption1-regular text-zinc-600')}>{userPlanDisplayName}</span>
        </p>
      )}
    </button>
  )
}

export default ProfileButton
