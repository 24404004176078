import type { UseQueryOptions } from '@tanstack/react-query'
import { useMutation, useQuery } from '@tanstack/react-query'
import type { AxiosError } from 'axios'

import { POST_USER_ARARM_LIST_DEFAULT_PAGE, POST_USER_ARARM_LIST_DEFAULT_SIZE } from './constants'
import { USER_ALARM_API_ENDPOINT } from './endpoints'
import { getUserAlarmCount, postUserAlarmList, postUserAlarmRead } from './fetchers'
import type { UsePostUserAlarmListParams, UserAlarm } from './types'
import type { PageableData } from '../../types/common'
import { getHasLoginToken } from '../../utils/getHasLoginToken'

export const USER_ALARM_QUERY_KEYS = {
  base: [USER_ALARM_API_ENDPOINT.base],
  count: () => [...USER_ALARM_QUERY_KEYS.base, USER_ALARM_API_ENDPOINT.count],
  list: ({ types, page, size }: UsePostUserAlarmListParams) => [
    ...USER_ALARM_QUERY_KEYS.base,
    USER_ALARM_API_ENDPOINT.list,
    page,
    size,
    ...types,
  ],
  read: () => [...USER_ALARM_QUERY_KEYS.base, USER_ALARM_API_ENDPOINT.read],
}

export const useGetUserAlarmCount = () => {
  const hasLoginToken = getHasLoginToken()

  return useQuery<number>({
    queryKey: USER_ALARM_QUERY_KEYS.count(),
    queryFn: getUserAlarmCount,
    enabled: hasLoginToken,
  })
}

export const usePostUserAlarmList = (
  {
    types,
    page = POST_USER_ARARM_LIST_DEFAULT_PAGE,
    size = POST_USER_ARARM_LIST_DEFAULT_SIZE,
  }: UsePostUserAlarmListParams,

  options?: Omit<UseQueryOptions<PageableData<UserAlarm[]>, AxiosError>, 'queryKey' | 'queryFn'>
) => {
  const hasLoginToken = getHasLoginToken()

  return useQuery({
    queryKey: USER_ALARM_QUERY_KEYS.list({ types, page, size }),
    queryFn: () => postUserAlarmList({ types, page, size }),
    enabled: hasLoginToken,
    ...options,
  })
}

export const usePostUserAlarmRead = () => {
  return useMutation({ mutationKey: USER_ALARM_QUERY_KEYS.read(), mutationFn: postUserAlarmRead })
}
