import type { TabItems } from '@lbox-kr/libra/v1'

import { isRestrictedUser } from '../../utils/user/checkIsRestrictedUser'

export const FAST_SEARCH_COMMANDS_WITH_AT = {
  사건명: '@사건명',
  주문: '@주문',
  법조문: '@법조문',
  // 법무법인: '@법무법인',
  당사자명: '@당사자명',
  사건부호: '@사건부호',
  판결요지: '@판결요지',
  청구취지: '@청구취지',
  신청취지: '@신청취지',
} as const

export const FAST_SEARCH_COMMANDS = {
  ...FAST_SEARCH_COMMANDS_WITH_AT,
  완전일치: '"완전일치"',
  제외어: '-제외어',
} as const

export const FAST_SEARCH_COMMAND_VALUES_WITH_AT = Object.values(FAST_SEARCH_COMMANDS_WITH_AT)
export const FAST_SEARCH_COMMAND_VALUES = Object.values(FAST_SEARCH_COMMANDS)

export const NOTIFICATION_CATEGORIES: TabItems = isRestrictedUser()
  ? [
      { label: '전체', value: '전체' },
      { label: '사건일정관리', value: '사건일정관리' },
      { label: '검색조건 알림', value: '검색조건 알림' },
      { label: '내 복대리', value: '내 복대리' },
      { label: '판결문 요청', value: '판례요청' },
      { label: '기타', value: '기타' },
    ]
  : [
      { label: '전체', value: '전체' },
      { label: '사건일정관리', value: '사건일정관리' },
      { label: '검색조건 알림', value: '검색조건 알림' },
      { label: '내 복대리', value: '내 복대리' },
      { label: '내 판결문 등록', value: '내 판례등록' },
      { label: '판결문 요청', value: '판례요청' },
      { label: '기타', value: '기타' },
    ]

export const MAPPING_NOTIFICATION_CATEGORY_VALUE_TO_TYPES: Record<string, string[]> = {
  // TODO: Heedo - 멤버심 알림 카테고리가 없음 ('MEMBERSHIP')
  전체: [],
  사건일정관리: ['caseManage'],
  '검색조건 알림': ['caseSubscribe'],
  '내 복대리': ['SUBSTITUTE'],
  '내 판례등록': ['myCaseRegister'],
  판례요청: ['requestCase', 'caseReport'],
  기타: ['notice'],
}
