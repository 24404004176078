import { isObjectType, isStringType, webLocalStorage } from '@lbox/shared/utils'

import type { History, SearchedJudgeItem } from '../$types/histories'
import { LOCAL_STORAGE_KEY_SEARCH_HISTORY } from '../../../constants/localStorage'

/**
 * "최근 검색한 항목" 조회
 */
export function getGuestHistories(): string[] {
  const histories = webLocalStorage.getArrayItem<string>(LOCAL_STORAGE_KEY_SEARCH_HISTORY)
  return histories
}

/**
 * "최근 검색한 항목" 로컬 스토리지에 키워드 추가
 * - 마지막에 추가한 키워드가 맨 위로 와야 함
 * - 만약 기존에 존재하는 키워드면 제거하고 맨 위에 새로 추가
 */
export function addGuestHistory(searchKeyword: History) {
  if (isStringType(searchKeyword)) {
    searchKeyword = searchKeyword.trim()
  }
  const histories = webLocalStorage.getArrayItem<History>(LOCAL_STORAGE_KEY_SEARCH_HISTORY)
  const filteredHistories = histories.filter((history) => !isSameHistory(history, searchKeyword))
  const unshiftedHistories = [searchKeyword, ...filteredHistories]

  webLocalStorage.setItem(LOCAL_STORAGE_KEY_SEARCH_HISTORY, unshiftedHistories)
}

/**
 * 선택한 키워드를 "최근 검색한 항목" 로컬 스토리지에서 제거
 */
export function removeGuestHistory(selectedHistory: History) {
  const histories = webLocalStorage.getArrayItem<History>(LOCAL_STORAGE_KEY_SEARCH_HISTORY)
  const filteredHistories = histories.filter((history) => !isSameHistory(history, selectedHistory))

  webLocalStorage.setItem(LOCAL_STORAGE_KEY_SEARCH_HISTORY, filteredHistories)
}

/**
 * 판사 정보를 포함한 "최근 검색한 항목"에서 선택된 History로 필터링
 */
export function isSameHistory(history: History, selectedHistory: History) {
  if (isStringType(history) && isStringType(selectedHistory)) {
    return history === selectedHistory
  }

  if (isObjectType(history) && isObjectType(selectedHistory)) {
    return history.id === selectedHistory.id
  }

  return false
}

/**
 * 판사 정보를 포함한 "최근 검색한 항목"에서 검색 문자열로 필터링
 */
export function hasSameHistoryByQuery(history: History, query: string) {
  if (isStringType(history)) {
    return history.includes(query)
  }

  // NOTE: 현재, 타입 가드 이후의 최근 검색에는 판사 정보만 들어있어서 name으로 필터링
  return history.name.includes(query)
}

/**
 * 검색된 query를 가공해주는 함수
 */
export function processQuery(query: History) {
  if (isStringType(query)) {
    // eslint-disable-next-line no-control-regex
    const BACKSPACE_REGEX = /\x08/g

    return query.replace(BACKSPACE_REGEX, '')
  }

  return query.name
}

export const isSearchedJudgeItem = (args: unknown): args is SearchedJudgeItem => {
  return (
    isObjectType(args) &&
    'id' in args &&
    'name' in args &&
    'serial' in args &&
    'retired' in args &&
    'court' in args &&
    'bar_type' in args
  )
}
