import type { Ref } from 'react'
import { forwardRef } from 'react'

import cn from 'classnames'

import { twMergeLDS } from '../../../utils/twMergeLDS'

export interface BadgeNumberProps {
  value: number | string
  className?: string
}

const BadgeNumber = forwardRef(function BadgeNumber({ value, className }: BadgeNumberProps, ref: Ref<HTMLDivElement>) {
  return (
    <div
      ref={ref}
      className={twMergeLDS(
        cn(
          'inline-flex items-center justify-center',
          'h-[18px] w-fit min-w-[18px] max-w-fit rounded-full px-[5px]',
          'border-[0.5px] border-red-700 bg-red-600',
          'text-lds2-caption1-medium text-white'
        ),
        className
      )}
    >
      {value}
    </div>
  )
})

export interface BadgeDotProps {
  className?: string
}

const BadgeDot = forwardRef(function BadgeDot({ className }: BadgeDotProps, ref: Ref<HTMLDivElement>) {
  return <div ref={ref} className={twMergeLDS(cn('h-[10px] w-[10px] rounded-full bg-red-600'), className)} />
})

export const Badge = {
  Number: BadgeNumber,
  Dot: BadgeDot,
}
