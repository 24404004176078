import type { PopoverProps } from '@lbox-kr/libra/v2'
import { Popover } from '@lbox-kr/libra/v2'
import { useBooleanState } from '@toss/react'
import cn from 'classnames'

import { SEARCH_BAR_Z_INDEX } from '../$constants/zIndex'
import { SearchCommandRequestModal } from '../search-command-request-modal/SearchCommandRequestModal'
import { SearchCommandGuideContent } from './search-command-guide-content/SearchCommandGuideContent'

interface SearchCommandGuidePopoverProps {
  isOpen: boolean
  isMobile?: boolean
  renderTarget: PopoverProps['renderTarget']
  closePopover: VoidFunction
}

export const SearchCommandGuidePopover = ({
  isOpen,
  isMobile,
  renderTarget,
  closePopover,
}: SearchCommandGuidePopoverProps) => {
  const [isSearchCommandRequestModalOpen, openSearchCommandRequestModal, closeSearchCommandRequestModal] =
    useBooleanState(false)

  return (
    <>
      {/* NOTE: mobile translate-y = 통합검색 영역(64px) + 검색창(55px) + Spacing(36px) + 검색 가이드 버튼(30px) */}
      <Popover
        isOpen={isOpen}
        backdropClassName={cn(`${SEARCH_BAR_Z_INDEX.searchCommandGuidePopover}`)}
        body={
          <SearchCommandGuideContent
            isMobile={isMobile}
            onClickSearchCommandRequestButton={openSearchCommandRequestModal}
            onClickCloseButton={closePopover}
          />
        }
        rootClassName={cn(
          'shadow-[0px_2px_4px_-1px_rgba(0,0,0,0.06),0px_4px_6px_-1px_rgba(0,0,0,0.10)]',
          `${SEARCH_BAR_Z_INDEX.searchCommandGuidePopover}`,
          {
            'w-[565px]': !isMobile,
            'w-[calc(100%-32px)] translate-x-[16px] translate-y-[185px] h-auto max-h-[calc(var(--custom-vh,1vh)*100-120px)]':
              isMobile,
          }
        )}
        bodyClassName={cn('p-[16px]')}
        renderTarget={renderTarget}
        justify="right"
        onClickBackdrop={closePopover}
      />

      {/* 검색식 요청 Modal */}
      {isSearchCommandRequestModalOpen && (
        <SearchCommandRequestModal isOpen={isSearchCommandRequestModalOpen} onClose={closeSearchCommandRequestModal} />
      )}
    </>
  )
}
