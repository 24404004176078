import { V2_PREFIX } from '@lbox/shared/constants'

import { TIP_ADJUST } from '@lbox-kr/libra/v2'
import { isServer } from '@toss/utils'

/**
 * url pathname에 맞게 TIP_ADJUST 값을 반환하는 함수
 *
 * react app -> 1
 * angular app -> -4 (angular app에서 툴팁 꼬리 위치 이슈)
 */
export const getTipAdjust = () => {
  if (isServer()) {
    return TIP_ADJUST.default
  }

  const { pathname } = window.location
  const isReactApp = pathname.startsWith(`/${V2_PREFIX}/`)

  if (isReactApp) {
    return TIP_ADJUST.default
  }

  return TIP_ADJUST.angular
}
