import cn from 'classnames'

export type ItemType = 'underline' | 'chip'
export type TabItems = readonly TabItem[]

export interface TabItem {
  label: string
  value: string
}

export interface TabsProps {
  type: ItemType
  items: TabItems
  value: TabItem
  gap?: number
  onChangeTab?: (selectedTabItem: TabItem) => unknown
}

export interface TabItemProps {
  item: TabItem
  isActive: boolean
  onChangeTab?: TabsProps['onChangeTab']
}

export const Tabs = ({ type, items, value, gap = 24, onChangeTab }: TabsProps) => {
  function getTabItemComponent(itemType: ItemType) {
    switch (itemType) {
      case 'underline':
        return TabItemUnderline
      case 'chip':
        return TabItemChip
      default:
        return TabItemUnderline
    }
  }

  function renderTabItems() {
    if (!items) {
      return null
    }

    return items.map((item, index) => {
      const ItemComponent = getTabItemComponent(type)
      const isActive = item.value === value.value
      return <ItemComponent key={index} item={item} isActive={isActive} onChangeTab={onChangeTab} />
    })
  }

  return (
    <div className="flex" style={{ gap: `${gap}px` }}>
      {renderTabItems()}
    </div>
  )
}

const TabItemUnderline = ({ item, isActive, onChangeTab }: TabItemProps) => {
  function handleClickTabItem() {
    if (typeof onChangeTab === 'function') {
      onChangeTab(item)
    }
  }

  return (
    <div
      className={cn(
        'cursor-pointer',
        'pb-[12px]',
        'whitespace-nowrap',
        'flex select-none justify-center border-b-4 text-[14px] leading-[20px]',
        'tablet:block tablet:text-[16px] tablet:leading-[23px]',
        {
          'border-zinc-700 text-zinc-700': isActive,
          'border-transparent text-zinc-400': !isActive,
        }
      )}
      role="button"
      onClick={handleClickTabItem}
    >
      {item.label}
    </div>
  )
}

const TabItemChip = ({ item, isActive, onChangeTab }: TabItemProps) => {
  function handleClickTabItem() {
    if (typeof onChangeTab === 'function') {
      onChangeTab(item)
    }
  }

  return (
    <div
      className={cn(
        'flex items-center',
        'px-4 py-2',
        'text-[16px] font-medium leading-[23px]',
        'rounded-[30px]',
        'cursor-pointer',
        'whitespace-nowrap',
        {
          'bg-zinc-700 text-white': isActive,
          'border border-zinc-200 text-zinc-900': !isActive,
        }
      )}
      role="button"
      onClick={handleClickTabItem}
    >
      {item.label}
    </div>
  )
}
