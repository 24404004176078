import { FAST_SEARCH_COMMAND_VALUES } from '../../constants/#header'
import type { FastSearchCommandValue } from '../../types/#header'
import { escapeRegExp } from '../../utils/regex'

/**
 * 검색바 팝오버에 표시할 빠른 검색 명령어 목록
 * - 마지막 단어를 입력 중인데 @ 문자가 입력되면 그 때부터 표시한다.
 * - @ 뒤에 텍스트가 오면 그 텍스트로 필터링한 빠른 검색 명령어 목록을 표시한다.
 */
export const getFilteredFastSearchCommands = (query: string): FastSearchCommandValue[] | null => {
  if (query.trim().length === 0) {
    return FAST_SEARCH_COMMAND_VALUES
  }

  const hasTrailingSpace = query.trimEnd().length !== query.length
  if (hasTrailingSpace) {
    return null
  }

  if (countOccurrences('"', query) % 2 === 1) {
    return null // 완전일치의 경우 바로 가이드 표시함
  }

  const words = query.split(' ')
  if (words.length === 0) {
    return null
  }

  const lastWord = words[words.length - 1]
  if (!lastWord.includes('@')) {
    return null
  }

  // 마지막 단어의 마지막 @부터 끝까지 문자열
  const filteringText = lastWord.slice(lastWord.lastIndexOf('@'))
  const filteredFastSearchCommands = FAST_SEARCH_COMMAND_VALUES.filter(
    (fastSearchCommand) => fastSearchCommand.indexOf(filteringText) === 0
  )

  return filteredFastSearchCommands
}

function countOccurrences(keyword: string, inputString: string): number {
  const regex = new RegExp(escapeRegExp(keyword), 'gi')
  const matches = inputString.match(regex)

  return matches ? matches.length : 0
}
